import React, { useCallback, useEffect, useState } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { Button, TextArea } from 'modules/ui';
import { PrimaryTextParagraph } from 'styles/TextsElements';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { useAppDispatch } from 'store';
import { Hint } from 'modules/ui/Hint';
import { HintChildrenDefaultRule } from 'components/contents/Models/EditableMenu/PopoverRule/Modal/RuleModal/Hint/HintChildrenDefaultRule';
import { AddIcon } from 'assets/icons/withContainer';
import { RuleItem } from 'components/contents/Models/EditableMenu/PopoverRule/RuleItem';
import { closeModalAction, openConfirmationModalAction, openModalTypedAction } from 'store/reducers/modals/actions';
import { NoopValueType } from 'types/global';
import {
  EditItemNameCallback,
  EditItemValueCallback,
  OnAddItemCallbackType,
  VariableValuesInterfaceList,
} from 'components/contents/Models/EditableMenu/PopoverRule/Modal/RuleModal/Variables/VariablesDefault/types';
import { NameVariablesValueModal } from 'components/contents/Models/EditableMenu/PopoverRule/Modal/RuleModal/Variables/VariablesDefault/NameVariablesValueModal';

interface VariablesDefaultProps {
  variablesList: VariableValuesInterfaceList[];
  onEditItemName: NoopValueType<EditItemNameCallback>;
  onEditItemValue: NoopValueType<EditItemValueCallback>;
  onAddItem: NoopValueType<OnAddItemCallbackType>;
  onDeleteItem: NoopValueType<number>;
  isNotSelectedField?: boolean;
  defaultValue?: string;
}

export const VariablesDefault = ({
  variablesList,
  onEditItemName,
  onDeleteItem,
  onAddItem,
  onEditItemValue,
}: VariablesDefaultProps) => {
  const dispatch = useAppDispatch();

  const [variableActiveValue, setVariableActiveValue] = useState<{
    value: string;
    name: string;
    index: number;
  } | null>(null);

  const variableActiveValueIndex = variableActiveValue?.index;
  const variableActiveValueName = variableActiveValue?.name;

  const onDeleteModalItem = useCallback(
    async ({ name, index }: EditItemNameCallback) => {
      try {
        await dispatch(
          openConfirmationModalAction({
            confirmationButtonText: 'Удалить',
            description: `Действительно удалить перемнную «${name}»?`,
            onConfirm: () => onDeleteItem(index),
            titleText: 'Удаление переменной',
            disableModalPortal: true,
          }),
        );
        setVariableActiveValue(null);
      } catch (e) {
        return;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onDeleteItem],
  );

  const onEditModalItemName = useCallback(
    async ({ name, index }: EditItemNameCallback) => {
      dispatch(
        openConfirmationModalAction({
          confirmationButtonText: 'Переименовать',
          onConfirm: (name) => name && onEditItemName({ name, index }),
          valueInput: name,
          isRenamable: true,
          titleText: 'Переименование переменной',
          width: '320px',
          disableModalPortal: true,
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onEditItemName],
  );

  const onCloseAddModalItem = () => dispatch(closeModalAction('nameVariablesValueModal'));

  const onAddModalItem = useCallback(
    () => {
      dispatch(
        openModalTypedAction({
          Component: NameVariablesValueModal,
          componentProps: {
            onClose: onCloseAddModalItem,
            onClick: ({ title, value }) => {
              onAddItem({ title, value, onClose: onCloseAddModalItem });
              setVariableActiveValue({ name: title, index: variablesList.length, value });
            },
            buttonSaveText: 'Создать',
          },
          modalSettings: {
            position: 'static',
            width: '320px',
            maxHeight: '168px',
            headerText: 'Создание переменной',
            disableModalPortal: true,
          },
          name: 'nameVariablesValueModal',
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCloseAddModalItem, onAddItem, variablesList.length],
  );

  const activeVariables = variablesList.find((_, index) => index === variableActiveValueIndex);

  useEffect(() => {
    setVariableActiveValue({
      value: activeVariables?.value || activeVariables?.defaultValue || '',
      index: variableActiveValueIndex || 0,
      name: activeVariables?.name || '',
    });
  }, [activeVariables?.defaultValue, activeVariables?.name, activeVariables?.value, variableActiveValueIndex]);

  const onResultVariable = (value: string) => {
    const resultVariable = { index: variableActiveValueIndex || 0, value, name: variableActiveValueName || '' };
    setVariableActiveValue(resultVariable);

    onEditItemValue(resultVariable);
  };

  return (
    <FlexContainer flexDirection="row" height="100%" width="100%" gap="1px">
      <FlexContainer position="relative" flexDirection="column" alignItems="flex-start" gap="8px">
        <FlexContainer flexDirection="row" alignItems="center" gap="8px">
          <PrimaryTextParagraph fontWeight="bold" lineHeight="14px" fontSize="14px" color={`var(${ColorVarsEnum.Level_1})`}>
            Переменные
          </PrimaryTextParagraph>
          <Hint>
            <HintChildrenDefaultRule title="Для выбранного пользователя или группы требуется строковое значение переменной без кавычек." />
          </Hint>
        </FlexContainer>

        <FlexContainer flexDirection="column" gap="8px" maxHeight="260px" overflow="auto" width="235px">
          {variablesList?.map(({ value, defaultValue, name }, index) => (
            <RuleItem
              key={name}
              onClick={() => setVariableActiveValue({ value: value || defaultValue || '', name, index })}
              onDelete={() => onDeleteModalItem({ index, name })}
              onEdit={() => onEditModalItemName({ name, index })}
              title={name}
              disabledActive
              activeBorder={index === variableActiveValueIndex}
            />
          ))}
        </FlexContainer>
        <FlexContainer position="absolute" bottom="0">
          <Button width="190px" text="Создать переменную" leftIcon={<AddIcon />} onClick={onAddModalItem} />
        </FlexContainer>
      </FlexContainer>

      <FlexContainer marginBottom="54px" width="100%" maxHeight="273px">
        <TextArea
          name="textarea"
          needBackground={true}
          value={variableActiveValue?.value || ''}
          onChange={(events) => onResultVariable(events.target.value)}
          width="100%"
          placeholder="Введите значение переменной"
          maxRows={11}
        />
      </FlexContainer>
    </FlexContainer>
  );
};
