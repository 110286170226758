import { useEffect, useMemo } from 'react';
import { AppDispatch } from 'store';
import { useParams } from 'react-router-dom';
import { ProjectIdParam } from 'constants/Routes';
import {
  DefaultDataSettingsInterface,
  DefaultDataSettingsWithActiveIncisionIdInterface,
  DefaultSqlDataInterface,
  ImagesSelectValuesInterface,
  IncisionSelectItemInterface,
  IndicatorSelectItemInterface,
  ServiceSelectValuesInterface,
  SqlDependencyInterface,
  VariablesSelectValuesInterface,
  VisualisationIdInterface,
} from 'store/reducers/visualisations/types';
import { getVisualisationAstParts, getVisualisationFieldName } from 'store/reducers/visualisations/constants';
import { setAstOfVisualisationActions } from 'store/reducers/ast/actions';
import { getVisualisationValuesAction } from 'store/reducers/visualisations/actions';
import Snackbar from 'services/Snackbar';
import { ModelIdInterface, SqlRequestInterface } from 'types/store';
import { useRLS } from 'utils/hooks/visualisation/useRLS';
import { ErrorMessageInterface } from 'types/global';

export interface SqlVisualisationProps
  extends VisualisationIdInterface,
    Partial<ServiceSelectValuesInterface>,
    Partial<VariablesSelectValuesInterface>,
    Partial<ImagesSelectValuesInterface>,
    Pick<SqlDependencyInterface, 'limit'>,
    SqlRequestInterface,
    ModelIdInterface {
  sqlData: DefaultSqlDataInterface;
  dispatch: AppDispatch;
  dataSettings: DefaultDataSettingsInterface;
}

export const useSqlVisualisation = ({
  sqlData: { filterAndGroupRequest },
  dispatch,
  dataSettings,
  id,
  serviceSelectValues = [],
  variablesSelectValues = [],
  imagesSelectValues = [],
  limit,
  sqlRequest,
  modelId,
}: SqlVisualisationProps) => {
  const projectId = useParams<ProjectIdParam>().projectId || '';
  const { rls } = useRLS();

  const sqlDataDependency = useMemo<SqlDependencyInterface>(() => {
    const { incisions, isRealData, indicators, activeIncisionId } =
      dataSettings as DefaultDataSettingsWithActiveIncisionIdInterface;

    const a = {
      incisions: incisions.reduce<IncisionSelectItemInterface[]>(
        (result, { id, fieldName, name, settings: { nameFromDatabase, customRequest } }) =>
          fieldName || customRequest
            ? [
                ...result,
                {
                  alias: getVisualisationFieldName({ fieldName, name, nameFromDatabase }),
                  selectSql: fieldName || '',
                  customRequest,
                  id,
                },
              ]
            : result,
        [],
      ),
      indicators: indicators.reduce<IndicatorSelectItemInterface[]>(
        (result, { id, fieldName, name, settings: { nameFromDatabase }, customRequest, operationType }) =>
          fieldName || customRequest
            ? [
                ...result,
                {
                  alias: getVisualisationFieldName({ fieldName, name, nameFromDatabase }),
                  selectSql: fieldName || '',
                  id,
                  customRequest,
                  operationType,
                },
              ]
            : result,
        [],
      ),
      isRealData,
      activeIncisionId,
      serviceSelectValues,
      variablesSelectValues,
      imagesSelectValues,
      id,
      limit,
      filterAndGroupRequest,
    };
    return a;
  }, [dataSettings, serviceSelectValues, variablesSelectValues, imagesSelectValues, id, limit, filterAndGroupRequest]);

  /* Update AST Store */
  useEffect(() => {
    const {
      id,
      indicators,
      incisions,
      activeIncisionId,
      serviceSelectValues,
      variablesSelectValues,
      imagesSelectValues,
      limit,
      filterAndGroupRequest,
    } = sqlDataDependency;

    try {
      const astData = getVisualisationAstParts({
        incisions,
        activeIncisionId,
        indicators,
        serviceSelectValues,
        variablesSelectValues,
        imagesSelectValues,
        limit,
        filterAndGroupRequest,
      });

      dispatch(setAstOfVisualisationActions({ id, astData }));
    } catch (error) {
      const message = (error as ErrorMessageInterface)?.message || '';
      Snackbar.show(message, 'error');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(sqlDataDependency)]);

  useEffect(() => {
    if (sqlRequest && dataSettings.isRealData && modelId) {
      const request = dispatch(getVisualisationValuesAction({ sqlRequest, projectId, visualisationId: id, modelId, rls }));

      return () => {
        request.abort();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sqlRequest, dataSettings.isRealData, rls, modelId]);

  return { projectId };
};
