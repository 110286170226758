import React from 'react';
import { DefaultFilterDataSettings } from 'modules/settingsContainer/common/data/DefaultFilterDataSettings';
import { useGetActiveFilter } from 'modules/filters/hooks/getActiveFilter';
import { FilterValuesList } from 'modules/settingsContainer/FilterValuesList';
import {
  onLimitChange,
  onMultipleModeChange,
  onOverflowPositionChange,
  onPaddingChange,
  onSelectedDataChange,
} from 'modules/filters/Single/settings/DataTab/constants';
import { InternalPaddingSettings } from 'modules/settingsContainer/InternalPaddingSettings';
import { useSelector } from 'react-redux';
import { getFiltersValuesStateByFilterId } from 'store/reducers/filters/getters';
import { FilterElementModeEnum } from 'types/store';
import {
  onFilterElementsChange as dispatchedFilterElementsChange,
  onSqlDataChange,
} from 'modules/settingsContainer/common/data/DefaultFilterDataSettings/constants';
import { LimitSettings } from 'modules/settingsContainer/common/data/LimitSettings';
import { OverflowPositionSettings } from 'modules/settingsContainer/common/OverflowPositionSettings';
import { MultipleChoiceSettings } from 'modules/settingsContainer/common/data/MultipleChoiceSettings';
import { FilterTypeEnum } from 'store/reducers/filters/types';
import { FilterIncisionsSettings } from 'modules/settingsContainer/FIlterIncisionsSettings';
import { useDataSettingsMetric } from 'modules/visualisations/hooks/dataSettingsMetric';
import { getAstFromSql } from 'utils/SQL/genereteAst';
import Snackbar from 'services/Snackbar';
import { FilterElementsInterface } from 'modules/settingsContainer/FIlterIncisionsSettings/types';
import { ErrorMessageInterface } from 'types/global';

export const DataTab = () => {
  const { data, modelIdValue, tableFields } = useGetActiveFilter({ type: 'single' });
  const { modelMetaData } = useDataSettingsMetric(data.modelId);

  const { isRealData, nameSettings, fictionalData, selectedData, paddings, id, limit, overflowPosition, isMultipleMode } = data;

  const { filterElementMode, fieldName } = nameSettings;

  const { filterValues, loadingFilterValue } = useSelector(getFiltersValuesStateByFilterId(id));

  const onFilterElementsChange = (filterElements: FilterElementsInterface) => {
    const { fieldName } = filterElements.nameSettings;

    dispatchedFilterElementsChange(filterElements);

    if (fieldName !== nameSettings.fieldName) {
      onSelectedDataChange([]);
    }
  };

  const onFilterElementModeChange = (filterElementMode: FilterElementModeEnum) => {
    dispatchedFilterElementsChange({ ...data, nameSettings: { ...nameSettings, filterElementMode } });
  };

  const onChangeSqlData = ({
    incisionRequest,
    filterAndGroupRequest,
  }: {
    incisionRequest: string;
    filterAndGroupRequest: string;
  }) => {
    try {
      const isValidIncisionAst = getAstFromSql(`SELECT ${incisionRequest}`);

      if (isValidIncisionAst) {
        onSqlDataChange({
          sqlData: {
            incisionRequest,
            filterAndGroupRequest,
          },
        });
      }
    } catch (error) {
      const message = (error as ErrorMessageInterface)?.message || '';
      Snackbar.show(message, 'error');
    }
  };

  return (
    <>
      <DefaultFilterDataSettings modelIdValue={modelIdValue} data={data}>
        <FilterIncisionsSettings
          onChange={onFilterElementsChange}
          isRealData={isRealData}
          options={tableFields}
          value={{ fictionalData, nameSettings }}
          modelMetaData={modelMetaData}
          onChangeSqlData={onChangeSqlData}
          data={data}
        >
          <FilterValuesList
            onChange={onSelectedDataChange}
            onFilterModeChange={onFilterElementModeChange}
            value={selectedData}
            filterValues={filterValues}
            filterElementMode={filterElementMode}
            loading={loadingFilterValue}
            fieldName={fieldName}
          />
        </FilterIncisionsSettings>
        <OverflowPositionSettings value={overflowPosition} onChange={onOverflowPositionChange} />
        {paddings && <InternalPaddingSettings value={paddings} onChange={onPaddingChange} />}
        <LimitSettings value={limit} onChange={onLimitChange} width="auto" />
        <MultipleChoiceSettings value={isMultipleMode} onChange={onMultipleModeChange} type={FilterTypeEnum.SINGLE} />
      </DefaultFilterDataSettings>
    </>
  );
};
