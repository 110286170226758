import React from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

type HintChildrenDefaultRuleProps = {
  title: string;
};

export const HintChildrenDefaultRule = ({ title }: HintChildrenDefaultRuleProps) => (
  <FlexContainer flexDirection="column">
    <PrimaryTextSpan color={`var(${ColorVarsEnum.Level_2})`} lineHeight="17px" fontSize="14px">
      {title}
    </PrimaryTextSpan>
  </FlexContainer>
);
