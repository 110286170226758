import { TextVisualisationType } from 'store/reducers/visualisations/types';
import { VisualisationRender } from 'modules/workspace/components/VisualisationArea/constants';
import { useAppDispatch } from 'store';
import { VisualisationLayout } from 'modules/visualisations/components/VisualisationLayout';
import { BoardPositionConfigInterface } from 'types/store';
import React, { memo, useMemo } from 'react';
import { useBackgroundContainerColors, useContainerColors } from 'modules/visualisations/hooks/containerColors';
import { useVisualisationNormalizedValues } from 'modules/visualisations/hooks/visualisationNormalizedValues';
import { useColorBy } from 'modules/visualisations/hooks/colorBy';
import { getImageObjectFit, getImageProportions } from 'modules/visualisations/Text/visualisation/constants';
import { Images } from 'react-gallery-carousel';
import { Carousel } from 'modules/ui/Carousel';
import { AreaInterface } from 'modules/workspace/components/WorkAreaSpace/types';
import { FlexContainer } from 'styles/FlexContainer';
import { CarouselContainerWrapper } from 'modules/ui/Carousel/styles';
import { BoardContainer } from 'modules/workspace/components/BoardContainer';
import { updatePositionConfigByIdAction } from 'store/reducers/visualisations/actions';
import { useVisualisationEvents } from 'modules/visualisations/hooks/visualisationEvents';
import { FilterInfluencePopup } from '../FilterInfluencePopup';
import { useSelector } from 'react-redux';
import { getActiveFilter } from 'store/reducers/filters/getters';
import { getActiveVisualisationSettings } from 'store/reducers/visualisations/getters';

const Visualisation = VisualisationRender['text'].Visualisation;
const SettingComponent = VisualisationRender['text'].MainSettings;

export const MediaBlock: AreaInterface<TextVisualisationType> = ({
  data,
  sqlRequest,
  isShowMode,
  scale,
  isActive,
  isLoading,
  isError,
  isInfluenceEditing,
  onInfluenceChange,
}) => {
  const dispatch = useAppDispatch();

  const {
    id,
    positionConfig,
    viewSettings: { shadowSettings, borderSettings, disableDragging, showBackground, backgroundSettings },
    backgroundImagesSettings: { backgroundImages, imagesLinkType, proportionsImageType, carouselAutoPlay },
    dataSettings,
    events,
  } = data;

  const visualizationData = useSelector(getActiveVisualisationSettings);
  const filterData = useSelector(getActiveFilter);

  const visualizationInfluences = visualizationData?.events?.filterSettings?.filterInfluences;
  const filterVisualizationInfluences = filterData?.filterInfluences;
  const filterInfluences = visualizationInfluences || filterVisualizationInfluences || {};

  const {
      imagesSettings: { backgroundImagesBy },
    } = backgroundImages,
    {
      byCondition: { sqlCondition, alias },
    } = backgroundImagesBy;

  const onPositionConfigChange = (positionConfig: Partial<BoardPositionConfigInterface>) => {
    dispatch(
      updatePositionConfigByIdAction({
        id,
        positionConfig,
      }),
    );
  };

  const handleFilterInfluence = (payload: boolean) => () => {
    const events = visualizationData?.events;
    const filterSettings = events?.filterSettings;

    const data = {
      visualizationInfluences: {
        ...events,
        filterSettings: { ...filterSettings, filterInfluences: { ...filterInfluences, [id]: payload } },
      },
      filterInfluences: { ...filterInfluences, [id]: payload },
      isVisualization: !!visualizationData?.visualisationType,
    };

    onInfluenceChange(data);
  };

  const { visualisationNormalizedValues } = useVisualisationNormalizedValues({
    id,
    isReactingToFilter: data.events.isReactingToFilter,
    dataSettings: data.dataSettings,
  });

  const containerColors = useContainerColors({
    visualisationNormalizedValues,
    shadowSettings,
    borderSettings,
    showBackground,
  });
  const borderRadius = borderSettings.radius;

  const {
    colorSettings: { backgroundColorBy },
  } = backgroundSettings;
  const { backgroundColor, ...backgroundContainerColors } = useBackgroundContainerColors(backgroundSettings);

  const { getColorValueByCondition, isColorByCondition, getVisualisationColorsAndImagesData } = useColorBy({
    colorsBy: [backgroundColorBy],
    indicators: data.dataSettings.indicators,
    visualisationValues: visualisationNormalizedValues,
  });

  const conditionalBackgroundColorValue = useMemo(() => {
    const alias = backgroundColorBy.byCondition.alias,
      colorFontsSQL = getVisualisationColorsAndImagesData(alias)?.[0];

    return backgroundColorBy.byCondition.sqlCondition && isColorByCondition(alias)
      ? getColorValueByCondition(alias, colorFontsSQL)
      : undefined;
  }, [
    backgroundColorBy.byCondition.alias,
    backgroundColorBy.byCondition.sqlCondition,
    getColorValueByCondition,
    getVisualisationColorsAndImagesData,
    isColorByCondition,
  ]);

  const backgroundColorValue = conditionalBackgroundColorValue || backgroundColor;

  const conditionalTextImageValue = useMemo(() => {
    const imageFontsSQL = visualisationNormalizedValues[alias]?.[0];

    return !!sqlCondition && backgroundImagesBy.type === 'condition'
      ? backgroundImagesBy.byCondition.images[Number(imageFontsSQL) - 1]?.value?.linkImage
      : undefined;
  }, [alias, backgroundImagesBy.byCondition.images, backgroundImagesBy.type, sqlCondition, visualisationNormalizedValues]);

  const images = visualisationNormalizedValues[dataSettings.incisions[0]?.name];
  const byValueImage = backgroundImagesBy.byValue.images;

  const backgroundImage = useMemo(() => {
    if (imagesLinkType === 'link' && byValueImage.length <= 1) {
      return conditionalTextImageValue || (backgroundImagesBy.type === 'value' ? byValueImage[0]?.value?.linkImage : undefined);
    }

    if (imagesLinkType === 'fromDatabase' && images && images?.length <= 1) {
      return images[0];
    }
  }, [imagesLinkType, byValueImage, images, conditionalTextImageValue, backgroundImagesBy.type]);

  const carouselData = useMemo<Images | undefined>(() => {
    return imagesLinkType === 'fromDatabase'
      ? (images || []).map((item) => ({
          src: item ? String(item) : '',
        }))
      : backgroundImagesBy.type === 'value'
      ? (byValueImage || []).map((item) => ({
          src: item ? String(item.value?.linkImage) : '',
        }))
      : undefined;
  }, [imagesLinkType, backgroundImagesBy.type, byValueImage, images]);

  const { onClick } = useVisualisationEvents(events);

  return (
    <>
      <BoardContainer
        isLoading={isLoading}
        isShowMode={isShowMode}
        isActive={isActive}
        isError={isError}
        isInfluenceEditing={isInfluenceEditing}
        scale={scale}
        id={id}
        SettingComponent={SettingComponent}
        config={positionConfig}
        onChange={onPositionConfigChange}
        disableDragging={disableDragging}
        shadowSettings={shadowSettings}
        borderSettings={borderSettings}
        {...containerColors}
        {...backgroundContainerColors}
        backgroundColor={backgroundColorValue}
        backgroundImage={backgroundImage as string}
        backgroundSize={getImageProportions[proportionsImageType]}
      >
        <FilterInfluencePopup
          isActive={isActive}
          id={id}
          isInfluenceEditing={isInfluenceEditing}
          handleFilterInfluence={handleFilterInfluence}
          filterInfluences={filterInfluences}
        />
        <CarouselContainerWrapper borderRadius={`${borderRadius}px`}>
          <VisualisationLayout data={data}>
            <Visualisation data={data} sqlRequest={sqlRequest} isLoading={isLoading} />
          </VisualisationLayout>
          {carouselData && carouselData.length > 1 && (
            <FlexContainer
              position="absolute"
              width="100%"
              height="100%"
              top="0"
              left="0"
              borderRadius={`${borderRadius}px`}
              overflow="hidden"
              onClick={onClick}
            >
              <Carousel
                options={carouselAutoPlay}
                imagesData={carouselData || []}
                carouselPositionSize={getImageObjectFit[proportionsImageType]}
              />
            </FlexContainer>
          )}
        </CarouselContainerWrapper>
      </BoardContainer>
    </>
  );
};

export const MediaBlockArea = memo(MediaBlock);
