import React from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { Button, TextField } from 'modules/ui';
import { ModalComponentType } from 'store/reducers/modals/types';
import { NoopValueType } from 'types/global';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Form } from './styles';
import { LoadingOverlay } from 'modules/ui/Loading/LoadingOverlay';
import { NameVariablesValueFormType } from './types';

interface NameVariablesValueModalProps {
  onClick: NoopValueType<{ title: string; value: string }>;
  buttonSaveText?: string;
}

export const NameVariablesValueModal: ModalComponentType<NameVariablesValueModalProps> = ({
  onClose,
  onClick,
  buttonSaveText,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<NameVariablesValueFormType>({
    mode: 'onSubmit',
  });

  const onSubmit: SubmitHandler<NameVariablesValueFormType> = ({ nameValue, defaultValue }) => {
    if (isSubmitting) {
      return;
    }

    onClick({ value: defaultValue, title: nameValue.trim() });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay loading={isSubmitting} />

      <FlexContainer width="100%" flexDirection="column" height="100%" gap="32px">
        <FlexContainer width="100%" flexDirection="column" height="100%" gap="8px">
          <Controller
            name="nameValue"
            rules={{ required: true }}
            control={control}
            render={({ field }) => <TextField error={!!errors.nameValue} label="Название" width="100%" {...field} />}
          />

          <Controller
            name="defaultValue"
            control={control}
            render={({ field }) => <TextField label="Значение по умолчанию" width="100%" {...field} />}
          />
        </FlexContainer>

        <FlexContainer width="100%" justifyContent="flex-end" gap="8px">
          <Button text="Отмена" iconSize="normal" heightSize="normal" needBackground={false} onClick={onClose} />
          <Button text={buttonSaveText || 'Ок'} iconSize="normal" needBackground={true} heightSize="normal" type="submit" />
        </FlexContainer>
      </FlexContainer>
    </Form>
  );
};
