import styled from 'styled-components';
import { fileInputMixin } from 'constants/styles';

export const FileInput = styled.input`
  ${fileInputMixin}
`;

export const Form = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  position: relative;
  flex-direction: column;
`;
