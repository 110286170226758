import React, { useCallback } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { Button, TextField } from 'modules/ui';
import { ModalComponentType } from 'store/reducers/modals/types';
import { NoopValueType } from 'types/global';
import { FileIcon } from 'assets/icons/source';
import { FileInputLabel } from 'modules/ui/inputs/FileUploadInput/styles';
import { FileInput, Form } from './styles';
import { PrimaryTextParagraph, TextLink } from 'styles/TextsElements';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { CreateRuleModel } from 'components/contents/Models/EditableMenu/PopoverRule/Modal/CreateRuleModal/types';
import { sampleRightsFileLink } from '../RuleModal/Variables/constants';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { LoadingOverlay } from 'modules/ui/Loading/LoadingOverlay';

interface CreateRuleModalProps {
  onSave: NoopValueType<CreateRuleModel>;
}

export const CreateRuleModal: ModalComponentType<CreateRuleModalProps> = ({ onClose, onSave }) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { isSubmitting, errors },
  } = useForm<CreateRuleModel>({
    mode: 'onSubmit',
  });

  const file = watch('file');
  const nameValue = watch('name');

  const onSubmit: SubmitHandler<CreateRuleModel> = useCallback(
    async ({ name, file }) => {
      try {
        if (isSubmitting) {
          return null;
        }

        onSave({ name: name.trim(), file });

        onClose();
      } catch (e) {
        return;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSubmitting, onSave, onClose],
  );

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay loading={isSubmitting} />

      <FlexContainer width="100%" flexDirection="column" height="100%" gap="32px">
        <FlexContainer flexDirection="column" gap="16px">
          <Controller
            rules={{ required: true }}
            render={({ field }) => <TextField label="Название" width="100%" error={!!errors.name} {...field} />}
            name="name"
            control={control}
          />

          <FlexContainer marginRight="auto" flexDirection="column">
            <Controller
              render={({ field }) => (
                <FileInput
                  type="file"
                  accept=".csv, .xlsx"
                  id="fileUpload"
                  onChange={(event) => {
                    event.stopPropagation();
                    const file = event.target.files?.[0];

                    if (file) {
                      field.onChange(file);
                    }
                  }}
                />
              )}
              name="file"
              control={control}
            />

            <FileInputLabel
              lineHeight="12px"
              fontSize="12px"
              padding="2px 8px 2px 2px"
              htmlFor="fileUpload"
              onChange={(e) => e.stopPropagation()}
            >
              <FileIcon />
              <PrimaryTextParagraph fontSize="12px" color={`var(${ColorVarsEnum.Level_1})`}>
                {file?.name || 'Прикрепить файл'}
              </PrimaryTextParagraph>
            </FileInputLabel>
            <TextLink fontSize="10px" href={sampleRightsFileLink}>
              Скачать образец файла
            </TextLink>
          </FlexContainer>
        </FlexContainer>

        <FlexContainer width="100%" justifyContent="flex-end" gap="8px">
          <Button text="Отмена" iconSize="normal" heightSize="normal" needBackground={false} onClick={onClose} />
          <Button
            disabled={!nameValue?.trim()}
            text="Создать"
            iconSize="normal"
            needBackground={true}
            heightSize="normal"
            type="submit"
          />
        </FlexContainer>
      </FlexContainer>
    </Form>
  );
};
