import env from 'config/settings';
import { store } from 'store';
import { updateAllVariablesAction, updateRuleGroupsAction, updateRuleUsersAction } from 'store/reducers/models/actions';
import {
  AddAllVariableNameInterface,
  DeleteAllVariableInterface,
  UpdateAllVariableNameInterface,
  UpdateAllVariableValueInterface,
  UpdateGroupVariableValueInterface,
  UpdateUserVariableValueInterface,
} from 'components/contents/Models/EditableMenu/PopoverRule/Modal/RuleModal/Variables/types';
import { hasName } from 'utils/utils';

export const sampleRightsFileLink = `${env.API}/example_import_users_and_groups.xlsx`;
const dispatch = store.dispatch;

export const onAddAllVariableName = ({
  usersRule,
  groupsRule,
  newNameVariable,
  allVariablesList,
  defaultValue,
}: AddAllVariableNameInterface) => {
  const users = usersRule.map((item) => ({
    ...item,
    variableValues: [...item.variableValues, { name: newNameVariable, value: defaultValue }],
  }));

  const groups = groupsRule.map((item) => ({
    ...item,
    variableValues: [...item.variableValues, { name: newNameVariable, value: defaultValue }],
  }));

  dispatch(updateRuleUsersAction(users));
  dispatch(updateRuleGroupsAction(groups));
  if (!hasName(allVariablesList, newNameVariable)) {
    dispatch(updateAllVariablesAction([...allVariablesList, { name: newNameVariable, defaultValue }]));
  }
};

export const onUpdateAllVariableName = ({
  usersRule,
  groupsRule,
  variableIndex,
  newNameVariable,
  allVariablesList,
}: UpdateAllVariableNameInterface) => {
  const users = usersRule.map((item) => ({
    ...item,
    variableValues: item.variableValues.map((variable, index) =>
      index === variableIndex ? { ...variable, name: newNameVariable } : variable,
    ),
  }));

  const groups = groupsRule.map((item) => ({
    ...item,
    variableValues: item.variableValues.map((variable, index) =>
      index === variableIndex ? { ...variable, name: newNameVariable } : variable,
    ),
  }));

  dispatch(updateRuleGroupsAction(groups));
  dispatch(updateRuleUsersAction(users));

  if (!hasName(allVariablesList, newNameVariable)) {
    const newAllVariablesList = allVariablesList.map(({ defaultValue }) => ({ name: newNameVariable, defaultValue }));
    dispatch(updateAllVariablesAction(newAllVariablesList));
  }
};

export const onDeleteAllVariable = ({
  usersRule,
  groupsRule,
  variableIndex,
  allVariablesList,
}: Omit<DeleteAllVariableInterface, 'newNameVariable'>) => {
  const users = usersRule.map((item) => ({
    ...item,
    variableValues: item.variableValues.filter((_, index) => index !== variableIndex),
  }));
  const groups = groupsRule.map((item) => ({
    ...item,
    variableValues: item.variableValues.filter((_, index) => index !== variableIndex),
  }));

  const allVariablesFilter = allVariablesList.filter((_, index) => index !== variableIndex);

  dispatch(updateRuleUsersAction(users));
  dispatch(updateRuleGroupsAction(groups));
  dispatch(updateAllVariablesAction(allVariablesFilter));
};

export const onUpdateUserVariableValue = ({
  usersRule,
  variableIndex,
  userId,
  newValueVariable,
}: UpdateUserVariableValueInterface) => {
  const users = usersRule.map((item) =>
    item.id === userId
      ? {
          ...item,
          variableValues: item.variableValues.map((variable, index) =>
            index === variableIndex ? { ...variable, value: newValueVariable } : variable,
          ),
        }
      : item,
  );

  dispatch(updateRuleUsersAction(users));
};

export const onUpdateGroupVariableValue = ({
  groupsRule,
  variableIndex,
  groupId,
  newValueVariable,
}: UpdateGroupVariableValueInterface) => {
  const groups = groupsRule.map((item) =>
    item.id === groupId
      ? {
          ...item,
          variableValues: item.variableValues.map((variable, index) =>
            index === variableIndex ? { ...variable, value: newValueVariable } : variable,
          ),
        }
      : item,
  );
  dispatch(updateRuleGroupsAction(groups));
};

export const onUpdateAllVariableValue = ({
  allVariablesList,
  nameVariable,
  newValueVariable,
}: UpdateAllVariableValueInterface) => {
  const allVariables = allVariablesList.map((variable) => {
    return variable.name === nameVariable ? { ...variable, defaultValue: newValueVariable } : variable;
  });

  dispatch(updateAllVariablesAction(allVariables));
};
