import { parse, SelectFromStatement, Statement } from 'pgsql-ast-parser';

import { EnabledFiltersDependencyInterface } from 'store/reducers/filters/types';
import { AST } from 'types/ast';
import { defaultDateFormat, defaultSqlDateFormat, getFromToFnByType } from 'constants/dates';
import { getDateByFormat, getStringDateByFormat } from 'utils/dates';

import { getSql } from './generateSQL';

export const getAstFromSql = (sql: string) => {
  try {
    const parsed = parse(sql)[0];
    return parsed;
  } catch (error) {
    console.log('error ast', error);
  }
};

export const defaultSelectAST: SelectFromStatement = {
  type: 'select',
  columns: [],
  distinct: null,
  from: null,
  where: null,
  groupBy: null,
  having: null,
  orderBy: null,
  limit: null,
};

/* Generate Object AST from raw data */

export const getTableAndColumnByFieldName = (fieldName: string) => {
  const dividedFieldName = fieldName.split('.');

  let table = null;
  let column = fieldName;

  if (dividedFieldName.length > 1) {
    [table, column] = dividedFieldName;
  }

  return { table, column };
};

export const generateBasicColumn: (params: { alias: string; fieldName: string }) => any = ({ fieldName, alias }) => {
  const { table, column } = getTableAndColumnByFieldName(fieldName);

  return {
    alias: { name: alias },
    expr: { type: 'ref', name: column, table: { name: table } },
  };
};

//@ts-ignore
export const generateBasicFunctionColumn: (params: {
  alias: string;
  fieldName: string;
  functionName: string;
}) => AST.BasicFunctionColumn = ({ fieldName, alias, functionName }) => {
  const { expr: argumentExpr } = generateBasicColumn({ fieldName, alias });

  return {
    alias: { name: alias },
    expr: {
      type: 'call',
      function: { name: functionName },
      args: [argumentExpr],
    },
  };
};

export const generateGroupByColumn: (params: { fieldName: string }) => AST.GroupByColumn = ({ fieldName }) => {
  const { expr: groupBy } = generateBasicColumn({ fieldName, alias: '' });

  return groupBy;
};

export const generateValueString: (value: string) => AST.ValueString = (value) => ({ type: 'string', value });

export const generateExpressionList: (
  values: string[] | AST.ValueString[] | Array<string | AST.ValueString>,
) => AST.ExpressionList = (values) => ({
  type: 'expr_list',
  value: values.map((value) => {
    if (typeof value === 'string') {
      return generateValueString(value);
    }
    return value;
  }),
});

//@ts-ignore
export const generateIn: AST.GenerateFilterAstFnInterface = ({ fieldName, values, operator = 'IN' }) => {
  const { expr: left } = generateBasicColumn({ fieldName, alias: '' }),
    right = generateExpressionList(values);

  return {
    type: 'binary',
    left,
    op: operator,
    right,
  };
};

//@ts-ignore
export const generateLike: AST.GenerateLikeAstFnInterface = ({ fieldName, filterString }) => {
  const { expr: left } = generateBasicColumn({ fieldName, alias: '' }),
    { expr: right } = generateBasicColumn({ fieldName: filterString, alias: '' });

  return {
    type: 'binary',
    left,
    op: 'LIKE',
    right,
  };
};
export const generateILike: AST.GenerateILikeAstFnInterface = ({ fieldName, filterString }) => {
  const { expr: column } = generateBasicColumn({ fieldName, alias: '' });

  return {
    type: 'function',
    name: 'ilike',
    args: {
      type: 'expr_list',
      value: [
        {
          type: 'function',
          name: 'toString',
          args: {
            type: 'expr_list',
            value: [column],
          },
          over: null,
        },
        {
          type: 'string',
          value: filterString,
        },
      ],
    },
    over: null,
  } as AST.FunctionType;
};

export const generateFilterAstsFn: (params: {
  fieldName: string;
  values: EnabledFiltersDependencyInterface['values'];
  type: EnabledFiltersDependencyInterface['type'];
}) => AST.WhereIn | AST.WhereBetween = ({ fieldName, values, type }) => {
  let usedValues: string[] = values as string[];
  let operator: AST.AstOperatorType = 'IN';

  if (type === 'date' && !Array.isArray(values)) {
    operator = 'BETWEEN';
    const { startDate, endDate, byType } = values;

    const start = getDateByFormat(startDate, defaultDateFormat),
      end = getDateByFormat(endDate, defaultDateFormat);

    const { from, to } = getFromToFnByType[byType];

    usedValues =
      start && end
        ? [
            getStringDateByFormat(from(start), defaultSqlDateFormat) as string,
            getStringDateByFormat(to(end), defaultSqlDateFormat) as string,
          ]
        : [];

    if (byType === 'byToday') {
      usedValues = [
        getStringDateByFormat(new Date(), defaultSqlDateFormat) as string,
        getStringDateByFormat(new Date(), defaultSqlDateFormat) as string,
      ];
    }
  }

  return generateIn({ values: usedValues, operator, fieldName });
};

export const generateUnionWhereIn: (
  values: Array<AST.WhereIn | AST.WhereBetween | AST.WhereLike>,
) => AST.UnionAndExpressions | AST.WhereIn | AST.WhereBetween | AST.WhereLike | null = (values) => {
  if (values.length === 0) {
    return null;
  }

  if (values.length === 1) {
    return values[0];
  }

  const [left, right, ...rest] = values,
    restLength = rest.length;

  const unionLeftWhere: AST.UnionAndExpressions = {
    //@ts-ignore
    type: 'binary',
    left,
    op: 'AND',
    right,
  };

  if (restLength > 0) {
    const lastRestIndex = restLength - 1,
      restLeft = restLength === 1 ? unionLeftWhere : generateUnionWhereIn([left, right, ...rest.slice(0, lastRestIndex)]);

    return {
      type: 'binary',
      left: restLeft,
      op: 'AND',
      right: rest[lastRestIndex],
    } as any;
  }

  return unionLeftWhere;
};

export const generateLimit: (params: { to: number }) => AST.Limit = ({ to }) => {
  return {
    limit: { value: to, type: 'integer' },
  };
};

/* Generate Object AST using sqlParser */

export const getSelectColumnsFromSqlString = (selectQuery?: string) => {
  if (selectQuery && selectQuery !== '') {
    const columns = getAstFromSql(`SELECT ${selectQuery}`);

    if (Array.isArray(columns)) {
      return columns;
    }
  }

  return [];
};

/* Generate string from AST */

export const getFieldNameFromColumn = ({ table, column }: any) => {
  return `${table ? `${table}.` : ''}${column}`;
};

export const getColumnsWithoutSelect = (columns?: any | AST.BasicFunctionColumn[] | AST.BasicColumn[]) => {
  //@ts-ignore
  const sql = getSql({ ...defaultSelectAST, columns: columns || [] } as Statement)
    .replace('SELECT', '')
    .trim();

  return sql;
};

//TODO: fix types
export const getWhereString = (
  where?:
    | Array<AST.WhereIn | AST.WhereBetween>
    | AST.WhereBetween
    | AST.UnionAndExpressions
    | AST.UnionOrExpressions
    | AST.UnionExpressions
    | AST.WhereIn
    | AST.WhereLike
    | AST.FunctionType
    | null,
) => {
  let whereParam = where || null;

  if (Array.isArray(where)) {
    whereParam = generateUnionWhereIn(where);
  }

  //@ts-ignore
  const sql = getSql({ ...defaultSelectAST, where: whereParam } as any)
    .replace('SELECT', '')
    .trim();

  return sql;
};

/* Check Functions */

export const isNullOrString = (value: any) => {
  return value === null || typeof value === 'string';
};

export const isColumnRef = (expr: any) => {
  return expr?.type === 'column_ref' && isNullOrString(expr?.table) && typeof expr?.column === 'string';
};

export const isBasicColumn = (astValue: any) => {
  return isNullOrString(astValue?.as) && isColumnRef(astValue?.expr);
};

export const isBasicFunctionColumn = (astValue: any) => {
  const { expr } = astValue ?? {};

  const hasAs = isNullOrString(astValue?.as),
    isArgFunc =
      expr?.type === 'call' &&
      typeof expr?.name === 'string' &&
      isColumnRef(expr?.args?.expr) &&
      (astValue?.expr?.args?.distinct ? astValue?.expr?.args?.distinct === null : true);

  return hasAs && isArgFunc;
};
