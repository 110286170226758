import { DownFilterIcon, UpFilterIcon } from 'assets/icons/filter';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import isArray from 'lodash/isArray';
import { useColorValues } from 'modules/settingsContainer/ColorPicker/hooks';
import { useVisualisationTable } from 'modules/visualisations/hooks/Table/visualisationTable';
import { PropertiesRecordType, useProperties } from 'modules/visualisations/hooks/useProperties';
import {
  getCalculatedTotalValues,
  getDataForPivotTable,
  getTableData,
  getTotalSqlRequest,
  lastRowClass,
  onSortClick,
  transformLoadedValuesToTotalValues,
} from 'modules/visualisations/Table/visualisation/constants';
import {
  Column,
  ColumnSettingsInterface,
  DataTableInterface,
  MakeHyperLinksRecordType,
  MinMaxFuncInterface,
  SqlRequestForPivotInterface,
  TableDataInterface,
  TotalValuesType,
} from 'modules/visualisations/Table/visualisation/types';
import { VisualisationOriginInterface } from 'modules/workspace/components/VisualisationArea/types';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { useSelector } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { loadVisualisationValuesAction } from 'store/reducers/visualisations/actions';
import { colorAlias, getVisualisationFieldName } from 'store/reducers/visualisations/constants';
import {
  getAstForSqlGenerationQueryById,
  getSqlRequestForGroupingRowTable,
  getSqlRequestForHeaderTable,
} from 'store/reducers/visualisations/getters';
import {
  ColorByItem,
  DefaultPropertiesInterface,
  IndentationInterface,
  IsBeatInterface,
  TableVisualisationType,
  VisualisationValuesInterface,
} from 'store/reducers/visualisations/types';
import { PrimaryTextSpan, TextLink } from 'styles/TextsElements';
import { chainFormatter, formattingNameTextAlign } from 'utils/formatting';
import { useRLS } from 'utils/hooks/visualisation/useRLS';
import { generateUnionWhereIn } from 'utils/SQL/genereteAst';
import { addProtocolToLink, getColorByValueMinMax, getRandomInt, isURL } from 'utils/utils';
import { GetSqlRequestForHeaderPivotTable } from 'utils/visualisations';
import {
  AbsoluteColorBlock,
  ButtonWrapper,
  Observe,
  StyledTableWrapper,
  TableBody,
  TableCell,
  tableCellItem,
  TableHead,
  TableHeader,
  TableRow,
  TableTextBody,
  TanStackTableStyled,
} from './styles';
import { TotalComponent } from './TotalComponent';

export const TableVisualisationComponent: VisualisationOriginInterface<TableVisualisationType> = ({ data, sqlRequest }) => {
  const { id, dataSettings, viewSettings, sqlData, events } = data;
  const { colorByValueSettings, backgroundByValueSettings, isRealData, limitGrouping, limit } = dataSettings;
  const { bodySettings, headerSettings, subtotalsSettings, totalRowSettings, shadowSettings } = viewSettings;
  const {
    shadowColorSettings: { shadowColorBy },
  } = shadowSettings;
  const getSqlRequestForGroupingRow = useSelector(getSqlRequestForGroupingRowTable);
  const getSqlRequestForHeader = useSelector(getSqlRequestForHeaderTable);

  const { rls } = useRLS();

  const {
    from,
    whereAstData,
    filtersAndGroups: { where },
  } = useSelector(getAstForSqlGenerationQueryById(id));
  const { getColorValues, activeThemeSchema } = useColorValues();

  const [offsetSqlRequest, setOffsetSqlRequest] = useState(25);
  const [sqlRequestForPivot, setSqlRequestForPivot] = useState<SqlRequestForPivotInterface | null>(null);
  const [sqlRequestForTotalRowPivot, setSqlRequestForTotalRowPivot] = useState<SqlRequestForPivotInterface | null>(null);
  const [minMaxIndicators, setMinMaxIndicators] = useState<Record<string, [number, number]>>({});
  const [isLoadingData, setIsLoadingData] = useState<boolean>(false);
  const [hasMoreData, setHasMoreData] = useState<boolean>(true);
  const [columnVisibility, setColumnVisibility] = useState<string[]>([]);
  const [loadedTotalValues, setLoadedTotalValues] = useState<TotalValuesType>({});
  const tableRef = useRef<HTMLDivElement | null>(null);
  const { ref: observeDownRef, inView: inViewDownObserve } = useInView({
    threshold: 0,
  });
  const [tableData, setTableData] = useState<TableDataInterface>({
    columns: [],
    data: [],
  });

  const incisionsInHeaderNames: string[] = useMemo(
    () =>
      dataSettings.incisionsInHeader.map((incision) =>
        getVisualisationFieldName({
          fieldName: incision.fieldName,
          name: incision.name,
          nameFromDatabase: incision.settings.nameFromDatabase,
        }),
      ),
    [dataSettings.incisionsInHeader],
  );
  const grouping = useMemo(() => {
    const allIncisions = [...dataSettings.incisions, ...dataSettings.incisionsInHeader];
    if (dataSettings.hasAllGroupIncision) {
      return allIncisions.map((incision) => (incision.settings.nameFromDatabase ? incision.fieldName || '' : incision.name));
    } else {
      return allIncisions
        .filter((incision) => incision.settings.isGroup)
        .map((incision) => (incision.settings.nameFromDatabase ? incision.fieldName || '' : incision.name));
    }
  }, [dataSettings.incisions, dataSettings.incisionsInHeader, dataSettings.hasAllGroupIncision]);
  const sqlRequestForHeader = getSqlRequestForHeader({
    id,
    incisionsInHeaderNames,
    limitData: limit.isActive
      ? {
          seperator: ',',
          value: [
            {
              type: 'number',
              value: limit.value || 1,
            },
          ],
        }
      : undefined,
  });
  const sqlRequestTable =
    grouping.length > 0
      ? getSqlRequestForGroupingRow({
          id,
          columnNextIndex: 1,
          incisionsInHeaderNames,
          isPivotTable: incisionsInHeaderNames.length !== 0,
        })
      : sqlRequest;

  const countIncisions = useMemo(
    () => columnVisibility.length || dataSettings.incisions.filter((incision) => incision.fieldName).length,
    [dataSettings.incisions, columnVisibility.length],
  );
  const colorInvertInHEX = useCallback(
    (objectIsBeat: IsBeatInterface) => {
      return { ...objectIsBeat, color: getColorValues(objectIsBeat.color) as string };
    },
    [getColorValues],
  );
  const minMaxFunc = ({ columns, payloadRequest, parentsChain = 'BigDad' }: MinMaxFuncInterface) => {
    columns[columns.length - 1].forEach(({ isIncision, Header }) => {
      if (!isIncision) {
        const array = payloadRequest[Header] as number[],
          min = Math.min.apply(null, array),
          max = Math.max.apply(null, array);

        setMinMaxIndicators((prev) => {
          const keyName = Header + parentsChain;
          if (prev[keyName]) {
            prev[keyName][0] = prev[keyName][0] < min ? prev[keyName][0] : min;
            prev[keyName][1] = prev[keyName][1] > max ? prev[keyName][1] : max;
            return prev;
          } else {
            return {
              ...prev,
              [keyName]: [min, max],
            };
          }
        });
      }
    });
  };

  const {
    getColorByValue,
    visualisationNormalizedValues,
    dispatch,
    projectId,
    formattingParams: { formatting },
    modelId,
    updateFilter,
  } = useVisualisationTable({
    sqlData,
    id,
    colorsBy: [colorByValueSettings, backgroundByValueSettings, shadowColorBy],
    dataSettings,
    limit: { isActive: true, value: 25 },
    events,
    sqlRequest: sqlRequestTable,
  });

  const columnsSettings = useMemo<ColumnSettingsInterface>(() => {
    const columns = [...dataSettings.incisions, ...dataSettings.incisionsInHeader, ...dataSettings.indicators];

    return columns.reduce(
      (
        data,
        {
          fieldName,
          name,
          settings: {
            columnWidthSettings: { isActive, width },
            nameFromDatabase,
            hasIndentation,
            indentation,
          },
        },
      ) => {
        const columnName = getVisualisationFieldName({ name, fieldName, nameFromDatabase });

        return { ...data, [columnName]: { columnWidth: isActive ? width : undefined, hasIndentation, indentation } };
      },
      {},
    );
  }, [dataSettings.indicators, dataSettings.incisionsInHeader, dataSettings.incisions]);

  const propertiesData = useMemo(() => {
    const properties = [...dataSettings.incisions, ...dataSettings.incisionsInHeader, ...dataSettings.indicators];

    return properties.reduce<PropertiesRecordType>((data, { name, fieldName, settings: { properties, nameFromDatabase } }) => {
      const propertiesName = getVisualisationFieldName({ name, fieldName, nameFromDatabase });
      data[propertiesName] = properties;
      return { ...data, [propertiesName]: properties };
    }, {});
  }, [dataSettings.indicators, dataSettings.incisionsInHeader, dataSettings.incisions]);

  const properties = useProperties(propertiesData);

  const makeHyperLinks = useMemo<MakeHyperLinksRecordType>(() => {
    return dataSettings.indicators.reduce((data, { fieldName, name, settings: { makeHyperLinks, nameFromDatabase } }) => {
      const indicatorFieldName = getVisualisationFieldName({ name, fieldName, nameFromDatabase });

      return {
        ...data,
        [indicatorFieldName]: makeHyperLinks || undefined,
      };
    }, {});
  }, [dataSettings.indicators]);

  useEffect(() => {
    let lengthOpenRows = 0;

    someName: for (let x = dataSettings.incisions.length; x > 0; x--) {
      for (let i = 0; i < tableData.data.length; i++) {
        if (tableData.data[i][x - 1]?.value !== null) {
          lengthOpenRows = x;
          break someName;
        }
      }
    }

    setColumnVisibility(
      dataSettings.incisions
        .slice(0, lengthOpenRows)
        .map((incision) => (incision.settings.nameFromDatabase ? incision.fieldName || '' : incision.name)),
    );
  }, [tableData.data, tableData.data.length, dataSettings.incisions]);

  useEffect(() => {
    tableRef.current?.scrollTo(0, 0);
    setOffsetSqlRequest(25);
    setHasMoreData(true);
    const incisionsHeader =
      incisionsInHeaderNames.length && !isRealData
        ? incisionsInHeaderNames.reduce((object, name) => {
            return { ...object, [name]: visualisationNormalizedValues[name] };
          }, {})
        : {};
    const visualisationValuesForPivot =
      incisionsInHeaderNames.length && !isRealData
        ? Object.keys(visualisationNormalizedValues).reduce((object, name) => {
            if (!incisionsInHeaderNames.includes(name)) {
              return { ...object, [name]: visualisationNormalizedValues[name] };
            }
            return object;
          }, {})
        : {};
    const { data, columns } =
      incisionsInHeaderNames.length && !isRealData
        ? getDataForPivotTable({
            incisionsHeader,
            visualisationValues: visualisationValuesForPivot,
            allColumns: dataSettings,
            columnsSettings,
            formatting,
            properties,
            makeHyperLinks,
            grouping,
            hashNames: incisionsInHeaderNames,
            isRealData,
          })
        : getTableData({
            allColumns: dataSettings,
            visualisationValues: visualisationNormalizedValues,
            properties,
            formatting,
            makeHyperLinks,
            countIncisions: dataSettings.incisions.length,
            grouping,
            isRealData,
            columnsSettings,
          });
    setMinMaxIndicators({});
    minMaxFunc({ columns, payloadRequest: visualisationNormalizedValues });
    setTableData({ data, columns });
    setSqlRequestForPivot(null);
  }, [
    isRealData,
    incisionsInHeaderNames,
    visualisationNormalizedValues,
    properties,
    formatting,
    makeHyperLinks,
    grouping,
    dataSettings,
    viewSettings.subtotalsSettings.location,
    subtotalsSettings.isShow,
    columnsSettings,
  ]);

  const getColors = ({
    column,
    rowData,
    parentsChain,
    properties,
  }: {
    column: Column;
    rowData: DataTableInterface;
    parentsChain: string;
    properties: DefaultPropertiesInterface;
  }) => {
    const {
      Header,
      dataAccessor,
      properties: {
        isActiveBackgroundColor,
        isActiveFontColor,
        backgroundColor: backgroundColorColumn,
        fontColor: fontColorColumn,
        backgroundColorBy: { type: columnBackgroundType, byValue: byValueBackgroundColor },
        fontColorBy: { type: columnFontType, byValue: byValueFontColor },
      },
    } = column;
    const {
      valueFromBD,
      valueBackgroundColorByCondition,
      valueFontColorByCondition,
      backgroundColorBy: backgroundBy,
      fontColorBy: fontBy,
      isSubtotal,
    } = rowData;
    const { backgroundColor, fontColor: fontColorCell } = properties;

    const isDefaultTypeBackground = isActiveBackgroundColor
      ? columnBackgroundType === 'default'
      : backgroundByValueSettings.type === 'default';
    const isDefaultTypeFont = isActiveFontColor ? columnFontType === 'default' : colorByValueSettings.type === 'default';
    const backgroundColorDefault = isActiveBackgroundColor
      ? getColorValues(backgroundColorColumn)
      : getColorValues(backgroundColor);
    const fontColorDefault = isActiveFontColor ? getColorValues(fontColorColumn) : getColorValues(fontColorCell);

    const minMax = minMaxIndicators[Header + parentsChain];
    const backgroundColorByValue =
      minMax &&
      columnBackgroundType === 'value' &&
      (valueFromBD || valueFromBD === 0) &&
      byValueBackgroundColor.colors?.[colorAlias]
        ? getColorByValueMinMax<ColorByItem>({
            min: minMax[0],
            max: minMax[1],
            colors: byValueBackgroundColor.colors[colorAlias],
            value: valueFromBD,
          })
        : null;
    const fontColorByValue =
      minMax && columnFontType === 'value' && (valueFromBD || valueFromBD === 0) && byValueFontColor.colors?.[colorAlias]
        ? getColorByValueMinMax<ColorByItem>({
            min: minMax[0],
            max: minMax[1],
            colors: byValueFontColor.colors[colorAlias],
            value: valueFromBD,
          })
        : null;
    const commonBackgroundColorByCondition =
      !isDefaultTypeBackground && valueBackgroundColorByCondition
        ? getColorByValue({
            value: valueBackgroundColorByCondition,
            indicatorName: dataAccessor,
            alias: backgroundByValueSettings?.byCondition.alias,
          })
        : backgroundColorDefault;
    const commonFontColorByCondition =
      !isDefaultTypeFont && valueFontColorByCondition
        ? getColorByValue({
            value: valueFontColorByCondition,
            indicatorName: dataAccessor,
            alias: colorByValueSettings?.byCondition.alias,
          })
        : fontColorDefault;
    const background =
      (isActiveBackgroundColor && !isDefaultTypeBackground) || properties.isActiveBackgroundColor
        ? getColorValues(backgroundBy) || (backgroundColorByValue && getColorValues(backgroundColorByValue.value))
        : commonBackgroundColorByCondition;
    const fontColor = isSubtotal
      ? getColorValues(subtotalsSettings.properties.fontColor)
      : (isActiveFontColor && !isDefaultTypeFont) || properties.isActiveFontColor
      ? getColorValues(fontBy) || (fontColorByValue && getColorValues(fontColorByValue.value))
      : commonFontColorByCondition;

    return { fontColor, background };
  };

  /* Generate Total Data */

  const isTotalShow = totalRowSettings.isShow;
  const isTotalTop = totalRowSettings.location.position.value === 'top';

  const totalValues = useMemo<TotalValuesType>(() => {
    if (!isTotalShow) {
      return {};
    }

    if (dataSettings.isRealData) {
      return loadedTotalValues;
    }

    return getCalculatedTotalValues({
      indicators: dataSettings.indicators,
      visualisationValues: visualisationNormalizedValues,
    });
  }, [isTotalShow, visualisationNormalizedValues, dataSettings.indicators, dataSettings.isRealData, loadedTotalValues]);

  const totalSqlRequest = useMemo(() => {
    if (dataSettings.isRealData && isTotalShow) {
      const totalSql = getTotalSqlRequest({
        indicators: dataSettings.indicators,
        from,
        //@ts-ignore
        where: generateUnionWhereIn([whereAstData, where].filter((where) => !!where)),
      });

      if (sqlRequestForTotalRowPivot && totalSql) {
        const regex = /(SELECT\s+)/i;
        return totalSql.replace(regex, `$1${sqlRequestForTotalRowPivot.sqlRequest} `);
      }

      return totalSql;
    }
  }, [isTotalShow, dataSettings.isRealData, from, whereAstData, dataSettings.indicators, where, sqlRequestForTotalRowPivot]);

  const loadTotalValues = useCallback(
    (sqlRequest: string) => {
      const abortController = new AbortController();

      setLoadedTotalValues({});

      const request = dispatch(loadVisualisationValuesAction({ projectId, sqlRequest, modelId, rls }));

      abortController.signal.addEventListener('abort', () => {
        request.abort();
      });

      request
        .then(({ payload }) => {
          if (payload) {
            const totalValues = transformLoadedValuesToTotalValues(payload as VisualisationValuesInterface);

            Object.keys(totalValues).forEach((accessor) => {
              const totalSettings = dataSettings.indicators.find((indicator) => indicator.name === accessor)?.settings
                .totalSettings;
              const formattingFunction = formatting && formatting[accessor];
              const value = totalValues[accessor];
              const totalValue = formattingFunction && formattingFunction(value);
              const customValue =
                !totalSettings?.isAutoAggregation && totalSettings?.formatting?.isShow
                  ? chainFormatter(totalSettings.formatting.formats, String(value))
                  : null;

              if (totalValue) {
                totalValues[accessor] = customValue || totalValue;
              }
            });

            setLoadedTotalValues(totalValues);
          }
        })
        .catch((e) => {
          /* TODO: Добавить адекватный логер */
          console.log(e);
        });

      return abortController;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [projectId, rls, modelId, tableData],
  );

  useEffect(() => {
    if (totalSqlRequest) {
      const request = loadTotalValues(totalSqlRequest);

      return () => {
        request.abort();
      };
    } else {
      setLoadedTotalValues({});
    }
  }, [totalSqlRequest, loadTotalValues]);

  useEffect(() => {
    async function fetchPivotTableData() {
      if (!sqlRequestForHeader || !sqlRequestTable) {
        return;
      }
      let allSqlRequest = '';

      const request = await dispatch(
        loadVisualisationValuesAction({
          projectId,
          sqlRequest: allSqlRequest,
          modelId,
          rls,
        }),
      );

      if (!request.payload) {
        return;
      }

      const payloadRequest = request.payload as VisualisationValuesInterface;

      const { sqlRequest, hashNames } = GetSqlRequestForHeaderPivotTable({
        incisionsInHeader: dataSettings.incisionsInHeader,
        valuesWithHeader: payloadRequest,
        indicators: dataSettings.indicators,
      });
      allSqlRequest += `${sqlRequest} `;

      const regex = /(SELECT\s+)/i;
      const allSQLForPivot = sqlRequestTable.replace(regex, `$1${allSqlRequest} `);

      const requestAllValues = await dispatch(
        loadVisualisationValuesAction({
          projectId,
          sqlRequest: allSQLForPivot,
          modelId,
          rls,
        }),
      );

      if (!requestAllValues.payload) {
        return;
      }

      const { columns, data } = getDataForPivotTable({
        incisionsHeader: payloadRequest,
        visualisationValues: requestAllValues.payload as VisualisationValuesInterface,
        allColumns: dataSettings,
        columnsSettings,
        formatting,
        properties,
        makeHyperLinks,
        grouping,
        hashNames,
        isRealData,
      });
      setSqlRequestForTotalRowPivot({ sqlRequest, hashNames });
      setSqlRequestForPivot({ sqlRequest: allSQLForPivot, hashNames });
      setTableData({ columns, data: data });
    }

    fetchPivotTableData();
  }, [
    rls,
    isRealData,
    sqlRequestForHeader,
    dataSettings,
    sqlRequestTable,
    dispatch,
    projectId,
    modelId,
    columnsSettings,
    properties,
    makeHyperLinks,
    grouping,
    formatting,
  ]);

  // =============== fetch data in group ===============
  const addDataWithGroup = ({
    column,
    valueColumn,
    isExpanded,
    indexRow,
    indexColumn,
  }: {
    column: string;
    valueColumn: string;
    isExpanded: boolean;
    indexRow: number;
    indexColumn: number;
  }) => {
    setIsLoadingData(true);
    if (isExpanded) {
      setTableData((prev) => {
        let indexColumnInGroup = indexColumn;
        const row = prev.data[indexRow];
        const cell = row[indexColumn];

        row.forEach((cell) => (cell.isSubtotal = false));

        const {
          isShow,
          location: { position },
        } = subtotalsSettings;

        if (isShow && position?.value === 'top') {
          const totalRow = prev.data.splice(indexRow + 1, 1);
          totalRow[0].forEach((cell, index) => {
            row[index].value = index >= countIncisions ? cell.value : row[index].value;
          });
        }

        for (let i = indexRow + 1; i < prev.data.length; i) {
          if (prev.data[i][indexColumnInGroup + 1]?.isExpanded) {
            prev.data[i][indexColumnInGroup + 1].isExpanded = false;
            prev.data.splice(i, 1);
            indexColumnInGroup = indexColumnInGroup + 1;
            continue;
          }

          if (prev.data[i][indexColumnInGroup + 1]?.value !== null) {
            prev.data.splice(i, 1);
          } else if (indexColumnInGroup !== indexColumn) {
            indexColumnInGroup = indexColumnInGroup - 1;
          } else {
            break;
          }
        }
        cell.isExpanded = false;

        if (isShow && position?.value === 'bottom') {
          const totalRow = prev.data.splice(indexRow + 1, 1);
          totalRow[0].forEach((cell, index) => {
            row[index].value = index >= countIncisions ? cell.value : row[index].value;
          });
        }

        return prev;
      });
      setIsLoadingData(false);
      return;
    }

    let hasLoadingRow = false;

    const timeoutGroupLoading = setTimeout(() => {
      if (!isRealData) {
        return;
      }
      setTableData((prev) => {
        const newData = [...prev.data];
        const row = newData[indexRow];

        hasLoadingRow = true;
        const loadRow: DataTableInterface[] = row.map((cell, index) => ({
          ...cell,
          value: index < countIncisions && cell.value ? 'загрузка...' : '',
          isTotalRow: true,
          isSubtotal: true,
        }));

        newData.splice(indexRow + 1, 0, loadRow);
        return { ...prev, data: newData };
      });
    }, 500);

    const updateData = (payload: VisualisationValuesInterface) => {
      const excludeGroups = columnNextIndex ? grouping.slice(0, columnNextIndex - 1) : grouping,
        { data, columns } =
          sqlRequestForTotalRowPivot?.sqlRequest || (!isRealData && incisionsInHeaderNames.length)
            ? getDataForPivotTable({
                incisionsHeader: payload,
                visualisationValues: payload,
                allColumns: dataSettings,
                columnsSettings,
                properties,
                makeHyperLinks,
                grouping,
                formatting,
                excludeGroups,
                parentsChain: parentsChainTotal,
                hashNames:
                  isRealData && sqlRequestForTotalRowPivot ? sqlRequestForTotalRowPivot.hashNames : incisionsInHeaderNames,
                isRealData,
              })
            : getTableData({
                allColumns: dataSettings,
                visualisationValues: payload,
                columnsSettings,
                properties,
                formatting,
                makeHyperLinks,
                countIncisions: dataSettings.incisions.length,
                grouping,
                excludeGroups,
                parentsChain: parentsChainTotal,
                isRealData,
              });

      minMaxFunc({ columns, payloadRequest: payload, parentsChain: parentsChainTotal.join('>') });

      setTableData((prev) => {
        const row = prev.data[indexRow];
        const cell = row[indexColumn];
        const {
          location: { position },
          isShow,
        } = subtotalsSettings;
        cell.isExpanded = true;
        cell.countChildren = data.length;

        hasLoadingRow && prev.data.splice(indexRow + 1, 1);
        prev.data.splice(indexRow + 1, 0, ...data);

        if (isShow) {
          const totalRow: DataTableInterface[] = JSON.parse(JSON.stringify(row));
          totalRow.forEach((cell, index) => {
            cell.value = index < countIncisions && cell.value ? `Итог ${cell.value}` : cell.value;
            cell.isTotalRow = true;
            cell.isSubtotal = true;
          });

          row.forEach((cell, index) => {
            cell.value = index >= countIncisions ? null : cell.value;
          });

          position?.value === 'top' && prev.data.splice(indexRow + 1, 0, totalRow);
          position?.value === 'bottom' && prev.data.splice(indexRow + data.length + 1, 0, totalRow);
        }

        return {
          columns: prev.columns,
          data: prev.data,
        };
      });
      setIsLoadingData(false);
    };

    const columnNext = grouping[grouping.indexOf(column) + 1];
    const columnNextIndex = columnNext ? grouping.indexOf(column) + 2 : dataSettings.incisions.length;
    const { parentsChain } = tableData.data[indexRow][indexColumn];
    const parentsChainTotal = parentsChain ? [...parentsChain, valueColumn] : [valueColumn];

    if (!isRealData) {
      dataSettings.incisions.map((incision) => {
        incision.settings.isGroup;
      });

      const fictionalDataColumnNext = dataSettings.incisions[columnNextIndex - 1]?.fictionalData;

      const incisionsValues = dataSettings.incisions
        .slice(0, columnNextIndex)
        .reduce((values, { fieldName, name, fictionalData, settings: { nameFromDatabase } }) => {
          const incisionName = getVisualisationFieldName({ fieldName, name, nameFromDatabase });

          return { ...values, [incisionName]: fictionalData.slice(0, fictionalDataColumnNext?.length) };
        }, {});

      const indicatorsValues = dataSettings.indicators.reduce((values, { fieldName, name, settings: { nameFromDatabase } }) => {
        const indicatorName = getVisualisationFieldName({ fieldName, name, nameFromDatabase });

        return {
          ...values,
          [indicatorName]: fictionalDataColumnNext.map(() => getRandomInt(0, 100)),
        };
      }, {});

      updateData({
        ...incisionsValues,
        ...indicatorsValues,
      });
      setIsLoadingData(false);
    }

    let sqlRequest = getSqlRequestForGroupingRow({
      id,
      columnNextIndex,
      parentsChain: parentsChainTotal,
      limitData: limitGrouping.isActive
        ? {
            seperator: ',',
            value: [
              {
                type: 'number',
                value: limitGrouping.value || 1,
              },
            ],
          }
        : undefined,
      incisionsInHeaderNames,
    });

    if (!sqlRequest) {
      return;
    }

    if (sqlRequestForTotalRowPivot) {
      const regex = /(SELECT\s+)/i;
      sqlRequest = sqlRequest.replace(regex, `$1${sqlRequestForTotalRowPivot.sqlRequest} `);
    }

    const request = dispatch(loadVisualisationValuesAction({ projectId, sqlRequest, modelId, rls }));

    request
      .then(({ payload }) => {
        clearTimeout(timeoutGroupLoading);
        if (payload) {
          updateData(payload as VisualisationValuesInterface);
        }
      })
      .catch((e) => {
        /* TODO: Добавить адекватный логер */
        console.log(e);
      });
  };

  // =============== infinity scroll fetch ===============
  const fetchData = useCallback(
    () => {
      setIsLoadingData(true);

      if (!sqlRequestTable) {
        return;
      }

      const request = dispatch(
        loadVisualisationValuesAction({
          projectId,
          sqlRequest: `${sqlRequestForPivot?.sqlRequest || sqlRequestTable} OFFSET ${offsetSqlRequest}`,
          modelId,
          rls,
        }),
      );

      request
        .then(({ payload }) => {
          if (payload) {
            const payloadRequest = payload as VisualisationValuesInterface;

            if (Object.keys(payloadRequest).length === 0) {
              setHasMoreData(false);
              setIsLoadingData(false);
              return;
            }

            const { data, columns } = sqlRequestForPivot?.sqlRequest
              ? getDataForPivotTable({
                  incisionsHeader: payloadRequest,
                  visualisationValues: payloadRequest,
                  allColumns: dataSettings,
                  columnsSettings,
                  formatting,
                  properties,
                  makeHyperLinks,
                  grouping,
                  hashNames: sqlRequestForPivot.hashNames,
                  isRealData,
                })
              : getTableData({
                  allColumns: dataSettings,
                  visualisationValues: payloadRequest,
                  columnsSettings,
                  properties,
                  formatting,
                  makeHyperLinks,
                  countIncisions: dataSettings.incisions.length,
                  grouping,
                  isRealData,
                });

            minMaxFunc({ columns, payloadRequest });

            setTableData((prev) => {
              prev.data.push(...data);

              return {
                columns: prev.columns,
                data: prev.data,
              };
            });
            setOffsetSqlRequest(offsetSqlRequest + 25);
            setIsLoadingData(false);
          }
        })
        .catch(() => {
          setHasMoreData(false);
          setIsLoadingData(false);
        });
    }, // eslint-disable-next-line
    [sqlRequestTable, rls, offsetSqlRequest, sqlRequestForPivot],
  );

  useEffect(() => {
    if (inViewDownObserve && hasMoreData && !isLoadingData) {
      fetchData();
    }
  }, [inViewDownObserve, fetchData, hasMoreData, isLoadingData]);

  const getPaddingString = useCallback(
    (paddings: IndentationInterface, priority: boolean, priorityColumn?: boolean) => {
      const { top, bottom, left, right } = paddings;
      const { top: defaultTop, left: defaultLeft, bottom: defaultBottom, right: defaultRight } = bodySettings.indentation;

      return priorityColumn
        ? `${top}px ${right}px ${bottom}px ${left}px`
        : `${priority ? top : defaultTop}px ${defaultRight}px ${priority ? bottom : defaultBottom}px ${defaultLeft}px`;
    },
    [bodySettings.indentation],
  );

  const onChartClick = useCallback(
    (value: string, nameIncision: string) => {
      const fieldName = [...dataSettings.incisions, ...dataSettings.incisionsInHeader].find(
        (incision) => incision.name === nameIncision,
      )?.fieldName;

      if (fieldName) {
        updateFilter({ selectedValue: value, fieldName });
      }
    },
    [updateFilter, dataSettings],
  );

  return (
    <StyledTableWrapper
      showBackground={viewSettings.showBackground}
      isAdaptive={viewSettings.isAdaptive}
      verticalLine={colorInvertInHEX(bodySettings.verticalLines)}
      horizontalLine={colorInvertInHEX(bodySettings.horizontalLines)}
      externalBeat={colorInvertInHEX(bodySettings.externalBeat)}
      beatColumnLine={viewSettings.incisionBeat.includes('line')}
      beatHeaderBackground={viewSettings.headerBeat.includes('background')}
      beatHeaderLine={colorInvertInHEX(headerSettings.headerBeatLine)}
      beatTotalBackground={viewSettings.totalBeat.includes('background')}
      beatTotalLine={viewSettings.totalBeat.includes('line')}
      isTotalTop={isTotalTop}
      isTotalShow={isTotalShow}
      incisionsCount={countIncisions}
      countRowsInHeader={tableData.columns.length}
      ref={tableRef}
    >
      <TanStackTableStyled>
        <TableHead>
          {headerSettings.isShow &&
            tableData.columns.map((columns, index) => {
              return (
                <TableRow key={index}>
                  {columns.map((column, indexRow) => {
                    /*TODO раскомментировать всё когда появится группировка в хедере*/
                    const {
                      id: columnId,
                      Header,
                      colSpan,
                      rowSpan,
                      columnsSettings: { columnWidth, hasIndentation, indentation },
                      isIncision,
                      isIncisionInHeader,
                      properties,
                      dataAccessor,
                      /* isGroup, */
                      /* isExpanded, */
                    } = column;
                    const sortItsColumn = dataSettings.orderBy.filter((el) => el.columnName === Header)?.[0];
                    const isLowerLevel = index === tableData.columns.length - 1;
                    /* const isLastRowIncisionsInHeader = index === tableData.columns.length - 2; */
                    const isFirstBlockInPivot = !!incisionsInHeaderNames.length && index === 0 && indexRow === 0;
                    const {
                      alignment: { horizontal, vertical },
                    } = properties.isActiveStyle
                      ? properties
                      : isIncision
                      ? bodySettings.propertiesIncisions
                      : bodySettings.propertiesIndicators;
                    const { fontSize, lineHeight, opacity, letterSpacing, fontStyle } =
                      isIncisionInHeader && properties.isActiveStyle ? properties : headerSettings.properties;
                    const { backgroundOpacity } = headerSettings.properties;
                    const { left, right } = hasIndentation ? indentation : headerSettings.indentation;
                    const { top, bottom } = headerSettings.hasIndentation ? headerSettings.indentation : bodySettings.indentation;
                    const indentationCell = { left, right, top, bottom };
                    const backgroundHeader = headerSettings.overfill ? headerSettings.properties.backgroundColor : undefined;
                    const { fontColor } = properties.isActiveFontColor ? properties : headerSettings.properties;
                    const backgroundBody = properties.isActiveBackgroundColor
                      ? properties.backgroundColor
                      : isIncision
                      ? bodySettings.propertiesIncisions.backgroundColor
                      : bodySettings.propertiesIndicators.backgroundColor;

                    const backgroundColorBody = getColorValues(backgroundBody) || undefined;
                    const backgroundColorHeader = backgroundHeader && getColorValues(backgroundHeader);
                    const font = getColorValues(fontColor) || undefined;

                    if (!!columnVisibility.length && !columnVisibility.includes(Header) && isIncision) {
                      return null;
                    }

                    return (
                      <TableHeader
                        key={columnId}
                        width={columnWidth}
                        onClick={
                          isLowerLevel
                            ? onSortClick({
                                dataSettings,
                                columnName: Header,
                                sortingColumn: sortItsColumn,
                                isSorted: sortItsColumn?.columnName === Header,
                                id,
                              })
                            : undefined
                        }
                        colSpan={!isFirstBlockInPivot ? colSpan : columnVisibility.length}
                        rowSpan={rowSpan}
                        backgroundColor={backgroundColorBody}
                      >
                        {backgroundColorHeader && (
                          <AbsoluteColorBlock backgroundColor={backgroundColorHeader} opacity={backgroundOpacity} />
                        )}

                        <TableTextBody
                          justifyContent={horizontal}
                          alignItems={vertical}
                          textAlign={formattingNameTextAlign(horizontal)}
                          padding={getPaddingString(indentationCell, headerSettings.hasIndentation, hasIndentation)}
                        >
                          {/*{!isLastRowIncisionsInHeader && isGroup && isIncisionInHeader && (*/}
                          {/*  <ButtonWrapper*/}
                          {/*    onClick={() => {*/}
                          {/*      console.log('NO GROUPING');*/}
                          {/*    }}*/}
                          {/*  >*/}
                          {/*    <PrimaryTextSpan lineHeight={'12px'}>{isExpanded ? '–' : '+'}</PrimaryTextSpan>*/}
                          {/*  </ButtonWrapper>*/}
                          {/*)}*/}
                          <PrimaryTextSpan
                            fontSize={`${fontSize}px`}
                            lineHeight={`${lineHeight}%`}
                            color={font}
                            opacity={opacity}
                            letterSpacing={`${letterSpacing}px`}
                            fontWeight={fontStyle.bold ? 'bold' : 'normal'}
                            fontStyle={fontStyle.italic ? 'italic' : 'normal'}
                            textDecoration={fontStyle.underline ? 'underline' : undefined}
                            onClick={() => isIncisionInHeader && onChartClick(Header, dataAccessor)}
                          >
                            {Header}
                          </PrimaryTextSpan>
                          {isLowerLevel && (
                            <PrimaryTextSpan lineHeight="12px">
                              {sortItsColumn?.columnName === Header &&
                                (sortItsColumn?.type === 'DESC' ? <DownFilterIcon /> : <UpFilterIcon />)}
                            </PrimaryTextSpan>
                          )}
                        </TableTextBody>
                      </TableHeader>
                    );
                  })}
                </TableRow>
              );
            })}
          {isTotalShow && isTotalTop && (
            <TotalComponent
              columns={tableData.columns[tableData.columns.length - 1] || []}
              columnVisibility={columnVisibility}
              totalValues={totalValues}
              hashName={sqlRequestForTotalRowPivot?.hashNames}
              countIncisions={dataSettings.incisions.length}
              settings={totalRowSettings}
              bodySettings={bodySettings}
            />
          )}
        </TableHead>
        {/* @ts-ignore */}
        <TransitionGroup component={TableBody}>
          {tableData.data.map((rows, indexRow) => {
            const lastRowClassName = indexRow === tableData.data.length - 1 ? lastRowClass : undefined;

            return (
              <CSSTransition key={indexRow} timeout={200} classNames="fade">
                <TableRow className={lastRowClassName}>
                  {rows.map((row, index) => {
                    const column = tableData.columns[tableData.columns.length - 1][index] || tableData.columns[0][index];
                    const {
                      properties,
                      isHyperLink,
                      isIncision,
                      Header,
                      isGroup,
                      columnsSettings: { hasIndentation, indentation },
                    } = column;
                    const { id, value, isExpanded, accessorKey, strParentsChain, isTotalRow, isSubtotal } = row;
                    const propertiesCell = isIncision ? bodySettings.propertiesIncisions : bodySettings.propertiesIndicators;
                    const { fontColor, background } = getColors({
                      column,
                      rowData: row,
                      parentsChain: strParentsChain,
                      properties: propertiesCell,
                    });
                    const { fontSize, lineHeight, opacity, letterSpacing, fontStyle, backgroundOpacity } = isSubtotal
                      ? subtotalsSettings.properties
                      : properties.isActiveStyle
                      ? properties || propertiesCell
                      : propertiesCell;
                    const {
                      alignment: { horizontal, vertical },
                    } = properties.isActiveStyle ? properties || propertiesCell : propertiesCell;
                    const indentationCell = hasIndentation
                      ? indentation
                      : isSubtotal
                      ? subtotalsSettings.indentation
                      : bodySettings.indentation;
                    const { backgroundOpacity: bodyBackgroundOpacity } = propertiesCell;
                    const priorityIndentation = isSubtotal ? subtotalsSettings.hasIndentation : false;
                    const rowsBeatColor = bodySettings.rowsBeat.isBeat ? getColorValues(bodySettings.rowsBeat.color) : null;

                    if (!!columnVisibility.length && !columnVisibility.includes(Header) && isIncision) {
                      return null;
                    }

                    return (
                      <TableCell key={id} className={tableCellItem} backgroundColor={background} opacity={bodyBackgroundOpacity}>
                        {isSubtotal && subtotalsSettings.overfill && (
                          <AbsoluteColorBlock
                            backgroundColor={getColorValues(subtotalsSettings.properties.backgroundColor)}
                            opacity={backgroundOpacity}
                          />
                        )}
                        {bodySettings.rowsBeat.isBeat && indexRow % 2 === 0 && (
                          <AbsoluteColorBlock
                            backgroundColor={
                              (!isArray(rowsBeatColor) && rowsBeatColor) || activeThemeSchema[ColorVarsEnum.Level_1]
                            }
                            opacity={bodySettings.rowsBeat.countUnit}
                          />
                        )}

                        <TableTextBody
                          justifyContent={horizontal}
                          alignItems={vertical}
                          textAlign={formattingNameTextAlign(horizontal)}
                          padding={getPaddingString(indentationCell, priorityIndentation, hasIndentation)}
                        >
                          {value === null ? (
                            ''
                          ) : (
                            <>
                              {!isTotalRow && isGroup && isIncision && index !== dataSettings.incisions.length - 1 && (
                                <ButtonWrapper
                                  onClick={() => {
                                    !isLoadingData &&
                                      addDataWithGroup({
                                        column: accessorKey,
                                        valueColumn: value,
                                        isExpanded,
                                        indexRow,
                                        indexColumn: index,
                                      });
                                  }}
                                  backgroundColor={background}
                                  opacity={bodyBackgroundOpacity}
                                  color={fontColor || undefined}
                                >
                                  <PrimaryTextSpan lineHeight="12px" color={fontColor || undefined}>
                                    {isExpanded ? '–' : '+'}
                                  </PrimaryTextSpan>
                                </ButtonWrapper>
                              )}
                              <PrimaryTextSpan
                                fontSize={`${fontSize}px`}
                                lineHeight={`${lineHeight}%`}
                                color={fontColor || undefined}
                                opacity={opacity}
                                letterSpacing={`${letterSpacing}px`}
                                fontWeight={fontStyle.bold ? 'bold' : 'normal'}
                                fontStyle={fontStyle.italic ? 'italic' : 'normal'}
                                textDecoration={fontStyle.underline ? 'underline' : undefined}
                                padding={isTotalRow && isIncision ? '0 0 0 20px' : undefined}
                                onClick={() => isIncision && onChartClick(String(value), accessorKey)}
                              >
                                {isHyperLink && isURL(value) ? (
                                  <TextLink
                                    color={`var(${ColorVarsEnum.Accent})`}
                                    href={addProtocolToLink(value)}
                                    target="_blank"
                                  >
                                    {value}
                                  </TextLink>
                                ) : (
                                  value
                                )}
                              </PrimaryTextSpan>
                            </>
                          )}
                        </TableTextBody>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </CSSTransition>
            );
          })}
          {isRealData && <Observe ref={observeDownRef} />}
          {isTotalShow && !isTotalTop && (
            <TotalComponent
              columns={tableData.columns[tableData.columns.length - 1] || []}
              columnVisibility={columnVisibility}
              totalValues={totalValues}
              hashName={sqlRequestForTotalRowPivot?.hashNames}
              countIncisions={dataSettings.incisions.length}
              settings={totalRowSettings}
              bodySettings={bodySettings}
            />
          )}
        </TransitionGroup>
      </TanStackTableStyled>
    </StyledTableWrapper>
  );
};

export const TableVisualisation = memo(TableVisualisationComponent) as VisualisationOriginInterface<TableVisualisationType>;
