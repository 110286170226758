import isArray from 'lodash/isArray';
import isUndefined from 'lodash/isUndefined';
import { onChangeOrderById } from 'modules/visualisations/common/onChangeFunctions';
import { FormattingRecordType } from 'modules/visualisations/hooks/useFormat';
import { PropertiesRecordType } from 'modules/visualisations/hooks/useProperties';
import {
  Column,
  ColumnSettingsInterface,
  DataTableInterface,
  MakeHyperLinksRecordType,
  OnSortChangeInterface,
  TotalValuesType,
} from 'modules/visualisations/Table/visualisation/types';
import { IndicatorAstType } from 'store/reducers/ast/types';
import {
  backgroundByValueAlias,
  backgroundValueAlias,
  colorValueAlias,
  colorValueByAlias,
  getVisualisationFieldName,
} from 'store/reducers/visualisations/constants';
import { TableDataSettings, VisualisationDataInterface, VisualisationValuesInterface } from 'store/reducers/visualisations/types';
import { AST } from 'types/ast';
import { generateSqlSelectValue } from 'utils/SQL/generateSQL';
import { defaultSelectAST, generateBasicFunctionColumn, getSelectColumnsFromSqlString } from 'utils/SQL/genereteAst';
import { getSql } from 'utils/SQL/generateSQL';
import { getRandomInt, getSumOfArrayValues } from 'utils/utils';
import { ColorByConditionUtils } from 'utils/visualisations';
import { v4 } from 'uuid';
import { Statement } from 'pgsql-ast-parser';

export const loaderSize = '40px';
export const lastRowClass = 'last-row';

interface GetTableDataParams {
  visualisationValues: VisualisationValuesInterface;
  allColumns: TableDataSettings;
  columnsSettings: ColumnSettingsInterface;
  properties: PropertiesRecordType;
  makeHyperLinks: MakeHyperLinksRecordType;
  formatting?: FormattingRecordType;
  countIncisions?: number;
  grouping: string[];
  excludeGroups?: string[];
  parentsChain?: string[];
  isRealData?: boolean;
}

export const getColumnData: (params: GetTableDataParams) => Column[][] = ({
  allColumns,
  properties,
  makeHyperLinks,
  countIncisions,
  grouping,
  columnsSettings,
}) => {
  const columns = [...allColumns.incisions, ...allColumns.indicators].map((column) =>
    column.settings.nameFromDatabase ? column.fieldName || '' : column.name,
  );

  return [
    columns.reduce<Column[]>(
      (columns, indicatorName, index) =>
        ![colorValueAlias, backgroundValueAlias, colorValueByAlias, backgroundByValueAlias].includes(indicatorName)
          ? [
              ...columns,
              {
                Header: indicatorName,
                id: v4(),
                dataAccessor: indicatorName,
                parentAccessor: '',
                isGroup: grouping.includes(indicatorName),
                isIncision: countIncisions ? index < countIncisions : false,
                columnsSettings: columnsSettings[indicatorName],
                properties: properties[indicatorName],
                isHyperLink: !!makeHyperLinks[indicatorName],
                colSpan: 1,
                rowSpan: 1,
              },
            ]
          : columns,
      [],
    ),
  ];
};

export const getTableData = ({
  allColumns,
  visualisationValues,
  properties,
  formatting,
  makeHyperLinks,
  countIncisions,
  grouping,
  excludeGroups,
  parentsChain,
  isRealData,
  columnsSettings,
}: GetTableDataParams) => {
  const columns = getColumnData({
    allColumns,
    visualisationValues,
    properties,
    makeHyperLinks,
    countIncisions,
    grouping,
    columnsSettings,
  });
  const accessors = columns[columns.length - 1]?.map(({ dataAccessor }) => dataAccessor),
    defaultColumnName = accessors[0],
    defaultVisualisationValues = visualisationValues[defaultColumnName] || [];

  const data = defaultVisualisationValues.map((_, index) =>
    accessors.reduce<DataTableInterface[]>((data, accessor, indexAccessor) => {
      const visualisationValuesOfColumn = visualisationValues[accessor] || [];
      const { backgroundColorBy, fontColorBy } = properties[accessor];
      const value = visualisationValuesOfColumn[index];
      const formattingFunction = formatting && formatting[accessor];
      const totalValue = formattingFunction && value ? formattingFunction(value) : value !== undefined ? String(value) : null;
      const backgroundColor =
        isRealData && visualisationValues[backgroundByValueAlias]
          ? ColorByConditionUtils({
              visualisationValues,
              colorBy: backgroundColorBy,
              alias: backgroundByValueAlias,
              index,
              indexAccessor,
            })
          : null;
      const fontColor =
        isRealData && visualisationValues[colorValueByAlias]
          ? ColorByConditionUtils({
              visualisationValues,
              colorBy: fontColorBy,
              alias: colorValueByAlias,
              index,
              indexAccessor,
            })
          : null;
      const valueBackgroundColorByCondition = visualisationValues[backgroundValueAlias]?.[index] || null;
      const valueFontColorByCondition = visualisationValues[colorValueAlias]?.[index] || null;
      const uniqueKey = columns
        .slice(0, columns.length - 1)
        .map((row) => row[index].Header)
        .join('>');

      return [
        ...data,
        {
          id: `${accessor}>${uniqueKey}>${indexAccessor}`,
          accessorKey: accessor,
          value: excludeGroups?.includes(accessor) ? null : totalValue || null,
          valueFromBD: Number(value),
          isExpanded: false,
          countChildren: 0,
          backgroundColorBy: backgroundColor,
          fontColorBy: fontColor,
          valueBackgroundColorByCondition,
          valueFontColorByCondition,
          parentsChain,
          strParentsChain: parentsChain ? parentsChain.join('>') : 'BigDad',
          isTotalRow: false,
        },
      ];
    }, []),
  );

  return { columns, data };
};

export const getColumnDataForPivotTable = ({
  incisionsHeader,
  allColumns,
  properties,
  makeHyperLinks,
  grouping,
  columnsSettings,
  isRealData,
}: {
  incisionsHeader: VisualisationValuesInterface;
  allColumns: TableDataSettings;
  properties: PropertiesRecordType;
  makeHyperLinks: MakeHyperLinksRecordType;
  grouping: string[];
  formatting?: FormattingRecordType;
  columnsSettings: ColumnSettingsInterface;
  isRealData?: boolean;
}) => {
  const columnsNameIncisions = allColumns.incisions
    .filter((incision) => (isRealData ? incision.fieldName : true))
    .map((incision) => (incision.settings.nameFromDatabase ? incision.fieldName || '' : incision.name));
  const columnsNameIndicators = allColumns.indicators
    .filter((indicator) => (isRealData ? indicator.fieldName : true))
    .map((indicator) => (indicator.settings.nameFromDatabase ? indicator.fieldName || '' : indicator.name));
  const columns: Column[][] = [];

  Object.keys(incisionsHeader)?.forEach((incisionName, index) => {
    if (isArray(incisionsHeader[incisionName])) {
      const dataInHeader: Column[] | undefined = incisionsHeader[incisionName]?.map((value) => {
        return {
          Header: String(value) || '',
          id: v4(),
          dataAccessor: incisionName,
          parentAccessor: index === 0 ? '' : Object.keys(incisionsHeader)[index - 1],
          isGroup: grouping.includes(incisionName),
          isExpanded: false,
          isIncisionInHeader: true,
          properties: properties[incisionName],
          columnsSettings: columnsSettings[incisionName],
          isHyperLink: !!makeHyperLinks[incisionName],
          colSpan: allColumns.indicators.length,
          rowSpan: 1,
        };
      });

      index === 0 &&
        !!columnsNameIncisions.length &&
        dataInHeader?.unshift({
          Header: '',
          id: v4(),
          dataAccessor: incisionName,
          parentAccessor: '',
          isGroup: false,
          columnsSettings: columnsSettings[incisionName],
          properties: properties[incisionName],
          isHyperLink: !!makeHyperLinks[incisionName],
          colSpan: columnsNameIncisions.length,
          rowSpan: allColumns.incisionsInHeader.filter((el) => (isRealData ? el.fieldName : true)).length,
        });
      dataInHeader && columns.push(dataInHeader);
    }
  });

  if (columns.length !== 0) {
    const columnsIncisions: Column[] = columnsNameIncisions.map((columnName) => {
      return {
        Header: String(columnName) || 'null',
        id: v4(),
        dataAccessor: columnName,
        parentAccessor: '',
        isGroup: grouping.includes(columnName),
        isIncision: true,
        columnsSettings: columnsSettings[columnName],
        properties: properties[columnName],
        isHyperLink: !!makeHyperLinks[columnName],
        colSpan: 1,
        rowSpan: 1,
      };
    });
    const columnsIndicators = columns[columns.length - 1]?.reduce<Column[]>((columnsData, { dataAccessor, Header }) => {
      if (!Header) {
        return columnsData;
      }

      const indicators = columnsNameIndicators.map((columnName) => {
        return {
          Header: String(columnName) || 'null',
          id: v4(),
          dataAccessor: columnName,
          parentAccessor: `${dataAccessor}>${Header}`,
          isGroup: grouping.includes(columnName),
          columnsSettings: columnsSettings[columnName],
          properties: properties[columnName],
          isHyperLink: !!makeHyperLinks[columnName],
          colSpan: 1,
          rowSpan: 1,
        };
      });
      return [...columnsData, ...indicators];
    }, []);
    columnsIndicators && columns.push([...columnsIncisions, ...columnsIndicators]);
  }

  return { columns };
};

interface GetDataForPivotTableInterface extends GetTableDataParams {
  incisionsHeader: VisualisationValuesInterface;
  allColumns: TableDataSettings;
  hashNames: string[];
}

export const getDataForPivotTable = ({
  incisionsHeader,
  visualisationValues,
  allColumns,
  properties,
  makeHyperLinks,
  grouping,
  formatting,
  hashNames,
  excludeGroups,
  parentsChain,
  columnsSettings,
  isRealData,
}: GetDataForPivotTableInterface) => {
  const data: DataTableInterface[][] = [];

  const { columns } = getColumnDataForPivotTable({
    incisionsHeader,
    columnsSettings,
    allColumns,
    properties,
    makeHyperLinks,
    grouping,
    formatting,
    isRealData,
  });

  const namesIncisions = allColumns.incisions
    .filter((incision) => (isRealData ? incision.fieldName : true))
    .map(({ name, fieldName, settings: { nameFromDatabase } }) =>
      getVisualisationFieldName({
        name,
        nameFromDatabase,
        fieldName,
      }),
    );

  namesIncisions.forEach((key) => {
    if (!visualisationValues[key]) {
      const cellData: DataTableInterface = {
        id: `${key}>${v4()}`,
        accessorKey: key,
        value: null,
        valueFromBD: null,
        isExpanded: false,
        countChildren: 0,
        backgroundColorBy: null,
        fontColorBy: null,
        valueBackgroundColorByCondition: null,
        valueFontColorByCondition: null,
        parentsChain,
        strParentsChain: 'BigDad',
        isTotalRow: false,
      };
      data.forEach((_, indexData) => {
        if (!data[indexData]) {
          data.push([]);
        }
        data[indexData].push(cellData);
      });
    }
    visualisationValues[key]?.forEach((value, indexValue) => {
      const cellData: DataTableInterface = {
        id: `${key}>${value}>${v4()}`,
        accessorKey: key,
        value: excludeGroups?.includes(key) ? null : String(value) || null,
        valueFromBD: Number(value),
        isExpanded: false,
        countChildren: 0,
        backgroundColorBy: null,
        fontColorBy: null,
        valueBackgroundColorByCondition: null,
        valueFontColorByCondition: null,
        parentsChain,
        strParentsChain: 'BigDad',
        isTotalRow: false,
      };
      if (!data[indexValue]) {
        data.push([]);
      }
      data[indexValue].push(cellData);
    });
  });

  isRealData
    ? hashNames.forEach((key) => {
        const lengthValues = visualisationValues[key]?.length;
        if (lengthValues) {
          const accessor = key.split('_')[1];
          const formattingFunction = formatting && formatting[accessor];

          for (let i = 0; i < lengthValues; i++) {
            const value = visualisationValues[key]?.[i];
            const totalValue =
              formattingFunction && value ? formattingFunction(value) : !isUndefined(value) ? String(value) : null;

            const cellData: DataTableInterface = {
              id: `${key}>${value}>${v4()}`,
              accessorKey: key,
              value: excludeGroups?.includes(accessor) ? null : totalValue || null,
              valueFromBD: Number(value),
              isExpanded: false,
              countChildren: 0,
              backgroundColorBy: null,
              fontColorBy: null,
              valueBackgroundColorByCondition: null,
              valueFontColorByCondition: null,
              parentsChain,
              strParentsChain: 'BigDad',
              isTotalRow: false,
            };
            if (!data[i]) {
              data.push([]);
            }
            data[i].push(cellData);
          }
        }
      })
    : columns[columns.length - 1]
        .filter((cell) => !namesIncisions.includes(cell.dataAccessor))
        .forEach(({ dataAccessor }) => {
          const lengthValues = visualisationValues[dataAccessor]?.length;
          if (lengthValues) {
            const formattingFunction = formatting && formatting[dataAccessor];

            for (let i = 0; i < lengthValues; i++) {
              const value = getRandomInt(0, 100);
              const totalValue =
                formattingFunction && value ? formattingFunction(value) : !isUndefined(value) ? String(value) : null;

              const cellData: DataTableInterface = {
                id: `${dataAccessor}>${value}>${v4()}`,
                accessorKey: dataAccessor,
                value: excludeGroups?.includes(dataAccessor) ? null : totalValue || null,
                valueFromBD: Number(value),
                isExpanded: false,
                countChildren: 0,
                backgroundColorBy: null,
                fontColorBy: null,
                valueBackgroundColorByCondition: null,
                valueFontColorByCondition: null,
                parentsChain,
                strParentsChain: 'BigDad',
                isTotalRow: false,
              };
              if (!data[i]) {
                data.push([]);
              }
              data[i].push(cellData);
            }
          }
        });

  return { columns, data };
};

interface GetTotalSqlRequestParams extends Pick<TableDataSettings, 'indicators'> {
  from: AST.FromFromParser;
  where: AST.WhereIn | AST.WhereBetween | AST.UnionAndExpressions | AST.WhereLike | null;
}

export const getTotalSqlRequest = ({ indicators, from, where }: GetTotalSqlRequestParams) => {
  const columns = indicators.reduce<IndicatorAstType[]>(
    (
      selectValues,
      {
        name,
        fieldName,
        customRequest,
        operationType,
        settings: {
          nameFromDatabase,
          totalSettings: { isShow, operationType: operationTypeManual, customRequest: totalCustomRequest, isAutoAggregation },
        },
      },
    ) => {
      if (isShow) {
        let selectValue: IndicatorAstType | null = null;
        const indicatorName = getVisualisationFieldName({ name, fieldName, nameFromDatabase }),
          allCustomRequest = isAutoAggregation ? customRequest : totalCustomRequest,
          totalOperationType = isAutoAggregation ? operationType : operationTypeManual;

        if (totalOperationType === 'other') {
          const selectQuery = generateSqlSelectValue(allCustomRequest, indicatorName);

          selectValue = getSelectColumnsFromSqlString(selectQuery)?.[0];
        } else if (fieldName) {
          selectValue = generateBasicFunctionColumn({ alias: indicatorName, fieldName, functionName: totalOperationType });
        }

        return selectValue ? [...selectValues, selectValue] : selectValues;
      }

      return selectValues;
    },
    [],
  );

  if (columns.length) {
    const a = getSql({ ...defaultSelectAST, columns, from, where } as unknown as Statement);

    return a;
  }
};

interface GetCalculatedTotalValuesParams extends Pick<TableDataSettings, 'indicators'>, Required<VisualisationDataInterface> {}

export const getCalculatedTotalValues: (params: GetCalculatedTotalValuesParams) => TotalValuesType = ({
  indicators,
  visualisationValues,
}) => {
  return indicators.reduce(
    (
      result,
      {
        name,
        fieldName,
        settings: {
          nameFromDatabase,
          totalSettings: { isShow },
        },
      },
    ) => {
      if (isShow) {
        const indicatorName = getVisualisationFieldName({ name, fieldName, nameFromDatabase });

        const indicatorData = visualisationValues[indicatorName] || [];

        return { ...result, [indicatorName]: getSumOfArrayValues(indicatorData) };
      }
      return result;
    },
    {},
  );
};

export const transformLoadedValuesToTotalValues = (loadedValues: VisualisationValuesInterface) =>
  Object.keys(loadedValues).reduce<TotalValuesType>((result, key) => {
    const values = loadedValues[key],
      value = Array.isArray(values) ? values[0] : 0,
      mayBeNumber = typeof value === 'string' && !Number.isNaN(parseInt(value)) ? parseInt(value) : value || 0,
      normalizedValue = typeof mayBeNumber === 'string' ? 0 : mayBeNumber;

    return { ...result, [key]: normalizedValue };
  }, {});

export const onSortClick =
  ({ dataSettings, columnName, id, sortingColumn, isSorted }: OnSortChangeInterface) =>
  () => {
    const order = dataSettings.orderBy.filter((el) => el.columnName !== columnName);

    if (!isSorted) {
      return onChangeOrderById(
        dataSettings,
        [
          ...order,
          {
            columnName,
            type: 'DESC',
          },
        ],
        id,
      );
    }

    if (sortingColumn?.type === 'ASC') {
      return onChangeOrderById(dataSettings, order, id);
    }

    return onChangeOrderById(
      dataSettings,
      [
        ...order,
        {
          columnName,
          type: 'ASC',
        },
      ],
      id,
    );
  };
