import { useEffect, useMemo, useState } from 'react';
import { loadFieldsAction } from 'store/reducers/filters/actions';
import { useParams } from 'react-router-dom';
import { ProjectIdParam } from 'constants/Routes';
import { useSelector } from 'react-redux';
import { getEnabledFilterByFilterId, getFiltersValuesStateByFilterId } from 'store/reducers/filters/getters';
import { IdInterface, SqlDataRequestInterface, WhereQueryInterface } from 'types/store';
import { useAppDispatch } from 'store';
import { EnabledFilterDataType, EnableFilterActionPayload, FilterDataType } from 'store/reducers/filters/types';
import { usePrimitiveHaveBeenChanged } from 'utils/hooks/primitiveHaveBeenChanged';
import { useModelIdValue } from 'utils/hooks/visualisation/modelIdValue';
import { getModelFromById } from 'store/reducers/models/getters';
import { MapRecordType, NoopValueType } from 'types/global';
import { LimitSettingInterface } from 'store/reducers/visualisations/types';
import { useRLS } from 'utils/hooks/visualisation/useRLS';

interface FilterQueryParams extends Pick<FilterDataType, 'nameSettings' | 'modelId'>, IdInterface, WhereQueryInterface {
  searchString?: string;
  isDataFilter?: boolean;
  isFetchRequiredWhenDataExists?: boolean;
  sqlData: SqlDataRequestInterface;
  limit?: LimitSettingInterface;
  sortingStatus?: string | null;
}

export const useFilterQuery = ({
  nameSettings,
  id,
  modelId,
  whereQuery,
  searchString = '',
  isDataFilter = false,
  isFetchRequiredWhenDataExists = false,
  sqlData,
  limit,
  sortingStatus,
}: FilterQueryParams) => {
  const dispatch = useAppDispatch();
  const { projectId } = useParams<ProjectIdParam>();
  const [isValuesOverLimit, setIsValuesOverLimit] = useState<boolean>(false);

  const { filterValues } = useSelector(getFiltersValuesStateByFilterId(id));

  const { rls } = useRLS();

  const modelIdValue = useModelIdValue(modelId || '');

  const fromQuery = useSelector(getModelFromById(modelIdValue))?.from;

  /* Loading filter values when changed fieldName or enabled filters list (sqlFilterString)   */
  useEffect(() => {
    if (isFetchRequiredWhenDataExists && filterValues.length && !searchString && !sortingStatus) {
      return;
    }

    if (nameSettings.fieldName && fromQuery && modelIdValue) {
      const request = dispatch(
        loadFieldsAction({
          id,
          projectId: projectId || '',
          fieldName: nameSettings.fieldName,
          whereQuery,
          fromQuery,
          modelId: modelIdValue,
          searchString,
          isDataFilter,
          sqlData,
          limit,
          sortingStatus,
          rls,
        }),
      );

      request.then((result) => {
        if (loadFieldsAction.fulfilled.match(result)) {
          const isOverLimit = result?.payload?.isOverLimit;

          setIsValuesOverLimit(isOverLimit);
        }
      });

      return () => {
        request.abort();
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameSettings.fieldName, rls, whereQuery, fromQuery, modelIdValue, searchString, sqlData, limit, sortingStatus]);

  return { dispatch, filterValues, modelIdValue, isValuesOverLimit };
};

export type UpdateEnableFilterDataFnType<EnabledFilterInterface extends EnabledFilterDataType> = NoopValueType<
  EnableFilterActionPayload<EnabledFilterInterface>
>;

interface EnabledFilterParams<EnabledFilterInterface extends EnabledFilterDataType>
  extends Pick<FilterDataType, 'type' | 'isGlobal' | 'nameSettings' | 'isRealData'>,
    IdInterface {
  selectedValues: EnabledFilterDataType['selectedValues'] | null;
  modelIdValue: string;
  filterInfluences: MapRecordType<boolean> | null;
  updateEnableFilterDataFn: UpdateEnableFilterDataFnType<EnabledFilterInterface>;
}

export const useEnabledFilter = <EnabledFilterInterface extends EnabledFilterDataType>({
  id,
  isRealData,
  nameSettings,
  isGlobal,
  selectedValues,
  type,
  modelIdValue,
  updateEnableFilterDataFn,
  filterInfluences,
}: EnabledFilterParams<EnabledFilterInterface>) => {
  const enabledFilter = useSelector(getEnabledFilterByFilterId(id));

  const enableFilterData = useMemo(
    () =>
      ({
        type,
        isGlobal,
        nameSettings,
        isRealData,
        link: 'filter',
        linkId: id,
        selectedValues,
        filterInfluences,
        modelId: modelIdValue,
      } as EnableFilterActionPayload<EnabledFilterInterface>),
    [type, isGlobal, isRealData, nameSettings, id, selectedValues, modelIdValue, filterInfluences],
  );

  /* Update Enabled Filter if changing any data except case when selectedValues = []  */
  useEffect(() => {
    updateEnableFilterDataFn(enableFilterData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableFilterData]);

  return { enabledFilter: enabledFilter as EnabledFilterInterface, enableFilterData };
};

type ChangedFilterDataParams = Pick<FilterDataType, 'nameSettings' | 'isRealData'>;

export const useChangedFilterData = ({ isRealData, nameSettings }: ChangedFilterDataParams) => {
  const isRealDataIsChanged = usePrimitiveHaveBeenChanged(isRealData);
  const fieldNameIsChanged = usePrimitiveHaveBeenChanged(nameSettings.fieldName);

  return { isRealDataIsChanged, fieldNameIsChanged };
};
