import React from 'react';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import { FlexContainer } from 'styles/FlexContainer';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { PrimaryTextParagraph } from 'styles/TextsElements';
import { DeleteIcon, EditIcon } from 'assets/icons/withContainer';
import { ButtonsWrapper, PopoverRuleContainer } from './styles';
import { NoopType } from 'types/global';

export interface RuleItemProps {
  title: string;
  onEdit: NoopType;
  onDelete: NoopType;
  onClick: NoopType;
  isActive?: boolean;
  disabledActive?: boolean;
  activeBorder?: boolean;
}

export const RuleItem = ({ title, isActive, onEdit, disabledActive = false, activeBorder, onClick, onDelete }: RuleItemProps) => (
  <PopoverRuleContainer onClick={onClick}>
    <FlexContainer
      padding="8px"
      flexDirection="row"
      gap="8px"
      alignItems="center"
      borderRadius="4px"
      minWidth="40px"
      height="100%"
      border={activeBorder ? `1px solid var(${ColorVarsEnum.Accent})` : `1px solid var(${ColorVarsEnum.Level_4})`}
    >
      {!disabledActive && (
        <FlexContainer
          width="8px"
          height="8px"
          borderRadius="50%"
          backgroundColor={isActive ? `var(${ColorVarsEnum.Accent})` : `var(${ColorVarsEnum.Level_5})`}
        />
      )}
      <PrimaryTextParagraph fontSize="12px" color={`var(${ColorVarsEnum.Level_1})`}>
        {title || 'Правила'}
      </PrimaryTextParagraph>
    </FlexContainer>

    <ButtonsWrapper>
      <IconWrapper
        onClick={(e) => {
          onEdit();
          e.stopPropagation();
        }}
        iconWidth="17px"
        iconHeight="17px"
        containerHeight="30px"
        containerWidth="30px"
        Icon={EditIcon}
      />

      <IconWrapper
        onClick={(e) => {
          onDelete();
          e.stopPropagation();
        }}
        iconWidth="17px"
        iconHeight="17px"
        containerHeight="30px"
        containerWidth="30px"
        Icon={DeleteIcon}
      />
    </ButtonsWrapper>
  </PopoverRuleContainer>
);
