import React from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';

export const HintChildrenMainFilter = () => (
  <FlexContainer flexDirection="column">
    <PrimaryTextSpan color={`var(${ColorVarsEnum.Level_2})`} lineHeight="17px" fontSize="14px">
      Для вставки переменной в SQL-выражение фильтрации используйте двойные фигурные скобки, например:
      <br />
      <br />
      WHERE goods.category IN &#123;&#123; переменная1 &#125;&#125; OR &#123;&#123; переменная2 &#125;&#125; = ‘фрукты’
    </PrimaryTextSpan>
  </FlexContainer>
);
