import { store } from 'store';
import { moveArrayItem, MoveToType } from 'utils/utils';
import { updateRuleMoveToGroupsAction } from 'store/reducers/models/actions';
import { ModelRuleUserAndGroupInterface } from 'store/reducers/models/types';

const dispatch = store.dispatch;

export const onMoveItemGroup = (groups: ModelRuleUserAndGroupInterface[], groupsId: string, moveTo: MoveToType) => {
  const indexOfGroups = groups.findIndex(({ id }) => id === groupsId),
    { newArray } = moveArrayItem(groups, indexOfGroups, moveTo);

  dispatch(updateRuleMoveToGroupsAction(newArray));
};
