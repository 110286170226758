import isArray from 'lodash/isArray';
import { ColorByConditionUtilsInterface } from 'modules/visualisations/Table/visualisation/types';
import {
  ColorAndImageByInterfaceEnum,
  TableIncisionInterface,
  TableIndicatorInterface,
  VisualisationValuesInterface,
} from 'store/reducers/visualisations/types';
import { v4 } from 'uuid';

export const ColorByConditionUtils = ({
  visualisationValues,
  colorBy,
  index,
  indexAccessor,
  alias,
}: ColorByConditionUtilsInterface) => {
  const value = visualisationValues[alias] as Array<Array<string | null> | null>;
  const rowColorByCondition = colorBy.type === ColorAndImageByInterfaceEnum.Condition ? value[index] : null;
  const colorsIndex = isArray(rowColorByCondition) && rowColorByCondition?.[indexAccessor];
  const colorByCondition = colorsIndex ? colorBy.byCondition.colors[+colorsIndex - 1]?.value : null;

  return colorByCondition;
};

export const GetSqlRequestForHeaderPivotTable = ({
  incisionsInHeader,
  valuesWithHeader,
  indicators,
}: {
  incisionsInHeader: TableIncisionInterface[];
  valuesWithHeader: VisualisationValuesInterface;
  indicators: TableIndicatorInterface[];
}) => {
  let sqlRequest = '';
  const hashNames: string[] = [];

  const keys = Object.keys(valuesWithHeader);
  const maxLength = valuesWithHeader[keys[0]]?.length;

  if (!maxLength) {
    return { sqlRequest, hashNames };
  }

  for (let i = 0; i < maxLength; i++) {
    indicators.forEach((indicator) => {
      const indicatorFieldName = indicator.fieldName;
      const hashName = `fastboard_${v4().replace(/-/g, '')}_hash`;

      hashNames.push(hashName);
      sqlRequest += `countIf(${indicatorFieldName}, `;

      for (let j = 0; j < keys.length; j++) {
        const incisionInHeader = incisionsInHeader.find(
          (incision) => incision.fieldName === keys[j] || incision.name === keys[j],
        );
        if (incisionInHeader?.fieldName == null) {
          continue;
        }
        if (valuesWithHeader[keys[j]]?.[i] !== undefined) {
          sqlRequest += `${incisionInHeader.fieldName} = '${valuesWithHeader[keys[j]]?.[i]}'`;
        }
        if (j < keys.length - 1) {
          sqlRequest += ' and ';
        }
      }

      if (i <= maxLength - 1) {
        sqlRequest += `) as "${hashName}", `;
      }
    });
  }

  return { sqlRequest, hashNames };
};
