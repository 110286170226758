import ReactDatePicker, { ReactDatePickerProps, registerLocale } from 'react-datepicker';
import { DateOriginType, DatePickerValue, OnChangeType } from 'modules/ui/inputs/DatePicker/types';
import React, { forwardRef, memo, MouseEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ru from 'date-fns/locale/ru';
import { DatePickerWrapper } from './styles';
import { addDays, endOfQuarter, getMonth, getYear, isSameDay, startOfQuarter } from 'date-fns';
import { findMonthByYear, generateYearOptions, getMonthOptions, lastDayOfWeek, startOfWeek } from 'utils/dates';
import { byTypeOptions, months } from 'modules/ui/inputs/DatePicker/constants';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import { LeftArrow, RightArrow } from 'assets/icons/withContainer';
import { FlexContainer } from 'styles/FlexContainer';
import { NoopType, NoopValueType } from 'types/global';
import { ByType } from 'types/store';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { parseToRgb, rgba } from 'polished';
import { useSelector } from 'react-redux';
import { getActiveThemeColors } from 'store/reducers/themes/getters';
import { DateIcon } from 'assets/icons/editor';
import { Close8px } from 'assets/icons/forDelete';
import { byTypeFormat } from 'constants/dates';
import CustomSelect from 'components/shared/CustomSelect';
import { PeriodEnum } from 'modules/ui/inputs/DatePicker/types';
import { getActiveBoardElement, getActiveBoardElementInViewMode } from 'store/reducers/board/getters';

let DISABLED_CLICK_OUTSIDE = false;

registerLocale('ru', ru);

interface CustomInputProps {
  value?: string;
  onClick?: MouseEventHandler<HTMLElement>;
  onOpen?: MouseEventHandler<HTMLElement>;
  onClear?: MouseEventHandler<HTMLElement>;
  byType: ByType;
  datePickerName: string;
}

// eslint-disable-next-line react/display-name
const CustomInput = forwardRef<HTMLDivElement, CustomInputProps>(({ value, onOpen, onClear, datePickerName }, ref) => {
  const selectedValue = value !== '';

  const displayValue = useMemo(() => {
    if (!value) return datePickerName;
    const dates = value.split(' - ');
    if (dates.length === 1 || dates[0] === dates[1]) {
      return dates[0];
    }
    return value;
  }, [value, datePickerName]);

  return (
    <FlexContainer alignItems="center" gap="8px" ref={ref}>
      <IconWrapper iconWidth="23px" iconHeight="23px" Icon={DateIcon} onClick={onOpen} />
      <PrimaryTextSpan cursor="pointer" onClick={onOpen} color={`var(${ColorVarsEnum.Accent})`} fontSize="18px" lineHeight="18px">
        {selectedValue ? displayValue : datePickerName}
      </PrimaryTextSpan>
      {selectedValue && (
        <IconWrapper
          iconWidth="10px"
          iconHeight="10px"
          containerWidth="20px"
          containerHeight="24px"
          Icon={Close8px}
          onClick={onClear}
        />
      )}
    </FlexContainer>
  );
});

interface CustomHeaderProps {
  date: Date;
  decreaseMonth: NoopType;
  increaseMonth: NoopType;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
  prevYearButtonDisabled: boolean;
  nextYearButtonDisabled: boolean;
  decreaseYear: NoopValueType<any>;
  increaseYear: NoopType;
  changeYear: NoopValueType<number>;
  changeMonth: NoopValueType<number>;
  onByTypeChange: NoopValueType<ByType>;
  byType: ByType;
  isRealData: boolean;
  oldestAndNewestDates: string[];
  monthDate: Date;
}

const CustomHeader = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  nextYearButtonDisabled,
  decreaseYear,
  prevYearButtonDisabled,
  increaseYear,
  changeYear,
  changeMonth,
  onByTypeChange,
  byType,
  isRealData,
  oldestAndNewestDates,
}: CustomHeaderProps) => {
  const oldestAndNewestMin = oldestAndNewestDates[0];
  const oldestAndNewestMax = oldestAndNewestDates[oldestAndNewestDates.length - 1];
  const onNextMonth = nextMonthButtonDisabled ? undefined : increaseMonth;
  const onPrevMonth = prevMonthButtonDisabled ? undefined : decreaseMonth;
  const onNextYear = nextYearButtonDisabled ? undefined : increaseYear;
  const onPrevYear = prevYearButtonDisabled ? undefined : decreaseYear;

  const byMonth = byType === 'byDay' || byType === 'byWeek';

  const onChangeMonth = useCallback(
    (value: string) => {
      changeMonth(Number(value));
    },
    [changeMonth],
  );

  const onChangeYear = useCallback(
    (value: string) => {
      const monthFound = findMonthByYear(oldestAndNewestDates, Number(value));

      if (monthFound) {
        changeMonth(monthFound);
      }
      changeYear(Number(value));
    },
    [changeMonth, changeYear, oldestAndNewestDates],
  );

  const onChangeType = (value: string) => {
    onByTypeChange(value as ByType);
  };

  const yearsOptions = generateYearOptions(isRealData, [oldestAndNewestMin, oldestAndNewestMax]);

  const monthsOptions = getMonthOptions(isRealData, date, oldestAndNewestDates);

  const valueTypeTitle = byTypeOptions(isRealData, oldestAndNewestMax).filter((el) => el.value === byType)[0]?.title;

  /* TODO:After closing the task (9140) you can delete it */
  // useEffect(() => {
  //   if (isRealData && oldestAndNewestMax) {
  //     const dateFull = new Date(oldestAndNewestMax);
  //
  //     const year = getYear(dateFull);
  //     const month = getMonth(dateFull);
  //
  //     onChangeMonth(String(month));
  //     onChangeYear(String(year));
  //   }
  // }, [isRealData, oldestAndNewestDates, oldestAndNewestMax, onChangeMonth, onChangeYear]);

  return (
    <FlexContainer flexDirection="column" width="100%" padding="0 12px">
      <FlexContainer justifyContent="center" marginBottom="12px">
        <PrimaryTextSpan color={`var(${ColorVarsEnum.Level_1})`} fontSize="14px" fontWeight="bold">
          Выбрать период
        </PrimaryTextSpan>
      </FlexContainer>
      <FlexContainer justifyContent="center" width="100%" marginBottom="8px">
        <CustomSelect value={valueTypeTitle} options={byTypeOptions(isRealData, oldestAndNewestMax)} onChange={onChangeType} />
      </FlexContainer>
      {byType !== 'byToday' && byType !== 'byYear' && (
        <FlexContainer gap="5px" justifyContent="space-between" width="100%" marginBottom="8px">
          <IconWrapper containerWidth="24px" Icon={LeftArrow} onClick={byMonth ? onPrevMonth : onPrevYear} />
          <FlexContainer gap="5px" width="100%">
            {byMonth && <CustomSelect value={months[getMonth(date)]} options={monthsOptions} onChange={onChangeMonth} />}

            <CustomSelect value={String(getYear(date))} options={yearsOptions} onChange={onChangeYear} width={100} />
          </FlexContainer>
          <IconWrapper containerWidth="24px" Icon={RightArrow} onClick={byMonth ? onNextMonth : onNextYear} />
        </FlexContainer>
      )}
    </FlexContainer>
  );
};

interface DatePickerProps<Type extends ByType, DateType extends DateOriginType = Date, WithRange extends boolean = boolean> {
  disabled?: boolean;
  value?: DatePickerValue<WithRange, DateType>;
  onChange?: OnChangeType<WithRange, DateType>;
  byType?: Type;
  isInline?: boolean;
  name: string;
  id: string;
  oldestAndNewestDates: string[];
  isRealData: boolean;
}

export const DatePickerComponent = <
  Type extends ByType = 'byDay',
  DateType extends DateOriginType = Date,
  WithRange extends boolean = boolean,
>({
  value,
  onChange,
  disabled,
  isInline = false,
  byType = 'byDay' as Type,
  name,
  id,
  oldestAndNewestDates,
  isRealData,
}: DatePickerProps<Type, DateType, WithRange>) => {
  const datePickerRef = useRef<any>(null);
  const [isOpen, setIsOpen] = useState(false);
  const colors = useSelector(getActiveThemeColors);
  const activeElementId = useSelector(getActiveBoardElement);
  const activeElementIdInViewMode = useSelector(getActiveBoardElementInViewMode);

  const onClose = useCallback(() => setIsOpen(false), [setIsOpen]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [byLocalType, setByLocalType] = useState<ByType>(byType);
  const localSelectsRange = useMemo<WithRange>(() => (byLocalType !== PeriodEnum.TODAY) as WithRange, [byLocalType]);

  const dates = oldestAndNewestDates?.map((date) => new Date(date));

  const minDateQuarter = dates.length > 0 ? startOfQuarter(dates[0]) : new Date();
  const maxDateQuarter = dates.length > 0 ? endOfQuarter(dates[1]) : new Date();
  const minDate = dates?.length > 0 ? dates[0] : new Date();
  const maxDate = useMemo(() => (dates?.length > 0 ? dates[1] : new Date()), [dates]);

  const excludeDates = useMemo(() => {
    const dates: Date[] = [];
    const now = new Date();

    for (let i = 1; i < 31; i++) {
      dates.push(addDays(now, i));
      dates.push(addDays(now, -i));
    }

    return byLocalType === PeriodEnum.TODAY ? dates : undefined;
  }, [byLocalType]);

  const localValue = useMemo(() => {
    let value: DatePickerValue<WithRange, DateType>;

    if (localSelectsRange) {
      let defaultEndDate = startDate;

      if (byLocalType === PeriodEnum.WEEK && startDate) {
        defaultEndDate = lastDayOfWeek(startDate);
      }

      value = [startDate, endDate || defaultEndDate] as DatePickerValue<WithRange, DateType>;
    } else {
      value = startDate as DatePickerValue<WithRange, DateType>;
    }

    return value;
  }, [localSelectsRange, byLocalType, startDate, endDate]);

  const setValue = useCallback((value?: DatePickerValue<WithRange, DateType>) => {
    if (Array.isArray(value)) {
      const [start, end] = value;
      setStartDate(start);
      setEndDate(end);
    } else {
      value && setStartDate(value);
    }
  }, []);

  const onLocalChange = useCallback<ReactDatePickerProps<never, WithRange>['onChange']>(
    (dates) => {
      let value = dates as DatePickerValue<WithRange, DateType>;

      if (byLocalType === 'byWeek' && Array.isArray(dates)) {
        const [start, end] = dates;
        const startDate = start ? startOfWeek(start) : null,
          endDate = end ? lastDayOfWeek(end) : null;

        value = [startDate, endDate] as DatePickerValue<WithRange, DateType>;
      }

      setValue(value);

      if (isInline && onChange) {
        onChange({ dates: value, byType: byLocalType }, 'change');
      }
    },
    [setValue, byLocalType, isInline, onChange],
  );

  const onLocalByTypeChange = useCallback<NoopValueType<ByType>>(
    (byValue) => {
      let dates: Date | null | [Date | null, Date | null] = [null, null];
      if (byValue === 'byToday') {
        dates = new Date();

        if (!isRealData || (isRealData && isSameDay(maxDate, new Date()))) {
          datePickerRef?.current?.calendar.instanceRef.changeMonth(getMonth(dates));
        }
      }

      setByLocalType(byValue);
      onLocalChange(dates as WithRange extends false | undefined ? Date | null : [Date | null, Date | null], undefined);
    },
    [isRealData, maxDate, onLocalChange],
  );

  const onClear = useCallback(() => {
    const newValue = [null, null] as DatePickerValue<WithRange, DateType>;
    setValue(newValue);
    onChange && onChange({ dates: newValue, byType: byLocalType }, 'clear');
  }, [setValue, onChange, byLocalType]);

  const onCancel = useCallback(() => {
    setValue(value);
    setByLocalType(byType);
    onClose();
  }, [byType, onClose, setValue, value]);

  const onOk = useCallback(() => {
    onChange && onChange({ dates: localValue, byType: byLocalType }, 'change');
    onClose();
  }, [onChange, localValue, byLocalType, onClose]);

  useEffect(() => {
    setValue(value);
  }, [value, setValue]);

  useEffect(() => {
    if ((byType === PeriodEnum.TODAY && !isRealData) || (isRealData && isSameDay(maxDate, new Date()))) {
      datePickerRef?.current?.calendar?.instanceRef?.changeMonth(getMonth(new Date()));
    }
  }, [byType, isRealData, maxDate, setByLocalType]);

  useEffect(() => {
    const footer = document.createElement('div');
    footer.classList.add('react-datepicker__footer');

    const clearButton = document.createElement('button'),
      controlButtonWrapper = document.createElement('div'),
      okButton = document.createElement('button'),
      cancelButton = document.createElement('button');

    if (isOpen) {
      const reactDatePicker =
        document.querySelector('.react-datepicker')?.querySelector('.react-datepicker__month-container') ||
        document.querySelector('.react-datepicker')?.querySelector('.react-datepicker__year--container');

      if (reactDatePicker) {
        const oldFooter = reactDatePicker.querySelector('.react-datepicker__footer');
        oldFooter?.remove();

        clearButton.classList.add('react-datepicker__clear-button');
        clearButton.addEventListener('click', onClear);

        clearButton.innerHTML =
          '<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
          '  <path d="M2.6665 6.6665H4.74058H21.3332" stroke="#7B7986" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>\n' +
          '  <path d="M7.85183 6.6665V4.6665C7.85183 4.13607 8.07035 3.62736 8.45932 3.25229C8.84828 2.87722 9.37583 2.6665 9.92591 2.6665H14.0741C14.6241 2.6665 15.1517 2.87722 15.5406 3.25229C15.9296 3.62736 16.1481 4.13607 16.1481 4.6665V6.6665M19.2592 6.6665V20.6665C19.2592 21.1969 19.0407 21.7056 18.6518 22.0807C18.2628 22.4558 17.7352 22.6665 17.1852 22.6665H6.8148C6.26472 22.6665 5.73717 22.4558 5.3482 22.0807C4.95924 21.7056 4.74072 21.1969 4.74072 20.6665V6.6665H19.2592Z" stroke="#7B7986" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>\n' +
          '  <path d="M9.92578 11.6665V17.6665" stroke="#7B7986" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>\n' +
          '  <path d="M14.0742 11.6665V17.6665" stroke="#7B7986" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>\n' +
          '</svg>\n';

        controlButtonWrapper.classList.add('react-datepicker__control-buttons');

        okButton.innerText = 'Готово';
        okButton.classList.add('react-datepicker__ok-button');
        okButton.addEventListener('click', onOk);

        cancelButton.innerText = 'Отмена';
        cancelButton.classList.add('react-datepicker__cancel-button');
        cancelButton.addEventListener('click', onCancel);

        controlButtonWrapper.append(cancelButton, okButton);
        footer.append(clearButton, controlButtonWrapper);

        reactDatePicker.append(footer);
      }
    }

    return () => {
      clearButton.removeEventListener('click', onClear);
      okButton.removeEventListener('click', onOk);
      cancelButton.removeEventListener('click', onCancel);
    };
  }, [isOpen, onCancel, onClear, onOk, isInline]);

  useEffect(() => {
    if (isOpen && !DISABLED_CLICK_OUTSIDE) {
      datePickerRef.current.calendar.disableOnClickOutside();
      DISABLED_CLICK_OUTSIDE = true;
    }
  }, [isOpen]);

  useEffect(() => {
    if (activeElementId !== id || activeElementIdInViewMode !== id) {
      onCancel();
    }
  }, [activeElementId, id, onCancel, activeElementIdInViewMode]);

  const maxDatePicker = isRealData ? (byLocalType === PeriodEnum.QUARTER ? maxDateQuarter : maxDate) : undefined;
  const minDatePicker = isRealData ? (byLocalType === PeriodEnum.QUARTER ? minDateQuarter : minDate) : undefined;

  return (
    <DatePickerWrapper
      isInline={isInline}
      byLocalType={byLocalType}
      outsideMonthBackgroundColor={rgba({
        ...parseToRgb(colors[ColorVarsEnum.Level_2_btn]),
        alpha: 0.3,
      })}
    >
      <ReactDatePicker
        ref={datePickerRef}
        open={isOpen}
        renderCustomHeader={(props) => (
          <CustomHeader
            {...props}
            onByTypeChange={onLocalByTypeChange}
            byType={byLocalType}
            isRealData={isRealData}
            oldestAndNewestDates={oldestAndNewestDates}
          />
        )}
        maxDate={maxDatePicker}
        minDate={minDatePicker}
        customInput={
          <CustomInput datePickerName={name} byType={byLocalType} onClear={onClear} onOpen={() => setIsOpen((value) => !value)} />
        }
        selectsRange={localSelectsRange}
        inline={isInline}
        selected={startDate || maxDate}
        onChange={onLocalChange}
        locale="ru"
        startDate={localSelectsRange ? startDate : undefined}
        endDate={localSelectsRange ? endDate : undefined}
        disabled={disabled}
        showQuarterYearPicker={byLocalType === PeriodEnum.QUARTER}
        showMonthYearPicker={byLocalType === PeriodEnum.MONTH}
        showWeekNumbers={byLocalType === PeriodEnum.WEEK}
        showYearPicker={byLocalType === PeriodEnum.YEAR}
        excludeDates={excludeDates}
        disabledKeyboardNavigation
        showPopperArrow={false}
        popperPlacement="bottom"
        dateFormat={byTypeFormat[byLocalType]}
        //@ts-ignore
        renderQuarterContent={(quarter) => `${quarter} квартал`}
      />
    </DatePickerWrapper>
  );
};

export const DatePicker = memo(DatePickerComponent);
