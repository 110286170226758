import React, { useCallback, useMemo } from 'react';
import { AllVariableValuesInterface, ModelRuleUserAndGroupInterface } from 'store/reducers/models/types';
import { VariablesDefault } from 'components/contents/Models/EditableMenu/PopoverRule/Modal/RuleModal/Variables/VariablesDefault';
import { useSelector } from 'react-redux';
import { getModelRuleGroups, getModelRuleUsers } from 'store/reducers/models/getters';
import Snackbar from 'services/Snackbar';
import { hasName } from 'utils/utils';
import {
  EditItemNameCallback,
  EditItemValueCallback,
  OnAddItemCallbackType,
} from 'components/contents/Models/EditableMenu/PopoverRule/Modal/RuleModal/Variables/VariablesDefault/types';
import {
  onAddAllVariableName,
  onDeleteAllVariable,
  onUpdateAllVariableName,
  onUpdateAllVariableValue,
  onUpdateGroupVariableValue,
  onUpdateUserVariableValue,
} from 'components/contents/Models/EditableMenu/PopoverRule/Modal/RuleModal/Variables/constants';

interface AllUniqueVariablesProps {
  activeModelRuleUser: ModelRuleUserAndGroupInterface | null;
  activeModelRuleGroup: ModelRuleUserAndGroupInterface | null;
  allVariablesList: AllVariableValuesInterface[];
}

export const AllUniqueVariables = ({ activeModelRuleUser, activeModelRuleGroup, allVariablesList }: AllUniqueVariablesProps) => {
  const usersRule = useSelector(getModelRuleUsers);
  const groupsRule = useSelector(getModelRuleGroups);

  const userId = activeModelRuleUser?.id || '';
  const groupId = activeModelRuleGroup?.id || '';

  const variablesUserList = useMemo(() => usersRule.find(({ id }) => id === userId)?.variableValues || [], [userId, usersRule]);

  const variablesGroupsList = useMemo(
    () => groupsRule.find(({ id }) => id === groupId)?.variableValues || [],
    [groupsRule, groupId],
  );
  /*Нет разницы использовать groupsRule или usersRule - так как нам тут просто нужен все переменные, и в обоих листах они одинаковые */

  const variablesList = useMemo(
    () => (activeModelRuleUser ? variablesUserList : activeModelRuleGroup ? variablesGroupsList : allVariablesList),
    [activeModelRuleGroup, activeModelRuleUser, allVariablesList, variablesGroupsList, variablesUserList],
  );

  const onEditItemName = useCallback(
    async ({ index, name }: EditItemNameCallback) => {
      try {
        if (!name) {
          return Snackbar.show('Поле «Название» обязательно для заполнения', 'error');
        }

        if (hasName(variablesUserList, name)) {
          return Snackbar.show('Переменная с таким именем уже существует', 'error');
        }

        await onUpdateAllVariableName({
          newNameVariable: name,
          variableIndex: index,
          usersRule,
          groupsRule,
          allVariablesList,
        });
      } catch (e) {
        return;
      }
    },
    [usersRule, groupsRule, allVariablesList, variablesUserList],
  );

  const onAddItem = useCallback(
    async ({ title, value, onClose }: OnAddItemCallbackType) => {
      if (!title) {
        return Snackbar.show('Поле «Название» обязательно для заполнения', 'error');
      }

      if (hasName(variablesUserList, title)) {
        return Snackbar.show('Переменная с таким именем уже существует', 'error');
      }

      try {
        await onAddAllVariableName({ usersRule, newNameVariable: title, groupsRule, allVariablesList, defaultValue: value });
        onClose();
      } catch (error) {
        return;
      }
    },
    [variablesUserList, usersRule, groupsRule, allVariablesList],
  );

  const onEditItemValue = useCallback(
    ({ value, name, index }: EditItemValueCallback) => {
      if (activeModelRuleUser) {
        onUpdateUserVariableValue({ newValueVariable: value, variableIndex: index, userId, usersRule });
      }

      if (activeModelRuleGroup) {
        onUpdateGroupVariableValue({ newValueVariable: value, variableIndex: index, groupId, groupsRule });
      }

      if (!activeModelRuleGroup && !activeModelRuleUser) {
        onUpdateAllVariableValue({ nameVariable: name || '', allVariablesList, newValueVariable: value });
      }
    },
    [activeModelRuleGroup, activeModelRuleUser, allVariablesList, groupId, groupsRule, userId, usersRule],
  );

  return (
    <VariablesDefault
      variablesList={variablesList}
      onEditItemName={onEditItemName}
      onAddItem={onAddItem}
      onDeleteItem={(variableIndex) => onDeleteAllVariable({ variableIndex, usersRule, groupsRule, allVariablesList })}
      onEditItemValue={onEditItemValue}
    />
  );
};
