import React from 'react';
import { DefaultFilterDataSettings } from 'modules/settingsContainer/common/data/DefaultFilterDataSettings';
import { useGetActiveFilter } from 'modules/filters/hooks/getActiveFilter';
import {
  onFilterElementsChange,
  onSqlDataChange,
} from 'modules/settingsContainer/common/data/DefaultFilterDataSettings/constants';
import { FilterIncisionsSettings } from 'modules/settingsContainer/FIlterIncisionsSettings';
import { useDataSettingsMetric } from 'modules/visualisations/hooks/dataSettingsMetric';
import { getAstFromSql } from 'utils/SQL/genereteAst';
import Snackbar from 'services/Snackbar';
import { ErrorMessageInterface } from 'types/global';

export const DataTab = () => {
  const { data, modelIdValue, tableFields } = useGetActiveFilter({ type: 'date' });
  const filteredTableFields = tableFields.filter(({ type }) => type.indexOf('Date') !== -1);
  const { modelMetaData } = useDataSettingsMetric(data.modelId);

  const { isRealData, nameSettings, fictionalData } = data;

  const onChangeSqlData = ({
    incisionRequest,
    filterAndGroupRequest,
  }: {
    incisionRequest: string;
    filterAndGroupRequest: string;
  }) => {
    try {
      const isValidIncisionAst = getAstFromSql(`SELECT ${incisionRequest}`);

      if (isValidIncisionAst) {
        onSqlDataChange({
          sqlData: {
            incisionRequest,
            filterAndGroupRequest,
          },
        });
      }
    } catch (error) {
      const message = (error as ErrorMessageInterface)?.message || '';
      Snackbar.show(message, 'error');
    }
  };

  return (
    <>
      <DefaultFilterDataSettings modelIdValue={modelIdValue} data={data}>
        {data.isRealData && (
          <FilterIncisionsSettings
            onChange={onFilterElementsChange}
            isRealData={isRealData}
            options={filteredTableFields}
            value={{ fictionalData, nameSettings }}
            onChangeSqlData={onChangeSqlData}
            modelMetaData={modelMetaData}
            data={data}
          />
        )}
      </DefaultFilterDataSettings>
    </>
  );
};
