import { store } from 'store';
import {
  ColorByInterface,
  ColumnWidthSettings,
  DefaultPropertiesInterface,
  DynamicsMarkerSettings,
  IndentationInterface,
  IndicatorEmptyValuesInterface,
  TableDataSettings,
  TotalSettings,
} from 'store/reducers/visualisations/types';
import { moveArrayItem, MoveToEnum, MoveToType } from 'utils/utils';
import { updateDataSettingsAction, updateTableDataSettingsAction } from 'store/reducers/visualisations/actions';
import {
  IncisionsKeysEnum,
  IncisionsKeysTypes,
  OnChangeValue,
  OnChangeValueIncisions,
  onStepIncisionClickWithGroupingInterface,
  SortChangeInterface,
} from 'modules/visualisations/Table/settings/DataTab/types';
import { ColorValuesByThemeType } from 'modules/settingsContainer/ColorPicker/types';
import { onChangeOrderBy } from 'modules/visualisations/common/onChangeFunctions';

const dispatch = store.dispatch;

export const onColorByValueSettingsChange = (colorByValueSettings: ColorByInterface) =>
  dispatch(updateTableDataSettingsAction({ colorByValueSettings }));

export const onBackgroundByValueSettingsChange = (backgroundByValueSettings: ColorByInterface) =>
  dispatch(updateTableDataSettingsAction({ backgroundByValueSettings }));

/* Incision in header change */

export const onChangeTableIncisionLabelProperties: OnChangeValueIncisions<DefaultPropertiesInterface> = (
  dataSettings,
  properties,
  id,
  incisionsKey,
) =>
  dispatch(
    updateTableDataSettingsAction({
      ...dataSettings,
      [incisionsKey]: dataSettings[incisionsKey].map((incision) =>
        id === incision.id ? { ...incision, settings: { ...incision.settings, properties } } : incision,
      ),
    }),
  );

export const onChangeTableIncisionName: OnChangeValueIncisions<string> = (
  dataSettings: TableDataSettings,
  name,
  id,
  incisionsKey,
) =>
  dispatch(
    updateTableDataSettingsAction({
      ...dataSettings,
      [incisionsKey]: dataSettings[incisionsKey].map((incision) => (id === incision.id ? { ...incision, name } : incision)),
    }),
  );

export const onMoveTableIncision = (
  dataSettings: TableDataSettings,
  incisionId: string,
  moveTo: MoveToType,
  incisionsKey: IncisionsKeysTypes,
) => {
  const incisions = dataSettings[incisionsKey];
  const indexOfIncision = incisions.findIndex(({ id }) => id === incisionId);
  const { newArray } = moveArrayItem(incisions, indexOfIncision, moveTo);
  const isLastIncision = indexOfIncision === incisions.length - 1;
  const isFirstIncision = indexOfIncision === 0;

  if (incisionsKey === IncisionsKeysEnum.IncisionsInHeader && isLastIncision && moveTo === MoveToEnum.DOWN) {
    const newArrayIncisionsInHeader = [...dataSettings.incisionsInHeader].slice(0, indexOfIncision);
    const newArrayIncisions = [...dataSettings.incisions];
    newArrayIncisions.splice(0, 0, dataSettings.incisionsInHeader[indexOfIncision]);

    return dispatch(
      updateTableDataSettingsAction({
        ...dataSettings,
        [IncisionsKeysEnum.IncisionsInHeader]: newArrayIncisionsInHeader,
        [IncisionsKeysEnum.Incisions]: newArrayIncisions,
      }),
    );
  }

  if (incisionsKey === IncisionsKeysEnum.Incisions && isFirstIncision && moveTo === MoveToEnum.UP) {
    const newArrayIncisions = [...dataSettings.incisions].slice(1);
    const newArrayIncisionsInHeader = [...dataSettings.incisionsInHeader];
    newArrayIncisionsInHeader.splice(newArrayIncisionsInHeader.length, 0, dataSettings.incisions[indexOfIncision]);

    return dispatch(
      updateTableDataSettingsAction({
        ...dataSettings,
        [IncisionsKeysEnum.IncisionsInHeader]: newArrayIncisionsInHeader,
        [IncisionsKeysEnum.Incisions]: newArrayIncisions,
      }),
    );
  }

  dispatch(
    updateTableDataSettingsAction({
      ...dataSettings,
      [incisionsKey]: newArray,
    }),
  );
};

export const onChangeTableIncisionCustomRequest: OnChangeValueIncisions<string | null> = (
  dataSettings,
  customRequest,
  id,
  incisionsKey,
) =>
  dispatch(
    updateTableDataSettingsAction({
      ...dataSettings,
      [incisionsKey]: dataSettings[incisionsKey].map((incision) =>
        id === incision.id ? { ...incision, settings: { ...incision.settings, customRequest } } : incision,
      ),
    }),
  );

export const onChangeTableIncisionFieldName: OnChangeValueIncisions<string> = (dataSettings, fieldName, id, incisionsKey) =>
  dispatch(
    updateTableDataSettingsAction({
      ...dataSettings,
      [incisionsKey]: dataSettings[incisionsKey].map((incision) =>
        id === incision.id
          ? {
              ...incision,
              fieldName,
            }
          : incision,
      ),
    }),
  );

export const onChangeTableFictionalData: OnChangeValueIncisions<string[]> = (dataSettings, fictionalData, id, incisionsKey) =>
  dispatch(
    updateDataSettingsAction({
      ...dataSettings,
      [incisionsKey]: dataSettings[incisionsKey].map((incision) =>
        id === incision.id
          ? {
              ...incision,
              fictionalData,
            }
          : incision,
      ),
    }),
  );

/* Incision change */

export const onTableColumnWidthIncisionsChange: OnChangeValueIncisions<ColumnWidthSettings> = (
  dataSettings,
  columnWidthSettings,
  id,
  incisionsKey,
) =>
  dispatch(
    updateTableDataSettingsAction({
      ...dataSettings,
      [incisionsKey]: dataSettings[incisionsKey].map((incision) =>
        id === incision.id ? { ...incision, settings: { ...incision.settings, columnWidthSettings } } : incision,
      ),
    }),
  );

export const onTableIndentationIncisionsChange: OnChangeValueIncisions<IndentationInterface> = (
  dataSettings,
  indentation,
  id,
  incisionsKey,
) =>
  dispatch(
    updateTableDataSettingsAction({
      ...dataSettings,
      [incisionsKey]: dataSettings[incisionsKey].map((incision) =>
        id === incision.id ? { ...incision, settings: { ...incision.settings, indentation } } : incision,
      ),
    }),
  );

export const onTableHasIndentationIncisionsChange: OnChangeValueIncisions<boolean> = (
  dataSettings,
  hasIndentation,
  id,
  incisionsKey,
) =>
  dispatch(
    updateTableDataSettingsAction({
      ...dataSettings,
      [incisionsKey]: dataSettings[incisionsKey].map((incision) =>
        id === incision.id ? { ...incision, settings: { ...incision.settings, hasIndentation } } : incision,
      ),
    }),
  );

export const onChangeTableIncisionsLabelProperties: OnChangeValueIncisions<DefaultPropertiesInterface> = (
  dataSettings,
  properties,
  id,
  incisionsKey,
) =>
  dispatch(
    updateTableDataSettingsAction({
      ...dataSettings,
      [incisionsKey]: dataSettings[incisionsKey].map((incision) =>
        id === incision.id ? { ...incision, settings: { ...incision.settings, properties } } : incision,
      ),
    }),
  );

export const onChangeTableIncisionNameFromDataBase: OnChangeValueIncisions<boolean> = (
  dataSettings,
  nameFromDatabase,
  id,
  incisionsKey,
) =>
  dispatch(
    updateDataSettingsAction({
      ...dataSettings,
      [incisionsKey]: dataSettings[incisionsKey].map((incision) =>
        id === incision.id ? { ...incision, settings: { ...incision.settings, nameFromDatabase } } : incision,
      ),
    }),
  );

export const onColorIncisionChange: OnChangeValue<ColorValuesByThemeType | null> = (dataSettings, color, id) =>
  dispatch(
    updateTableDataSettingsAction({
      ...dataSettings,
      incisions: dataSettings.incisions.map((incision) => (id === incision.id ? { ...incision, color } : incision)),
    }),
  );

/* Indicator change */

export const onColumnWidthIndicatorChange: OnChangeValue<ColumnWidthSettings> = (dataSettings, columnWidthSettings, id) =>
  dispatch(
    updateTableDataSettingsAction({
      ...dataSettings,
      indicators: dataSettings.indicators.map((indicator) =>
        id === indicator.id ? { ...indicator, settings: { ...indicator.settings, columnWidthSettings } } : indicator,
      ),
    }),
  );
export const onTableHasIndentationIndicatorChange: OnChangeValue<boolean> = (dataSettings, hasIndentation, id) =>
  dispatch(
    updateTableDataSettingsAction({
      ...dataSettings,
      indicators: dataSettings.indicators.map((indicator) =>
        id === indicator.id ? { ...indicator, settings: { ...indicator.settings, hasIndentation } } : indicator,
      ),
    }),
  );

export const onTableIndentationIndicatorChange: OnChangeValue<IndentationInterface> = (dataSettings, indentation, id) =>
  dispatch(
    updateTableDataSettingsAction({
      ...dataSettings,
      indicators: dataSettings.indicators.map((indicator) =>
        id === indicator.id ? { ...indicator, settings: { ...indicator.settings, indentation } } : indicator,
      ),
    }),
  );

export const onTotalIndicatorChange: OnChangeValue<TotalSettings> = (dataSettings, totalSettings, id) =>
  dispatch(
    updateTableDataSettingsAction({
      ...dataSettings,
      indicators: dataSettings.indicators.map((indicator) =>
        id === indicator.id ? { ...indicator, settings: { ...indicator.settings, totalSettings } } : indicator,
      ),
    }),
  );

export const onDynamicsMarkerChange: OnChangeValue<DynamicsMarkerSettings> = (dataSettings, dynamicsMarkerSettings, id) =>
  dispatch(
    updateTableDataSettingsAction({
      ...dataSettings,
      indicators: dataSettings.indicators.map((indicator) =>
        id === indicator.id ? { ...indicator, settings: { ...indicator.settings, dynamicsMarkerSettings } } : indicator,
      ),
    }),
  );

export const onSortChange = ({ dataSettings, columnName, type, sortItsColumn, isActive }: SortChangeInterface) => {
  const order = dataSettings.orderBy.filter((el) => el.columnName !== columnName);

  if (!isActive && isActive !== undefined) {
    return onChangeOrderBy(dataSettings, order);
  }

  if (sortItsColumn || isActive) {
    return onChangeOrderBy(dataSettings, [
      ...order,
      {
        columnName,
        type,
      },
    ]);
  }

  return onChangeOrderBy(dataSettings, order);
};

export const onColorIndicatorChange: OnChangeValue<ColorValuesByThemeType | null> = (dataSettings, color, id) => {
  dispatch(
    updateTableDataSettingsAction({
      ...dataSettings,
      indicators: dataSettings.indicators.map((indicator) => (id === indicator.id ? { ...indicator, color } : indicator)),
    }),
  );
};

export const onMoveIndicator = (dataSettings: TableDataSettings, indicatorId: string, moveTo: MoveToType) => {
  const indicators = dataSettings.indicators,
    indexOfIndicators = indicators.findIndex(({ id }) => id === indicatorId),
    { newArray } = moveArrayItem(indicators, indexOfIndicators, moveTo);

  dispatch(
    updateTableDataSettingsAction({
      ...dataSettings,
      indicators: newArray,
    }),
  );
};

export const onChangeIndicatorMakeHyperLinks: OnChangeValue<boolean> = (dataSettings, makeHyperLinks, id) =>
  dispatch(
    updateTableDataSettingsAction({
      ...dataSettings,
      indicators: dataSettings.indicators.map((indicator) =>
        id === indicator.id ? { ...indicator, settings: { ...indicator.settings, makeHyperLinks } } : indicator,
      ),
    }),
  );

export const onChangeIndicatorLabelProperties: OnChangeValue<DefaultPropertiesInterface> = (dataSettings, properties, id) =>
  dispatch(
    updateTableDataSettingsAction({
      ...dataSettings,
      indicators: dataSettings.indicators.map((indicator) =>
        id === indicator.id ? { ...indicator, settings: { ...indicator.settings, properties } } : indicator,
      ),
    }),
  );

export const onStepIncisionClickWithGrouping = ({
  dataSettings,
  hasIncisionsGrouping,
  id,
  moveTo,
}: onStepIncisionClickWithGroupingInterface) => {
  const dataSettingsCopy: TableDataSettings = JSON.parse(JSON.stringify(dataSettings));

  hasIncisionsGrouping.map((incision) => {
    dataSettingsCopy.incisions.forEach((el) => {
      if (el.id === incision.id) {
        el.settings.isGroup = false;
      }
    });
  });

  onMoveTableIncision(dataSettingsCopy, id, moveTo, IncisionsKeysEnum.Incisions);
};

export const onChangeTableEmptyValue: OnChangeValueIncisions<IndicatorEmptyValuesInterface> = (
  dataSettings,
  emptyValues,
  id,
  incisionsKey,
) =>
  dispatch(
    updateDataSettingsAction({
      ...dataSettings,
      [incisionsKey]: dataSettings[incisionsKey].map((incision) =>
        id === incision.id ? { ...incision, settings: { ...incision.settings, emptyValues } } : incision,
      ),
    }),
  );
