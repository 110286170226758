import { ColorValuesByThemeType, PaletteValuesByThemeType } from 'modules/settingsContainer/ColorPicker/types';
import { DefaultDataSettings } from 'modules/settingsContainer/common/data/DefaultDataSettings';
import { FormattingSettings } from 'modules/settingsContainer/common/data/FormattingSettings';
import { LimitSettings } from 'modules/settingsContainer/common/data/LimitSettings';
import { LineAndBarShowValueSettings } from 'modules/settingsContainer/common/data/LineAndBarShowValueSettings';
import { MetricSettings } from 'modules/settingsContainer/common/data/MetricSettings';
import { FieldSettingsRenderType, MetricsRenderType } from 'modules/settingsContainer/common/data/MetricSettings/types';
import { MinAndMaxSettings } from 'modules/settingsContainer/common/data/MinAndMaxSettings';
import { NameFromDatabaseSettings } from 'modules/settingsContainer/common/data/NameFromDatabaseSettings';
import { SqlSettings } from 'modules/settingsContainer/common/data/SqlSettings';
import { FictionalMetricField } from 'modules/settingsContainer/common/FictionalMetricField';
import { IndicatorMetricField } from 'modules/settingsContainer/common/IndicatorMetricField';
import { ModelSelectorSettings } from 'modules/settingsContainer/common/ModelSelectorSettings';
import { SettingsFieldEntry } from 'modules/settingsContainer/SettingsFieldEntry';
import {
  onAddNewIncision,
  onAddNewIndicator,
  onChangeEmptyValue,
  onChangeFictionalData,
  onChangeIncisionCustomRequest,
  onChangeIncisionFieldName,
  onChangeIncisionName,
  onChangeIncisionNameFromDataBase,
  onChangeIndicatorCustomRequest,
  onChangeIndicatorName,
  onDeleteIncision,
  onDeleteIndicator,
  onLimitChange,
  onModelIdChange,
  onSqlSettingsSave,
} from 'modules/visualisations/common/onChangeFunctions';
import { useDataSettingsMetric } from 'modules/visualisations/hooks/dataSettingsMetric';
import {
  onChangeIndicatorFormatting,
  onChangeIndicatorNameFromDataBase,
  onChangeShowValue,
  onColorIncisionChange,
  onColorIndicatorChange,
  onMinAndMaxChange,
  onMoveIncision,
} from 'modules/visualisations/Waterfall/settings/DataTab/constants';
import React from 'react';
import {
  defaultSqlDataSettings,
  defaultWaterfallDataSettings,
  defaultWaterfallViewSettings,
  getVisualisationFieldName,
} from 'store/reducers/visualisations/constants';
import { WaterfallIncisionInterface, WaterfallIndicatorInterface } from 'store/reducers/visualisations/types';
import { EmptyValuesElementSettings } from 'modules/settingsContainer/common/data/EmptyValuesElementSettings';
import { useGetActiveVisualisationSettings } from 'utils/hooks/visualisation/getActiveVisualisationSettings';
import { getIncisionAndIndicatorsCompletions } from 'utils/sqlSettings';

export const DataTab = () => {
  const { dataSettings, codeEditorData, astOfVisualisation } = useGetActiveVisualisationSettings({
    defaultData: defaultWaterfallDataSettings,
    defaultView: defaultWaterfallViewSettings,
    defaultSqlData: defaultSqlDataSettings,
  });

  const indicators = dataSettings.indicators;
  const positiveAndNegativeIndicators = dataSettings.positiveAndNegativeIndicators;

  const [plan, fact] = indicators.length > 0 ? indicators : defaultWaterfallDataSettings.indicators,
    [positive, negative] =
      positiveAndNegativeIndicators.length > 0
        ? positiveAndNegativeIndicators
        : defaultWaterfallDataSettings.positiveAndNegativeIndicators;

  const allIndicators = [plan, positive, negative, fact];

  const { isRealData, activeIncisionId } = dataSettings,
    newIndicators = [...indicators, ...positiveAndNegativeIndicators];

  const { tableFields, controls, onMetricClick, onSetupClick, setupIsOpen, metricIsSelected, modelMetaData } =
    useDataSettingsMetric(dataSettings.modelId);

  const incisionRender: MetricsRenderType<WaterfallIncisionInterface> = ({ metrics }) => (
    <>
      {metrics.map(({ fieldName, name, fictionalData, id, colors, settings: { nameFromDatabase, customRequest } }) => {
        const onUpClick = () => onMoveIncision(dataSettings, id, 'up');
        const onDownClick = () => onMoveIncision(dataSettings, id, 'down');
        const onChangeColors = (colors: PaletteValuesByThemeType | null) => {
          onColorIncisionChange(dataSettings, colors, id);
        };

        return (
          <SettingsFieldEntry
            setupIsOpen={setupIsOpen(id)}
            onSetupClick={onSetupClick(id)}
            onClick={onMetricClick(id)}
            isSelected={metricIsSelected(id)}
            isActive={id === activeIncisionId}
            fieldValue={getVisualisationFieldName({ nameFromDatabase, fieldName, name })}
            canChangeField={!nameFromDatabase}
            onFieldChange={(name) => onChangeIncisionName(dataSettings, name, id)}
            onChangeColors={onChangeColors}
            onDownClick={onDownClick}
            onUpClick={onUpClick}
            colorsValue={colors}
            colorPickerType="palette"
            key={id}
          >
            <FictionalMetricField
              onCustomRequestChange={(customRequest) => onChangeIncisionCustomRequest(dataSettings, customRequest, id)}
              customRequest={customRequest || ''}
              disabled={!!customRequest}
              isRealData={isRealData}
              options={tableFields}
              value={{ fictionalData, fieldName }}
              onChange={({ fictionalData, fieldName }) => {
                fieldName && onChangeIncisionFieldName(dataSettings, fieldName || '', id);
                fictionalData && onChangeFictionalData(dataSettings, fictionalData, id);
              }}
              modelMetaData={modelMetaData}
            />
          </SettingsFieldEntry>
        );
      })}
    </>
  );

  const incisionFieldSettingsRender: FieldSettingsRenderType<WaterfallIncisionInterface> = ({ metric: incision }) => {
    const {
      id,
      settings: {
        emptyValues: { isEmptyValue, value },
        nameFromDatabase,
      },
    } = incision;

    return (
      <>
        <EmptyValuesElementSettings
          switcherValue={isEmptyValue}
          value={value}
          onChange={(value) => onChangeEmptyValue(dataSettings, value, id)}
        />
        <NameFromDatabaseSettings
          value={nameFromDatabase}
          onChange={(nameFromDatabase: boolean) => onChangeIncisionNameFromDataBase(dataSettings, nameFromDatabase, id)}
        />
      </>
    );
  };

  const indicatorRender: MetricsRenderType<WaterfallIndicatorInterface> = ({ metrics }) => (
    <>
      {metrics.map(({ id, fieldName, name, operationType, color, type, customRequest, settings: { nameFromDatabase } }) => {
        const onChangeColors = (colors: ColorValuesByThemeType | null) => onColorIndicatorChange(dataSettings, colors, id);

        return (
          <SettingsFieldEntry
            setupIsOpen={setupIsOpen(id)}
            onSetupClick={onSetupClick(id)}
            onClick={onMetricClick(id)}
            isSelected={metricIsSelected(id)}
            fieldValue={getVisualisationFieldName({ name, nameFromDatabase, fieldName })}
            canChangeField={!nameFromDatabase}
            onFieldChange={(name) => onChangeIndicatorName(dataSettings, name, id)}
            onChangeColors={onChangeColors}
            colorsValue={color}
            key={id}
            disableChangePriory
            disabledViewChildren={type === 'negative' || type === 'positive'}
          >
            <IndicatorMetricField
              onCustomRequestChange={(customRequest) => onChangeIndicatorCustomRequest(dataSettings, customRequest, id)}
              customRequest={customRequest}
              options={tableFields}
              dataSettings={dataSettings}
              id={id}
              isRealData={isRealData}
              fieldName={fieldName}
              operationType={operationType}
              modelMetaData={modelMetaData}
            />
          </SettingsFieldEntry>
        );
      })}
    </>
  );

  const indicatorFieldSettingsRender: FieldSettingsRenderType<WaterfallIndicatorInterface> = ({ metric: indicator }) => {
    const {
      id,
      type,
      settings: { nameFromDatabase, showValue, formatting },
    } = indicator;

    return (
      <>
        <LineAndBarShowValueSettings
          type="bar"
          indicators={newIndicators.filter((indicator) => indicator.id === id)}
          value={showValue}
          onChange={(showValue) => onChangeShowValue(dataSettings, showValue, id)}
        />
        {(type === 'plan' || type === 'fact') && (
          <>
            <NameFromDatabaseSettings
              value={nameFromDatabase}
              onChange={(nameFromDatabase: boolean) => onChangeIndicatorNameFromDataBase(dataSettings, nameFromDatabase, id)}
            />
          </>
        )}
        <FormattingSettings value={formatting} onChange={(value) => onChangeIndicatorFormatting(dataSettings, value, id)} />
      </>
    );
  };

  return (
    <>
      <DefaultDataSettings dataSettings={dataSettings} />
      <ModelSelectorSettings value={dataSettings.modelId} onChange={onModelIdChange} />
      <MetricSettings
        titleText="Разрезы"
        addButtonText="Добавить разрез"
        fieldSettingsRender={incisionFieldSettingsRender}
        metricRender={incisionRender}
        metrics={dataSettings.incisions}
        onAdd={() => onAddNewIncision(dataSettings, 'waterfall')}
        onDelete={(id) => id && onDeleteIncision(dataSettings, id)}
        controls={controls}
      />
      <MetricSettings
        titleText="Показатели"
        fieldSettingsRender={indicatorFieldSettingsRender}
        metricRender={indicatorRender}
        metrics={allIndicators}
        onAdd={() => onAddNewIndicator(dataSettings, 'waterfall')}
        onDelete={(id) => id && onDeleteIndicator(dataSettings, id)}
        controls={controls}
        disableAddingMetric
      />
      <SqlSettings
        //@ts-ignore
        astData={astOfVisualisation}
        sqlData={codeEditorData}
        adviceEditorIncision={getIncisionAndIndicatorsCompletions(dataSettings.incisions)}
        adviceEditorIndicator={getIncisionAndIndicatorsCompletions(indicators)}
        onSave={(sqlSettingsChanges) =>
          onSqlSettingsSave(dataSettings, sqlSettingsChanges, 'waterfall', {
            incision: { minValue: 1 },
            indicator: { minValue: 2, maxValue: 2 },
          })
        }
        modelMetaData={modelMetaData}
      />
      <LimitSettings value={dataSettings.limit} onChange={onLimitChange} />
      <MinAndMaxSettings title="Мин/Макс для основных" value={dataSettings.minAndMax} onChange={onMinAndMaxChange} />
    </>
  );
};
