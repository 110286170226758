import styled from 'styled-components';

export const ButtonsWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 55px;
  right: -55px;
  display: none;
  align-items: flex-end;
  justify-content: center;
`;

export const PopoverRuleContainer = styled.div`
  position: relative;
  height: 30px;
  margin-right: auto;
  cursor: pointer;
  :hover {
    ${ButtonsWrapper} {
      display: flex;
    }
  }
`;
