import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getRLS } from 'store/reducers/projectSettings/getters';

export const useRLS = () => {
  const rls = useSelector(getRLS);

  const memoRLS = useMemo(() => {
    if (!rls.isActive || rls.type === 'none' || rls.entityId === null) {
      return undefined;
    }

    return {
      type: rls.type,
      entityId: rls.entityId || '',
    };
  }, [rls]);

  return { rls: memoRLS };
};
