import { Form } from 'components/console/elements/sideBar/Forms/FileForm/styles';
import { FlexContainer } from 'styles/FlexContainer';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Checkbox, Select, TextField } from 'modules/ui';
import React, { useCallback, useEffect, useState } from 'react';
import { NoopType } from 'types/global';
import {
  driverIdValidation,
  driverItems,
  fileLocationItems,
  maskMultiFilePatternItems,
  textTypes,
} from 'components/console/elements/sideBar/Forms/FileForm/constants';
import { useAppDispatch } from 'store';
import {
  addSourceAction,
  changeActiveSourceIdAction,
  createFileConnectionAction,
  updateFileConnectionAction,
  updateSourceByIdAction,
  uploadSourceFileTypeAction,
} from 'store/reducers/sources/actions';
import {
  CallbackSourceInterface,
  RenderFooterType,
  SourceCallbackConnectionFileData,
  SourceDriverType,
  SourceFileEditConnection,
} from 'components/console/elements/sideBar/Forms/FileForm/types';
import { closeModalAction, openModalTypedAction } from 'store/reducers/modals/actions';
import { FileFoldersModal } from 'components/console/elements/SourcesConnection/FileFoldersModal';
import { FileUploadInput } from 'modules/ui/inputs/FileUploadInput';
import { SourceFileConnectionData } from 'store/reducers/sources/types';
import { LoadingOverlay } from 'modules/ui/Loading/LoadingOverlay';
import { getFileExtension } from 'utils/utils';
import Snackbar from 'services/Snackbar';
import { useSelector } from 'react-redux';
import { getSources } from 'store/reducers/sources/getters';
import { fileFolder } from 'components/console/elements/SourcesConnection/FileFoldersModal/constants';
import { useConnection } from 'components/console/elements/hooks/useConnection';

interface FileFormProps {
  onClose?: NoopType;
  renderFooter?: RenderFooterType;
  mode: 'add' | 'edit';
  data?: SourceFileEditConnection | null;
  projectId?: string;
}

export const FileForm = ({ mode, onClose, data, projectId, renderFooter }: FileFormProps) => {
  const dispatch = useAppDispatch();
  const onConnection = useConnection(projectId || '');

  const { sourcesList } = useSelector(getSources);
  const hasName = useCallback((name: string) => sourcesList.find((obj) => obj.name === name), [sourcesList]);

  const [loading, setLoading] = useState(false);
  const editSourceId = data?.id;
  const sourceData = data?.sourceData;

  const isAdd = mode === 'add';

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = useForm<SourceCallbackConnectionFileData>({
    mode: 'onSubmit',
    defaultValues: {
      driver: 'auto',
      fileLocation: 'fromTheServer',
      maskMultiFilePattern: '^',
    },
  });

  const selectedDriver = watch('driver');
  const isManyFiles = watch('isManyFiles');
  const filePathServer = watch('filePathServer');
  const fileLocalName = watch('file')?.name as SourceDriverType;
  const fileLocation = watch('fileLocation');
  const fromServer = fileLocation === 'fromTheServer';

  const createAndEditSource = useCallback(
    async ({
      file,
      name,
      driverId,
      isAutoUpdate,
      nameTable,
      delimiter,
      maskMultiFileValue,
      maskMultiFilePattern,
      isManyFiles,
    }: CallbackSourceInterface) => {
      try {
        const data = {
          name,
          driverId,
          autoUpdate: isAutoUpdate,
          credentials: { file } as SourceFileConnectionData,
        };

        if (textTypes.includes(driverId)) {
          const delimiterParams = driverId !== 'qvd' ? (delimiter ? [delimiter] : undefined) : undefined;

          data.credentials = { ...data.credentials, nameTable, delimiter: delimiterParams };
        }

        if (isManyFiles) {
          data.credentials = {
            ...data.credentials,
            manyfiles: isManyFiles,
            mask: maskMultiFileValue ? { pattern: maskMultiFilePattern, value: maskMultiFileValue } : undefined,
          };
        }

        if (isAdd) {
          const actionFileConnectionResult = await dispatch(
            createFileConnectionAction({
              ...data,
            }),
          ).unwrap();

          if (actionFileConnectionResult) {
            const createSourceId = actionFileConnectionResult.id;
            const createIsValid = actionFileConnectionResult.isValid;

            dispatch(
              addSourceAction({
                id: createSourceId,
                name,
                isValid: createIsValid,
                fileName: file,
                type: 'file',
                driver: driverId,
              }),
            );

            setLoading(false);
            onClose && onClose();
            dispatch(changeActiveSourceIdAction(actionFileConnectionResult.id));
            onConnection({ sourceId: createSourceId, name });
          }
        } else {
          if (editSourceId) {
            const actionEditFileConnectionResult = await dispatch(
              updateFileConnectionAction({
                id: editSourceId,
                sourceData: { ...data },
              }),
            ).unwrap();
            if (actionEditFileConnectionResult) {
              const updatedSourceId = actionEditFileConnectionResult.id;
              const updatedIsValid = actionEditFileConnectionResult.isValid;

              dispatch(
                updateSourceByIdAction({
                  id: updatedSourceId,
                  source: { name, isValid: updatedIsValid, type: 'file', fileName: file, driver: driverId },
                }),
              );
              setLoading(false);
              onClose && onClose();
            }
          }
        }
      } catch (e) {
        setLoading(false);
        return;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editSourceId, isAdd, onClose],
  );

  const onSubmit: SubmitHandler<SourceCallbackConnectionFileData> = useCallback(
    async ({
      name,
      driver,
      fileLocation,
      delimiter,
      nameTable,
      file,
      filePathServer,
      isAutoUpdate,
      isManyFiles,
      maskMultiFileValue,
      maskMultiFilePattern,
    }) => {
      if (!isDirty) {
        return onClose && onClose();
      }

      if (!name) {
        return Snackbar.show('Поле «Название подключения» обязательно для заполнения', 'error');
      }

      if (hasName(name) && sourceData?.name !== name) {
        return Snackbar.show('Источник с таким именем уже существует', 'error');
      }

      if (!file && !filePathServer) {
        return Snackbar.show('Файл не выбран', 'error');
      }

      setLoading(true);

      const fileLocationProcessing = {
        fromTheLocal: async () => {
          if (!file) return false;
          const actionFileResult = await dispatch(uploadSourceFileTypeAction(file)).unwrap();

          return { fileData: actionFileResult, driverType: driver };
        },
        fromTheServer: async () => {
          if (!filePathServer) return false;
          return { fileData: filePathServer, driverType: driver };
        },
      };

      try {
        const process = fileLocationProcessing[fileLocation];
        if (!process) {
          setLoading(false);
          return;
        }

        const result = await process();
        if (!result) return setLoading(false);

        await createAndEditSource({
          file: result.fileData,
          name,
          driverId: result.driverType as SourceDriverType,
          nameTable,
          isAutoUpdate,
          delimiter,
          isManyFiles,
          maskMultiFileValue,
          maskMultiFilePattern,
        });
      } catch (e) {
        setLoading(false);
        return;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasName, isDirty, sourceData?.name, createAndEditSource],
  );

  const onFilePathName = useCallback((fileName: string) => setValue('filePathServer', fileName), [setValue]);
  const onDriver = useCallback((driver: SourceDriverType) => setValue('driver', driver), [setValue]);

  const onCloseEditModal = () => dispatch(closeModalAction(fileFolder));

  const onOpenFileModal = useCallback(
    () =>
      dispatch(
        openModalTypedAction({
          Component: FileFoldersModal,
          componentProps: {
            onClose: onCloseEditModal,
            onClick: onFilePathName,
            isManyFiles,
          },
          name: fileFolder,
        }),
      ),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCloseEditModal],
  );

  useEffect(() => {
    if (filePathServer && !isManyFiles) {
      setValue('driver', getFileExtension(filePathServer) as SourceDriverType);
    }
  }, [filePathServer, isManyFiles, setValue]);

  useEffect(() => {
    if (isManyFiles) {
      setValue('fileLocation', 'fromTheServer');
    }
  }, [fileLocation, isManyFiles, setValue]);

  useEffect(() => {
    if (isManyFiles && filePathServer && sourceData) {
      setValue('driver', sourceData.driverId);
    }
  }, [filePathServer, isManyFiles, setValue, sourceData]);

  useEffect(() => {
    if (sourceData) {
      setValue('driver', sourceData.driverId || 'postgres');
      setValue('name', sourceData.name);

      const autoUpdate = sourceData.autoUpdate;
      autoUpdate && setValue('isAutoUpdate', autoUpdate);

      if (sourceData.credentials) {
        const { nameTable, file, delimiter, mask, manyfiles } = sourceData.credentials;
        setValue('nameTable', nameTable);
        delimiter && setValue('delimiter', delimiter[0]);
        file && setValue('filePathServer', file);

        if (mask) {
          setValue('maskMultiFilePattern', mask.pattern);
          setValue('maskMultiFileValue', mask.value);
        }
        setValue('isManyFiles', manyfiles);
      }
    }
  }, [sourceData, setValue]);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay loading={loading} />

      <FlexContainer width="100%" flexDirection="column" marginBottom="40px">
        <FlexContainer flexDirection="column" gap="8px">
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={({ field }) => <TextField error={!!errors.name} label="Название подключения" width="100%" {...field} />}
          />
          <FlexContainer gap="16px" marginTop="18px" alignItems="flex-start" marginBottom="8px">
            <Controller
              render={({ field }) => (
                <Select
                  title="Расположение файла"
                  options={fileLocationItems}
                  width="196px"
                  placeholder="Тип"
                  {...field}
                  value={field.value}
                />
              )}
              name="fileLocation"
              control={control}
            />
            {!fromServer && (
              <Controller
                name="file"
                control={control}
                render={({ field }) => (
                  <FileUploadInput
                    onFileUpload={(file) => field.onChange(file)}
                    onClear={() => setValue('file', null)}
                    onDriver={(fileType) => onDriver(fileType as SourceDriverType)}
                    allowedFileTypes={driverIdValidation}
                    fileName={fileLocalName}
                    fileType={selectedDriver}
                    {...field}
                  />
                )}
              />
            )}

            {fromServer && (
              <FileUploadInput
                isManyFiles={isManyFiles}
                label={isManyFiles ? 'Папка' : 'Файл'}
                fileName={filePathServer}
                onCustomClick={onOpenFileModal}
              />
            )}
          </FlexContainer>

          <Controller
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                title="Формат файла"
                options={driverItems(isManyFiles)}
                width="196px"
                placeholder="Тип"
                {...field}
                onChange={(e) => {
                  if (e === 'auto' && (fileLocalName || filePathServer)) {
                    const fileType = getFileExtension(fromServer ? filePathServer : fileLocalName);
                    return field.onChange(fileType);
                  }
                  field.onChange(e);
                }}
                value={field.value}
                error={!!errors.driver}
              />
            )}
            name="driver"
            control={control}
          />
          {selectedDriver && textTypes.includes(selectedDriver) && (
            <>
              <Controller
                rules={{ required: true }}
                name="nameTable"
                control={control}
                render={({ field }) => (
                  <TextField
                    error={!!errors.nameTable}
                    description="Дайте название таблице которая будет сформирована из данных файла"
                    label="Название таблицы"
                    width="100%"
                    {...field}
                  />
                )}
              />

              {selectedDriver !== 'qvd' && (
                <Controller
                  name="delimiter"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      description="Используйте «Авто», если разделитель заранее неизвестен, в этом случае разделитель будет определен автоматически (рекомендуется)"
                      label="Авто"
                      width="50%"
                      {...field}
                    />
                  )}
                />
              )}
            </>
          )}
          <FlexContainer marginTop="4px">
            <Controller
              name="isManyFiles"
              control={control}
              render={({ field }) => (
                <Checkbox
                  name="isManyFiles"
                  padding="8px 0 0 0"
                  checked={field.value}
                  label="Все файлы в папке"
                  id="manyFiles_flag"
                  onChange={(e) => {
                    field.onChange(e);
                    setValue('filePathServer', '');
                    setValue('driver', undefined);
                  }}
                />
              )}
            />
          </FlexContainer>

          {isManyFiles && (
            <FlexContainer gap="10px" marginTop="8px" marginBottom="8px">
              <FlexContainer marginTop="5px">
                <Controller
                  render={({ field }) => (
                    <Select
                      title="Оператор сопоставления"
                      options={maskMultiFilePatternItems}
                      width="196px"
                      placeholder="Тип"
                      {...field}
                      value={field.value}
                    />
                  )}
                  name="maskMultiFilePattern"
                  control={control}
                />
              </FlexContainer>

              <Controller
                name="maskMultiFileValue"
                control={control}
                render={({ field }) => (
                  <TextField
                    description="Если не указать маску будут загружены все файлы выбранного формата"
                    label="Маска"
                    width="100%"
                    {...field}
                  />
                )}
              />
            </FlexContainer>
          )}
          <Controller
            name="isAutoUpdate"
            control={control}
            render={({ field }) => (
              <Checkbox
                name="https"
                padding="8px 0 0 0"
                checked={field.value}
                label="Автообновление"
                id="autoUpdate_flag"
                onChange={field.onChange}
              />
            )}
          />
        </FlexContainer>
      </FlexContainer>

      {renderFooter && renderFooter({ onClose })}
    </Form>
  );
};
