import React, { useEffect, useState } from 'react';
import { useGetActiveVisualisationSettings } from 'utils/hooks/visualisation/getActiveVisualisationSettings';
import {
  defaultSqlDataSettings,
  defaultTextDataSettings,
  defaultTextViewSettings,
} from 'store/reducers/visualisations/constants';
import {
  onAddNewVariables,
  onChangeBackground,
  onChangeCustomRequest,
  onChangePosition,
  onChangePropertiesSettings,
  onChangeText,
  onChangeTextProperties,
  onChangeVariableEmptyValue,
  onChangeVariableFormatting,
  onChangeVariablesName,
  onDeleteVariables,
  onShowHorizontalScrollChange,
  onShowVerticalScrollChange,
  onSqlSettingsSave,
} from 'modules/visualisations/Text/settings/ViewTab/constants';
import { TextPositionSettings } from 'modules/settingsContainer/common/view/PositionSettings';
import { TextBackgroundSettings } from 'modules/settingsContainer/common/view/TextBackgroundSettings';
import { SqlSettings } from 'modules/settingsContainer/common/data/SqlSettings';
import { SettingsFieldEntry } from 'modules/settingsContainer/SettingsFieldEntry';
import { FieldSettingsRenderType, MetricsRenderType } from 'modules/settingsContainer/common/data/MetricSettings/types';
import { TextVariablesInterface } from 'store/reducers/visualisations/types';
import { useDataSettingsMetric } from 'modules/visualisations/hooks/dataSettingsMetric';
import { MetricSettings } from 'modules/settingsContainer/common/data/MetricSettings';
import { getIncisionAndIndicatorsCompletions } from 'utils/sqlSettings';
import { CustomRequestSettings } from 'modules/settingsContainer/common/data/CustomRequestSettings';
import { useSelector } from 'react-redux';
import { getAstForSqlGenerationQueryById } from 'store/reducers/visualisations/getters';
import { getActiveBoardElement } from 'store/reducers/board/getters';
import { getColumnsWithoutSelect } from 'utils/SQL/genereteAst';
import { FormattingSettings } from 'modules/settingsContainer/common/data/FormattingSettings';
import { LimitSettings } from 'modules/settingsContainer/common/data/LimitSettings';
import { onLimitChange, onModelIdChange } from 'modules/visualisations/common/onChangeFunctions';
import { ModelSelectorSettings } from 'modules/settingsContainer/common/ModelSelectorSettings';
import { PropertiesSettings } from 'modules/settingsContainer/common/data/PropertiesSettings';
import { DefaultViewSettings } from 'modules/settingsContainer/common/view/DefaultViewSettings';
import { BooleanSettings } from 'modules/settingsContainer/common/BooleanSettings';
import { GroupContainerSettings } from 'modules/settingsContainer/GroupContainerSettings';
import { MainContainerSettings } from 'modules/settingsContainer/MainContainerSettings';
import { ElementContainerSettings } from 'modules/settingsContainer/ElementContainerSettings';
import { TextArea } from 'modules/ui';
import { EmptyValuesElementSettings } from 'modules/settingsContainer/common/data/EmptyValuesElementSettings';

export const ViewTab = () => {
  const { codeEditorData, viewSettings, dataSettings, astOfVisualisation, positionConfig } = useGetActiveVisualisationSettings({
    defaultData: defaultTextDataSettings,
    defaultView: defaultTextViewSettings,
    defaultSqlData: defaultSqlDataSettings,
  });
  // TODO перенести логику в новый компонент текстАриа
  const [textAreaValue, setTextAreaValue] = useState<string>(() => viewSettings.text.text);

  // TODO перенести логику в новый компонент текстАриа
  useEffect(() => {
    setTextAreaValue(viewSettings.text.text);
  }, [viewSettings.text.text]);

  const { controls, onMetricClick, onSetupClick, setupIsOpen, metricIsSelected, modelMetaData } = useDataSettingsMetric(
    dataSettings.modelId,
  );

  const variablesRender: MetricsRenderType<TextVariablesInterface> = ({ metrics }) => (
    <>
      {metrics.map(({ name, id, customRequest }) => (
        <SettingsFieldEntry
          disabledViewChildren
          disabledViewColorPicker
          beforeField={
            <CustomRequestSettings
              value={customRequest || ''}
              onChange={(customRequest) => onChangeCustomRequest(dataSettings, customRequest, id)}
              modelMetaData={modelMetaData}
            />
          }
          setupIsOpen={setupIsOpen(id)}
          onSetupClick={onSetupClick(id)}
          onClick={onMetricClick(id)}
          isSelected={metricIsSelected(id)}
          fieldValue={name}
          canChangeField
          onFieldChange={(name) => onChangeVariablesName(dataSettings, name, id)}
          key={id}
        />
      ))}
    </>
  );

  const variablesFieldSettingsRender: FieldSettingsRenderType<TextVariablesInterface> = ({ metric: variables }) => {
    const {
      id,
      emptyValues,
      settings: { formatting, textPropertiesSettings },
    } = variables;
    const { isEmptyValue, value } = emptyValues;

    return (
      <>
        <FormattingSettings value={formatting} onChange={(value) => onChangeVariableFormatting(dataSettings, value, id)} />
        <EmptyValuesElementSettings
          switcherValue={isEmptyValue}
          value={value}
          onChange={(value) => onChangeVariableEmptyValue(dataSettings, value, id)}
        />
        <MainContainerSettings titleText="Свойства переменных" isOpenDefault={true}>
          <GroupContainerSettings>
            <ElementContainerSettings>
              <TextArea
                name="textarea"
                needBackground={true}
                value={textPropertiesSettings.text.text}
                onChange={(events) =>
                  onChangePropertiesSettings(
                    dataSettings,
                    {
                      ...textPropertiesSettings,
                      text: { isShow: textPropertiesSettings.text.isShow, text: events.target.value },
                    },
                    id,
                  )
                }
                width="100%"
                placeholder="https://"
                rows={5}
              />
            </ElementContainerSettings>
          </GroupContainerSettings>
          <PropertiesSettings
            isMainContainerColorSettings
            isMainContainerSettings
            disabledComponentColorByValue
            disabledPadding
            value={textPropertiesSettings}
            onChange={(value) => onChangePropertiesSettings(dataSettings, value, id)}
            disabledBackgroundColorBy
            disabledFontColorBy
            switcherStyle={{
              switcherStateStyle: textPropertiesSettings.isActiveStyle,
              switcherChangeStyle: () =>
                onChangePropertiesSettings(
                  dataSettings,
                  { ...textPropertiesSettings, isActiveStyle: !textPropertiesSettings.isActiveStyle },
                  id,
                ),
            }}
            switcherColorBy={{
              switcherStateColorBy: textPropertiesSettings.isActiveFontColor,
              switcherChangeColorBy: () =>
                onChangePropertiesSettings(
                  dataSettings,
                  { ...textPropertiesSettings, isActiveFontColor: !textPropertiesSettings.isActiveFontColor },
                  id,
                ),
            }}
          />
        </MainContainerSettings>
      </>
    );
  };

  const activeBoardElement = useSelector(getActiveBoardElement),
    { variables } = useSelector(getAstForSqlGenerationQueryById(activeBoardElement || ''));

  const variablesNames = dataSettings.variables.map(({ name }) => name);

  //@ts-ignore
  const serviceSqlColumns = variables.filter(({ as }) => variablesNames.includes(as));

  const serviceSqlString = getColumnsWithoutSelect(serviceSqlColumns);

  return (
    <>
      <DefaultViewSettings
        FirstSlot={
          <>
            <ModelSelectorSettings value={dataSettings.modelId} onChange={onModelIdChange} />
            <MainContainerSettings titleText="Свойства " isOpenDefault={true}>
              <GroupContainerSettings>
                <ElementContainerSettings>
                  <TextArea
                    name="textarea"
                    needBackground={true}
                    value={textAreaValue}
                    onChange={(events) => {
                      setTextAreaValue(events.target.value);

                      onChangeText({
                        isShow: viewSettings.text.isShow,
                        text: events.target.value,
                      });
                    }}
                    width="100%"
                    placeholder="Текст"
                    rows={5}
                  />
                </ElementContainerSettings>
              </GroupContainerSettings>
              <PropertiesSettings
                isMainContainerSettings
                isMainContainerColorSettings
                disabledComponentColorByValue
                value={viewSettings.textProperties}
                onChange={onChangeTextProperties}
                disabledBackgroundColorBy
                disabledFontColorBy
              />
            </MainContainerSettings>
            <MetricSettings
              titleText="Переменные"
              addButtonText="Добавить переменную"
              metricRender={variablesRender}
              fieldSettingsRender={variablesFieldSettingsRender}
              metrics={dataSettings.variables}
              onAdd={() => onAddNewVariables(dataSettings)}
              onDelete={(id) => id && onDeleteVariables(dataSettings, id)}
              controls={controls}
            />
            <SqlSettings
              //@ts-ignore
              astData={astOfVisualisation}
              sqlData={codeEditorData}
              serviceTitle="Переменные"
              serviceSqlString={serviceSqlString}
              adviceEditorService={getIncisionAndIndicatorsCompletions(dataSettings.variables)}
              onSave={(sqlSettingsChanges) => onSqlSettingsSave(dataSettings, sqlSettingsChanges)}
              modelMetaData={modelMetaData}
            />
            <LimitSettings value={dataSettings.limit} onChange={onLimitChange} />
            <TextPositionSettings value={viewSettings.position} onChange={onChangePosition} />
          </>
        }
        positionConfig={positionConfig}
        viewSettings={viewSettings}
        ExternalBackgroundSettings={
          <TextBackgroundSettings value={viewSettings.backgroundSettings} onChange={onChangeBackground} />
        }
      />
      <BooleanSettings
        titleText="Прокрутка по горизонтали"
        value={viewSettings.showHorizontalScroll}
        onChange={onShowHorizontalScrollChange}
      />
      <BooleanSettings
        titleText="Прокрутка по вертикали"
        value={viewSettings.showVerticalScroll}
        onChange={onShowVerticalScrollChange}
      />
    </>
  );
};
