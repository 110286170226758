import React, { useCallback } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { PrimaryTextParagraph } from 'styles/TextsElements';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { ModelRuleUserAndGroupInterface } from 'store/reducers/models/types';
import { useAppDispatch } from 'store';
import { UniversalLeftBarList } from 'modules/settingsContainer/UniversalLeftBarList';
import { closeModalAction, openConfirmationModalAction, openModalTypedAction } from 'store/reducers/modals/actions';
import { DefaultUserInterface } from 'types/store';
import {
  changeActiveRuleGroupIdAction,
  changeActiveRuleUserIdAction,
  deleteByIdRuleGroupAction,
} from 'store/reducers/models/actions';
import { AddUserOrGroupsModal } from 'components/contents/Models/EditableMenu/PopoverRule/Modal/AddUserModal';
import { useSelector } from 'react-redux';
import { getActiveModelRuleGroup, getGroups } from 'store/reducers/models/getters';
import { onMoveItemGroup } from 'components/contents/Models/EditableMenu/PopoverRule/Modal/RuleModal/GroupsRule/constants';
import { GroupsListInterface } from 'store/reducers/adminGroups/types';
import { Hint } from 'modules/ui/Hint';
import { HintChildrenDefaultRule } from 'components/contents/Models/EditableMenu/PopoverRule/Modal/RuleModal/Hint/HintChildrenDefaultRule';

interface GroupsRuleProps {
  groupsRule: ModelRuleUserAndGroupInterface[];
}

export const GroupsRule = ({ groupsRule }: GroupsRuleProps) => {
  const dispatch = useAppDispatch();
  const groupsList = useSelector(getGroups);
  const activeModelRuleGroup = useSelector(getActiveModelRuleGroup);

  const filteredGroupsList = groupsList.filter((group) => !groupsRule?.some((groupRule) => groupRule.id === group.id));

  const modelMetaGroupsDataList = groupsRule.map(({ id, name }) => ({
    items: [],
    id: String(id),
    title: name,
    type: name,
  }));

  const onDeleteModal = useCallback(
    ({ id, title }: DefaultUserInterface) => {
      const onDeleteItem = (userID: string) => dispatch(deleteByIdRuleGroupAction(userID));

      dispatch(
        openConfirmationModalAction({
          confirmationButtonText: 'Удалить',
          subTitleText: `Действительно удалить группу ${title} из правила?`,
          onConfirm: () => {
            onDeleteItem(id);
            dispatch(changeActiveRuleGroupIdAction(null));
          },
          titleText: 'Удалиение группы',
          width: '320px',
          disableModalPortal: true,
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onAddGroupModal = useCallback(
    async () => {
      const onCloseAddGroupModal = () => dispatch(closeModalAction('addRuleUserModal'));

      try {
        const multiSelectData = filteredGroupsList.map((el) => ({ title: el.name, value: el.id }));

        dispatch(
          openModalTypedAction({
            Component: AddUserOrGroupsModal,
            componentProps: {
              onClose: onCloseAddGroupModal,
              multiSelectData,
              mode: 'groups',
            },
            modalSettings: {
              headerText: 'Добавление группы пользователей',
              disableModalPortal: true,
            },
            name: 'addRuleUserModal',
          }),
        );
      } catch (error) {
        return;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filteredGroupsList],
  );

  const onUpClick = useCallback((id: string) => onMoveItemGroup(groupsRule, id, 'up'), [groupsRule]);

  const onDownClick = useCallback((id: string) => onMoveItemGroup(groupsRule, id, 'down'), [groupsRule]);

  const onSelectedItem = useCallback(
    async ({ id, title }: GroupsListInterface) => {
      dispatch(changeActiveRuleGroupIdAction({ id, name: title, variableValues: [] }));
      dispatch(changeActiveRuleUserIdAction(null));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <FlexContainer flexDirection="column" height="100%" position="relative">
      <FlexContainer flexDirection="row" alignItems="center" gap="8px">
        <PrimaryTextParagraph fontWeight="bold" fontSize="14px" lineHeight="16px" color={`var(${ColorVarsEnum.Level_1})`}>
          Группы
        </PrimaryTextParagraph>
        <Hint>
          <HintChildrenDefaultRule title="Если пользователь входит в состав двух или более групп, то для него сработает правило группы, которая выше в списке" />
        </Hint>
      </FlexContainer>
      <UniversalLeftBarList
        buttonTitle="Добавить группу"
        disabledMobileVersion
        data={modelMetaGroupsDataList}
        activeTitle={activeModelRuleGroup?.name}
        activeId={activeModelRuleGroup?.id}
        loadingList={false}
        disabledAdditionalField
        onSelectedItem={onSelectedItem}
        onDeleteItem={onDeleteModal}
        onCreateItem={onAddGroupModal}
        height="100%"
        backgroundColorSidebar={`var(${ColorVarsEnum.Level_3_menu})`}
        onUpClick={onUpClick}
        onDownClick={onDownClick}
        buttonMinHeight="0"
        isShowMoveTo={true}
      />
    </FlexContainer>
  );
};
