import styled from 'styled-components';
import { fileInputMixin } from 'constants/styles';

export const FileInput = styled.input`
  ${fileInputMixin}
`;

export const Body = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  height: 80vh;
  flex-wrap: nowrap;
`;
