import React, { useCallback } from 'react';
import { Button } from 'modules/ui';
import { useAppDispatch } from 'store';
import { LoadingOverlay } from 'modules/ui/Loading/LoadingOverlay';
import { Form } from './styles';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { MultiSelect, MultiSelectType } from 'modules/ui/MultiSelect';
import { FlexContainer } from 'styles/FlexContainer';
import { ModalComponentType } from 'store/reducers/modals/types';
import { GroupAccessUserListInterface } from 'store/reducers/adminGroups/types';
import { createRuleGroupsAction, createRuleUsersAction } from 'store/reducers/models/actions';
import { ModelRuleUserAndGroupInterface } from 'store/reducers/models/types';
import { useSelector } from 'react-redux';
import { getAllVariables } from 'store/reducers/models/getters';

interface AddUserOrGroupsModalProps {
  multiSelectData: MultiSelectType<GroupAccessUserListInterface>[];
  mode: 'users' | 'groups';
}

export const AddUserOrGroupsModal: ModalComponentType<AddUserOrGroupsModalProps> = ({ onClose, multiSelectData, mode }) => {
  const dispatch = useAppDispatch();
  const isUsers = mode === 'users';
  const allVariablesList = useSelector(getAllVariables);

  const {
    control,
    handleSubmit,
    formState: { isDirty, isSubmitting },
  } = useForm<{
    users: ModelRuleUserAndGroupInterface[];
  }>({
    mode: 'onSubmit',
  });

  const onSubmit: SubmitHandler<{
    users: ModelRuleUserAndGroupInterface[];
  }> = useCallback(
    async ({ users }) => {
      try {
        if (!isDirty || isSubmitting) {
          return null;
        }

        const variableValues = allVariablesList.map(({ name, defaultValue }) => ({
          name,
          value: defaultValue,
        }));

        const usersAndGroups = users.map(({ id, name }) => ({
          id,
          name,
          variableValues,
        }));

        isUsers ? await dispatch(createRuleUsersAction(usersAndGroups)) : await dispatch(createRuleGroupsAction(usersAndGroups));

        onClose();
      } catch (e) {
        return;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isDirty, isUsers, isSubmitting, onClose],
  );

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <LoadingOverlay loading={isSubmitting} />

      <Controller
        name="users"
        rules={{ required: true }}
        control={control}
        render={({ field: { onChange } }) => (
          <MultiSelect
            label="Пользователи"
            noOptionsText="Пользователи не найдены"
            options={multiSelectData}
            onChange={(item) => onChange(item.map((el) => ({ id: el.value, name: el.title })))}
          />
        )}
      />

      <FlexContainer gap="10px" marginLeft="auto" marginTop="16px">
        <Button needBackground={false} text="Отмена" iconSize="normal" heightSize="normal" onClick={onClose} />
        <Button text="Добавить" iconSize="normal" heightSize="normal" type="submit" />
      </FlexContainer>
    </Form>
  );
};
