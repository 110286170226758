import React from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { TextArea } from 'modules/ui';
import { PrimaryTextParagraph } from 'styles/TextsElements';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { Hint } from 'modules/ui/Hint';
import { HintChildrenMainFilter } from 'components/contents/Models/EditableMenu/PopoverRule/Modal/RuleModal/Hint/HintChildrenMainFilter';
import { OnValueChange } from 'types/global';

type MainFilterProps = OnValueChange<string>;

export const MainFilter = ({ value, onChange }: MainFilterProps) => (
  <FlexContainer flexDirection="column" height="100%" width="100%" gap="4px" maxHeight="50%">
    <FlexContainer flexDirection="row" alignItems="center" gap="8px">
      <PrimaryTextParagraph fontWeight="bold" lineHeight="14px" fontSize="14px" color={`var(${ColorVarsEnum.Level_1})`}>
        Основной фильтр
      </PrimaryTextParagraph>
      <Hint>
        <HintChildrenMainFilter />
      </Hint>
    </FlexContainer>
    <FlexContainer height="100%" maxHeight="288px">
      <TextArea
        name="textarea"
        needBackground={true}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        width="100%"
        maxRows={12}
      />
    </FlexContainer>
  </FlexContainer>
);
