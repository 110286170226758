import { ColorManaging } from 'components/ColorManaging/ColorManaging';
import { conditionDefault, conditionIncision, conditionIndicator, conditionValue } from 'components/ColorManaging/constants';
import { GeneralSettingsSwitch } from 'components/shared/generalSettingsSwitch';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { GroupContainerSettings, MainContainerSettings } from 'modules/settingsContainer';
import { CircleActivator, ColorPicker } from 'modules/settingsContainer/ColorPicker';
import { useColorValues } from 'modules/settingsContainer/ColorPicker/hooks';
import { ColorValuesByThemeType } from 'modules/settingsContainer/ColorPicker/types';
import { ColumnWidthSettings } from 'modules/settingsContainer/common/ColumnWidthSettings';
import { DefaultDataSettings } from 'modules/settingsContainer/common/data/DefaultDataSettings';
import { FormattingSettings } from 'modules/settingsContainer/common/data/FormattingSettings';
import { LimitSettings } from 'modules/settingsContainer/common/data/LimitSettings';
import { MakeHyperLinksSettings } from 'modules/settingsContainer/common/data/MakeHyperLinks';
import { MetricSettings } from 'modules/settingsContainer/common/data/MetricSettings';
import { FieldSettingsRenderType, MetricsRenderType } from 'modules/settingsContainer/common/data/MetricSettings/types';
import { NameFromDatabaseSettings } from 'modules/settingsContainer/common/data/NameFromDatabaseSettings';
import { PropertiesSettings } from 'modules/settingsContainer/common/data/PropertiesSettings';
import { SortByColumnSettings } from 'modules/settingsContainer/common/data/SortByColumnSettings';
import { SqlSettings } from 'modules/settingsContainer/common/data/SqlSettings';
import { TableTotalIndicatorSettings } from 'modules/settingsContainer/common/data/TableTotalIndicatorSettings';
import { FictionalMetricField } from 'modules/settingsContainer/common/FictionalMetricField';
import { IndicatorMetricField } from 'modules/settingsContainer/common/IndicatorMetricField';
import { ModelSelectorSettings } from 'modules/settingsContainer/common/ModelSelectorSettings';
import { SettingsFieldEntry } from 'modules/settingsContainer/SettingsFieldEntry';
import SubSection from 'shared/ui/subSection';
import {
  onAddNewIncision,
  onAddNewIncisionInHeader,
  onAddNewIndicator,
  onChangeGroupingAllIncisions,
  onChangeGroupingTableRows,
  onChangeIncisionName,
  onChangeIndicatorCustomRequest,
  onChangeIndicatorEmptyValue,
  onChangeIndicatorFormatting,
  onChangeIndicatorName,
  onChangeIndicatorNameFromDataBase,
  onDeleteIncision,
  onDeleteIncisionInHeader,
  onDeleteIndicator,
  onLimitChange,
  onLimitGroupingChange,
  onModelIdChange,
  onSqlSettingsSave,
} from 'modules/visualisations/common/onChangeFunctions';
import { useDataSettingsMetric } from 'modules/visualisations/hooks/dataSettingsMetric';
import {
  onChangeIndicatorLabelProperties,
  onChangeIndicatorMakeHyperLinks,
  onChangeTableEmptyValue,
  onChangeTableFictionalData,
  onChangeTableIncisionCustomRequest,
  onChangeTableIncisionFieldName,
  onChangeTableIncisionLabelProperties,
  onChangeTableIncisionName,
  onChangeTableIncisionNameFromDataBase,
  onChangeTableIncisionsLabelProperties,
  onColumnWidthIndicatorChange,
  onMoveIndicator,
  onMoveTableIncision,
  onSortChange,
  onStepIncisionClickWithGrouping,
  onTableColumnWidthIncisionsChange,
  onTableHasIndentationIncisionsChange,
  onTableHasIndentationIndicatorChange,
  onTableIndentationIncisionsChange,
  onTableIndentationIndicatorChange,
  onTotalIndicatorChange,
} from 'modules/visualisations/Table/settings/DataTab/constants';
import React from 'react';
import { IndentStyledComponent } from 'shared/ui/IndentStyledComponent';
import {
  defaultSqlDataSettings,
  defaultTableDataSettings,
  getVisualisationFieldName,
} from 'store/reducers/visualisations/constants';
import {
  DefaultPropertiesInterface,
  TableDataSettings,
  TableIncisionInterface,
  TableIndicatorInterface,
} from 'store/reducers/visualisations/types';
import { useGetActiveVisualisationSettings } from 'utils/hooks/visualisation/getActiveVisualisationSettings';
import { getIncisionAndIndicatorsCompletions } from 'utils/sqlSettings';
import { CommonSettingsInterface, IncisionsKeysEnum } from './types';
import { EmptyValuesElementSettings } from 'modules/settingsContainer/common/data/EmptyValuesElementSettings';

export const DataTab = () => {
  const { dataSettings, codeEditorData, astOfVisualisation } = useGetActiveVisualisationSettings({
    defaultData: defaultTableDataSettings,
    defaultSqlData: defaultSqlDataSettings,
  });

  const { isRealData, hasAllGroupIncision, modelId, incisionsInHeader, incisions, indicators } = dataSettings;
  const { activeThemeSchema } = useColorValues();
  const hasGroupingIncision = !!incisions.filter((incision) => incision.settings.isGroup)[0];
  const isPivotTable = !!dataSettings.incisionsInHeader.length;

  const { tableFields, controls, onMetricClick, onSetupClick, setupIsOpen, metricIsSelected, modelMetaData } =
    useDataSettingsMetric(modelId);

  const CommonSettingsBackgroundColor = ({ properties, onChange, options, id }: CommonSettingsInterface) => {
    return (
      <ColorManaging
        titleText="Изменить цвет заливки"
        onChangeProperties={(value) =>
          onChange(
            dataSettings,
            {
              ...properties,
              isActiveBackgroundColor: value,
            },
            id,
          )
        }
        changeFillColor={properties.isActiveBackgroundColor}
        colorBy={properties?.backgroundColorBy}
        onChange={(value) => onChange(dataSettings, { ...properties, backgroundColorBy: value }, id)}
        options={options}
        isRealData={isRealData}
      >
        <ColorPicker
          Activator={CircleActivator}
          closeOnClick
          type="color"
          onChange={(backgroundColor) =>
            onChange(
              dataSettings,
              {
                ...properties,
                backgroundColor,
              },
              id,
            )
          }
          value={properties.backgroundColor}
          defaultActivatorBackgroundColor={activeThemeSchema[ColorVarsEnum.Level_1]}
        />
      </ColorManaging>
    );
  };

  const incisionInHeaderRender: MetricsRenderType<TableIncisionInterface> = ({ metrics }) => {
    const hasIncisionsGrouping = metrics.filter((metric) => metric.settings.isGroup);

    return (
      <>
        {metrics.map(({ fieldName, name, fictionalData, id, settings: { nameFromDatabase, customRequest, properties } }) => {
          const onUpClick = () => {
            if (hasIncisionsGrouping.length > 0) {
              return onStepIncisionClickWithGrouping({ dataSettings, hasIncisionsGrouping, id, moveTo: 'up' });
            }
            onMoveTableIncision(dataSettings, id, 'up', IncisionsKeysEnum.IncisionsInHeader);
          };
          const onDownClick = () => {
            if (hasIncisionsGrouping.length > 0) {
              return onStepIncisionClickWithGrouping({ dataSettings, hasIncisionsGrouping, id, moveTo: 'down' });
            }
            onMoveTableIncision(dataSettings, id, 'down', IncisionsKeysEnum.IncisionsInHeader);
          };
          const onChangeColors = (backgroundColor: ColorValuesByThemeType | null) => {
            onChangeTableIncisionLabelProperties(
              dataSettings,
              {
                ...properties,
                isActiveBackgroundColor: true,
                backgroundColor,
              },
              id,
              IncisionsKeysEnum.IncisionsInHeader,
            );
          };

          return (
            <SettingsFieldEntry
              setupIsOpen={setupIsOpen(id)}
              onSetupClick={onSetupClick(id)}
              onClick={onMetricClick(id)}
              isSelected={metricIsSelected(id)}
              fieldValue={getVisualisationFieldName({ nameFromDatabase, fieldName, name })}
              canChangeField={!nameFromDatabase}
              onFieldChange={(name) => onChangeTableIncisionName(dataSettings, name, id, IncisionsKeysEnum.IncisionsInHeader)}
              onDownClick={onDownClick}
              onUpClick={onUpClick}
              onChangeColors={onChangeColors}
              colorsValue={properties.backgroundColor}
              colorPickerType="color"
              colorByHand={properties.backgroundColorBy.type === 'default'}
              key={id}
            >
              <FictionalMetricField
                onCustomRequestChange={(customRequest) =>
                  onChangeTableIncisionCustomRequest(dataSettings, customRequest, id, IncisionsKeysEnum.IncisionsInHeader)
                }
                customRequest={customRequest || ''}
                isRealData={isRealData}
                options={tableFields}
                disabled={!!customRequest}
                value={{ fictionalData, fieldName }}
                onChange={({ fictionalData, fieldName }) => {
                  fieldName &&
                    onChangeTableIncisionFieldName(dataSettings, fieldName || '', id, IncisionsKeysEnum.IncisionsInHeader);
                  fictionalData &&
                    onChangeTableFictionalData(dataSettings, fictionalData, id, IncisionsKeysEnum.IncisionsInHeader);
                }}
                modelMetaData={modelMetaData}
              />
            </SettingsFieldEntry>
          );
        })}
      </>
    );
  };

  const incisionInHeaderFieldSettingsRender: FieldSettingsRenderType<TableIncisionInterface> = ({
    metric: incision,
    metrics,
  }) => {
    const {
        id,
        name,
        fieldName,
        settings: {
          emptyValues: { isEmptyValue, value },
          nameFromDatabase,
          columnWidthSettings,
          properties,
          isGroup,
          indentation,
          hasIndentation,
        },
      } = incision,
      columnName = nameFromDatabase ? fieldName || '' : name,
      sortItsColumn = dataSettings.orderBy.filter((el) => el.columnName === columnName)?.[0],
      indexMetric = metrics.map(({ id }) => id).indexOf(id),
      isPreviousMetricGrouped = indexMetric !== 0 ? metrics[indexMetric - 1]?.settings.isGroup : true,
      isNextMetricGrouped = metrics[indexMetric + 1]?.settings.isGroup,
      possibilityChangeGrouping = !isGroup || !isNextMetricGrouped || (!isNextMetricGrouped && isPreviousMetricGrouped);

    return (
      <>
        <EmptyValuesElementSettings
          switcherValue={isEmptyValue}
          value={value}
          onChange={(value) => onChangeTableEmptyValue(dataSettings, value, id, IncisionsKeysEnum.IncisionsInHeader)}
        />
        {!isPivotTable && !hasAllGroupIncision && isPreviousMetricGrouped && (
          <GeneralSettingsSwitch
            titleText="Группировать"
            onChange={(group: boolean) => {
              if (possibilityChangeGrouping) {
                onChangeGroupingTableRows(dataSettings, group, id, IncisionsKeysEnum.IncisionsInHeader);
              }
            }}
            value={!!isGroup}
          />
        )}
        <MainContainerSettings titleText="Параметры текста">
          <PropertiesSettings
            isMainContainerColorSettings
            isMainContainerSettings
            disabledComponentColorByValue
            disabledFontColorByBlock
            disabledPadding
            value={properties}
            onChange={(value) =>
              onChangeTableIncisionsLabelProperties(dataSettings, value, id, IncisionsKeysEnum.IncisionsInHeader)
            }
            disabledBackgroundColorBy
            disabledFontColorBy
            disabledOpacity
            activeAlignmentVertical
            activeAlignmentHorizontal
            switcherStyle={{
              switcherStateStyle: properties.isActiveStyle,
              switcherChangeStyle: () =>
                onChangeTableIncisionsLabelProperties(
                  dataSettings,
                  {
                    ...properties,
                    isActiveStyle: !properties.isActiveStyle,
                  },
                  id,
                  IncisionsKeysEnum.IncisionsInHeader,
                ),
            }}
          />
          <ColorManaging
            titleText="Цвет"
            colorBy={properties?.fontColorBy}
            changeFillColor={properties?.isActiveFontColor}
            onChangeProperties={(value) =>
              onChangeTableIncisionsLabelProperties(
                dataSettings,
                {
                  ...properties,
                  isActiveFontColor: value,
                },
                id,
                IncisionsKeysEnum.IncisionsInHeader,
              )
            }
            onChange={(value) =>
              onChangeTableIncisionsLabelProperties(
                dataSettings,
                {
                  ...properties,
                  fontColorBy: value,
                },
                id,
                IncisionsKeysEnum.IncisionsInHeader,
              )
            }
            options={[conditionDefault]}
            isRealData={isRealData}
          >
            <ColorPicker
              Activator={CircleActivator}
              closeOnClick
              type="color"
              onChange={(fontColor) =>
                onChangeTableIncisionsLabelProperties(
                  dataSettings,
                  {
                    ...properties,
                    fontColor,
                  },
                  id,
                  IncisionsKeysEnum.IncisionsInHeader,
                )
              }
              value={properties.fontColor}
              defaultActivatorBackgroundColor={activeThemeSchema[ColorVarsEnum.Level_1]}
            />
          </ColorManaging>
        </MainContainerSettings>
        <MainContainerSettings titleText="Параметры ячейки">
          <CommonSettingsBackgroundColor
            properties={properties}
            onChange={(dataSettings: TableDataSettings, properties: DefaultPropertiesInterface, id: string) =>
              onChangeTableIncisionsLabelProperties(dataSettings, properties, id, IncisionsKeysEnum.IncisionsInHeader)
            }
            options={[conditionDefault]}
            id={id}
          />
          <ColumnWidthSettings
            value={columnWidthSettings}
            onChange={(columnWidthSettings) =>
              onTableColumnWidthIncisionsChange(dataSettings, columnWidthSettings, id, IncisionsKeysEnum.IncisionsInHeader)
            }
          />
          <GroupContainerSettings
            titleText="Изменить отступы"
            switcherState={hasIndentation}
            switcherChange={() =>
              onTableHasIndentationIncisionsChange(dataSettings, !hasIndentation, id, IncisionsKeysEnum.IncisionsInHeader)
            }
          >
            <IndentStyledComponent
              value={indentation}
              onChange={(indentation) =>
                onTableIndentationIncisionsChange(dataSettings, indentation, id, IncisionsKeysEnum.IncisionsInHeader)
              }
            />
          </GroupContainerSettings>
        </MainContainerSettings>

        <NameFromDatabaseSettings
          value={nameFromDatabase}
          onChange={(nameFromDatabase: boolean) =>
            onChangeTableIncisionNameFromDataBase(dataSettings, nameFromDatabase, id, IncisionsKeysEnum.IncisionsInHeader)
          }
        />
        <SortByColumnSettings
          value={sortItsColumn}
          onChange={({ type, isActive }) =>
            onSortChange({
              dataSettings,
              columnName,
              type,
              sortItsColumn,
              isActive,
            })
          }
        />
      </>
    );
  };

  const incisionRender: MetricsRenderType<TableIncisionInterface> = ({ metrics }) => {
    const hasIncisionsGrouping = metrics.filter((metric) => metric.settings.isGroup);

    return (
      <>
        {metrics.map(({ fieldName, name, fictionalData, id, settings: { nameFromDatabase, customRequest, properties } }) => {
          const onUpClick = () => {
            if (hasIncisionsGrouping.length > 0) {
              return onStepIncisionClickWithGrouping({ dataSettings, hasIncisionsGrouping, id, moveTo: 'up' });
            }
            onMoveTableIncision(dataSettings, id, 'up', IncisionsKeysEnum.Incisions);
          };
          const onDownClick = () => {
            if (hasIncisionsGrouping.length > 0) {
              return onStepIncisionClickWithGrouping({ dataSettings, hasIncisionsGrouping, id, moveTo: 'down' });
            }
            onMoveTableIncision(dataSettings, id, 'down', IncisionsKeysEnum.Incisions);
          };
          const onChangeColors = (backgroundColor: ColorValuesByThemeType | null) => {
            onChangeTableIncisionLabelProperties(
              dataSettings,
              {
                ...properties,
                isActiveBackgroundColor: true,
                backgroundColor,
              },
              id,
              IncisionsKeysEnum.Incisions,
            );
          };

          return (
            <SettingsFieldEntry
              setupIsOpen={setupIsOpen(id)}
              onSetupClick={onSetupClick(id)}
              onClick={onMetricClick(id)}
              isSelected={metricIsSelected(id)}
              fieldValue={getVisualisationFieldName({ nameFromDatabase, fieldName, name })}
              canChangeField={!nameFromDatabase}
              onFieldChange={(name) => onChangeIncisionName(dataSettings, name, id)}
              onDownClick={onDownClick}
              onUpClick={onUpClick}
              onChangeColors={onChangeColors}
              colorsValue={properties.backgroundColor}
              colorPickerType="color"
              colorByHand={properties.backgroundColorBy.type === 'default'}
              key={id}
            >
              <FictionalMetricField
                onCustomRequestChange={(customRequest) =>
                  onChangeTableIncisionCustomRequest(dataSettings, customRequest, id, IncisionsKeysEnum.Incisions)
                }
                customRequest={customRequest || ''}
                isRealData={isRealData}
                options={tableFields}
                disabled={!!customRequest}
                value={{ fictionalData, fieldName }}
                onChange={({ fictionalData, fieldName }) => {
                  fieldName && onChangeTableIncisionFieldName(dataSettings, fieldName || '', id, IncisionsKeysEnum.Incisions);
                  fictionalData && onChangeTableFictionalData(dataSettings, fictionalData, id, IncisionsKeysEnum.Incisions);
                }}
                modelMetaData={modelMetaData}
              />
            </SettingsFieldEntry>
          );
        })}
      </>
    );
  };

  const incisionFieldSettingsRender: FieldSettingsRenderType<TableIncisionInterface> = ({ metric: incision, metrics }) => {
    const {
        id,
        name,
        fieldName,
        settings: {
          emptyValues: { isEmptyValue, value },
          nameFromDatabase,
          columnWidthSettings,
          properties,
          isGroup,
          indentation,
          hasIndentation,
        },
      } = incision,
      columnName = nameFromDatabase ? fieldName || '' : name,
      sortItsColumn = dataSettings.orderBy.filter((el) => el.columnName === columnName)?.[0],
      indexMetric = metrics.map(({ id }) => id).indexOf(id),
      isPreviousMetricGrouped = indexMetric !== 0 ? metrics[indexMetric - 1]?.settings.isGroup : true,
      isNextMetricGrouped = metrics[indexMetric + 1]?.settings.isGroup,
      possibilityChangeGrouping = !isGroup || !isNextMetricGrouped || (!isNextMetricGrouped && isPreviousMetricGrouped);

    return (
      <>
        <EmptyValuesElementSettings
          switcherValue={isEmptyValue}
          value={value}
          onChange={(value) => onChangeTableEmptyValue(dataSettings, value, id, IncisionsKeysEnum.Incisions)}
        />
        {!hasAllGroupIncision && isPreviousMetricGrouped && (
          <GeneralSettingsSwitch
            titleText="Группировать"
            onChange={(group: boolean) => {
              if (possibilityChangeGrouping) {
                onChangeGroupingTableRows(dataSettings, group, id, IncisionsKeysEnum.Incisions);
              }
            }}
            value={!!isGroup}
          />
        )}
        <MainContainerSettings titleText="Параметры текста">
          <SubSection>
            <PropertiesSettings
              isMainContainerColorSettings
              isMainContainerSettings
              disabledComponentColorByValue
              disabledFontColorByBlock
              disabledPadding
              value={properties}
              onChange={(value) => onChangeTableIncisionsLabelProperties(dataSettings, value, id, IncisionsKeysEnum.Incisions)}
              disabledBackgroundColorBy
              disabledFontColorBy
              disabledOpacity
              activeAlignmentVertical
              activeAlignmentHorizontal
              switcherStyle={{
                switcherStateStyle: properties.isActiveStyle,
                switcherChangeStyle: () =>
                  onChangeTableIncisionsLabelProperties(
                    dataSettings,
                    {
                      ...properties,
                      isActiveStyle: !properties.isActiveStyle,
                    },
                    id,
                    IncisionsKeysEnum.Incisions,
                  ),
              }}
            />
          </SubSection>
          <SubSection>
            <ColorManaging
              titleText="Изменить цвет текста"
              colorBy={properties?.fontColorBy}
              changeFillColor={properties.isActiveFontColor}
              onChangeProperties={(value) =>
                onChangeTableIncisionsLabelProperties(
                  dataSettings,
                  {
                    ...properties,
                    isActiveFontColor: value,
                  },
                  id,
                  IncisionsKeysEnum.Incisions,
                )
              }
              onChange={(value) =>
                onChangeTableIncisionsLabelProperties(
                  dataSettings,
                  {
                    ...properties,
                    fontColorBy: value,
                  },
                  id,
                  IncisionsKeysEnum.Incisions,
                )
              }
              options={!isPivotTable ? conditionIncision : [conditionDefault]}
              isRealData={isRealData}
            >
              <ColorPicker
                Activator={CircleActivator}
                closeOnClick
                type="color"
                onChange={(fontColor) =>
                  onChangeTableIncisionsLabelProperties(
                    dataSettings,
                    {
                      ...properties,
                      fontColor,
                    },
                    id,
                    IncisionsKeysEnum.Incisions,
                  )
                }
                value={properties.fontColor}
                defaultActivatorBackgroundColor={activeThemeSchema[ColorVarsEnum.Level_1]}
              />
            </ColorManaging>
          </SubSection>
        </MainContainerSettings>
        <MainContainerSettings titleText="Параметры ячейки">
          <SubSection>
            <CommonSettingsBackgroundColor
              properties={properties}
              onChange={(dataSettings: TableDataSettings, properties: DefaultPropertiesInterface, id: string) =>
                onChangeTableIncisionsLabelProperties(dataSettings, properties, id, IncisionsKeysEnum.Incisions)
              }
              options={!isPivotTable ? conditionIncision : [conditionDefault]}
              id={id}
            />
          </SubSection>
          <SubSection>
            <ColumnWidthSettings
              value={columnWidthSettings}
              onChange={(columnWidthSettings) =>
                onTableColumnWidthIncisionsChange(dataSettings, columnWidthSettings, id, IncisionsKeysEnum.Incisions)
              }
            />
          </SubSection>
          <SubSection>
            <GroupContainerSettings
              titleText="Изменить отступы"
              switcherState={hasIndentation}
              switcherChange={() =>
                onTableHasIndentationIncisionsChange(dataSettings, !hasIndentation, id, IncisionsKeysEnum.Incisions)
              }
            >
              <IndentStyledComponent
                value={indentation}
                onChange={(indentation) =>
                  onTableIndentationIncisionsChange(dataSettings, indentation, id, IncisionsKeysEnum.Incisions)
                }
              />
            </GroupContainerSettings>
          </SubSection>
        </MainContainerSettings>

        <NameFromDatabaseSettings
          value={nameFromDatabase}
          onChange={(nameFromDatabase: boolean) =>
            onChangeTableIncisionNameFromDataBase(dataSettings, nameFromDatabase, id, IncisionsKeysEnum.Incisions)
          }
        />

        <SortByColumnSettings
          value={sortItsColumn}
          onChange={({ type, isActive }) =>
            onSortChange({
              dataSettings,
              columnName,
              type,
              sortItsColumn,
              isActive,
            })
          }
        />
      </>
    );
  };

  const indicatorRender: MetricsRenderType<TableIndicatorInterface> = ({ metrics }) => (
    <>
      {metrics.map(({ id, fieldName, name, operationType, customRequest, settings: { nameFromDatabase, properties } }) => {
        const onUpClick = () => onMoveIndicator(dataSettings, id, 'up');
        const onDownClick = () => onMoveIndicator(dataSettings, id, 'down');
        const onChangeColors = (backgroundColor: ColorValuesByThemeType | null) => {
          onChangeIndicatorLabelProperties(
            dataSettings,
            {
              ...properties,
              isActiveBackgroundColor: true,
              backgroundColor,
            },
            id,
          );
        };
        return (
          <SettingsFieldEntry
            setupIsOpen={setupIsOpen(id)}
            onSetupClick={onSetupClick(id)}
            onClick={onMetricClick(id)}
            isSelected={metricIsSelected(id)}
            fieldValue={getVisualisationFieldName({ name, nameFromDatabase, fieldName })}
            canChangeField={!nameFromDatabase}
            onFieldChange={(name) => onChangeIndicatorName(dataSettings, name, id)}
            onUpClick={onUpClick}
            onDownClick={onDownClick}
            onChangeColors={onChangeColors}
            colorsValue={properties.backgroundColor}
            colorByHand={properties.backgroundColorBy.type === 'default'}
            key={id}
          >
            <IndicatorMetricField
              onCustomRequestChange={(customRequest) => onChangeIndicatorCustomRequest(dataSettings, customRequest, id)}
              customRequest={customRequest}
              options={tableFields}
              dataSettings={dataSettings}
              id={id}
              isRealData={isRealData}
              fieldName={fieldName}
              operationType={operationType}
              modelMetaData={modelMetaData}
            />
          </SettingsFieldEntry>
        );
      })}
    </>
  );

  const indicatorFieldSettingsRender: FieldSettingsRenderType<TableIndicatorInterface> = ({ metric: indicator }) => {
    const {
        id,
        name,
        fieldName,
        emptyValues,
        settings: {
          nameFromDatabase,
          columnWidthSettings,
          totalSettings,
          dynamicsMarkerSettings,
          formatting,
          makeHyperLinks,
          properties,
          indentation,
          hasIndentation,
        },
      } = indicator,
      columnName = nameFromDatabase ? fieldName || '' : name,
      sortItsColumn = dataSettings.orderBy.filter((el) => el.columnName === columnName)?.[0];

    const { isEmptyValue, value } = emptyValues;

    return (
      <>
        <NameFromDatabaseSettings
          value={nameFromDatabase}
          onChange={(nameFromDatabase: boolean) => onChangeIndicatorNameFromDataBase(dataSettings, nameFromDatabase, id)}
        />
        <EmptyValuesElementSettings
          switcherValue={isEmptyValue}
          value={value}
          onChange={(value) => onChangeIndicatorEmptyValue(dataSettings, value, id)}
        />
        <MainContainerSettings titleText="Параметры текста">
          <SubSection>
            <PropertiesSettings
              isMainContainerColorSettings
              isMainContainerSettings
              disabledComponentColorByValue
              disabledFontColorByBlock
              disabledPadding
              value={properties}
              onChange={(value) => onChangeIndicatorLabelProperties(dataSettings, value, id)}
              disabledBackgroundColorBy
              disabledFontColorBy
              disabledOpacity
              activeAlignmentVertical
              activeAlignmentHorizontal
              switcherStyle={{
                switcherStateStyle: properties.isActiveStyle,
                switcherChangeStyle: () =>
                  onChangeIndicatorLabelProperties(
                    dataSettings,
                    {
                      ...properties,
                      isActiveStyle: !properties.isActiveStyle,
                    },
                    id,
                  ),
              }}
            />
          </SubSection>
          <SubSection>
            <ColorManaging
              titleText="Изменить цвет текста"
              colorBy={properties?.fontColorBy}
              changeFillColor={properties?.isActiveFontColor}
              onChangeProperties={(value) =>
                onChangeIndicatorLabelProperties(
                  dataSettings,
                  {
                    ...properties,
                    isActiveFontColor: value,
                  },
                  id,
                )
              }
              onChange={(value) => onChangeIndicatorLabelProperties(dataSettings, { ...properties, fontColorBy: value }, id)}
              options={!isPivotTable ? conditionIndicator : [conditionDefault, conditionValue]}
              isRealData={isRealData}
            >
              <ColorPicker
                Activator={CircleActivator}
                closeOnClick
                type="color"
                onChange={(fontColor) =>
                  onChangeIndicatorLabelProperties(
                    dataSettings,
                    {
                      ...properties,
                      fontColor,
                    },
                    id,
                  )
                }
                value={properties.fontColor}
                defaultActivatorBackgroundColor={activeThemeSchema[ColorVarsEnum.Level_1]}
              />
            </ColorManaging>
          </SubSection>
        </MainContainerSettings>
        <MainContainerSettings titleText="Параметры ячейки">
          <SubSection>
            <CommonSettingsBackgroundColor
              properties={properties}
              onChange={onChangeIndicatorLabelProperties}
              options={!isPivotTable ? conditionIndicator : [conditionDefault, conditionValue]}
              id={id}
            />
          </SubSection>
          <SubSection>
            <ColumnWidthSettings
              value={columnWidthSettings}
              onChange={(columnWidthSettings) => onColumnWidthIndicatorChange(dataSettings, columnWidthSettings, id)}
            />
          </SubSection>
          <SubSection>
            <GroupContainerSettings
              titleText="Изменить отступы"
              switcherState={hasIndentation}
              switcherChange={() => onTableHasIndentationIndicatorChange(dataSettings, !hasIndentation, id)}
            >
              <IndentStyledComponent
                value={indentation}
                onChange={(indentation) => onTableIndentationIndicatorChange(dataSettings, indentation, id)}
              />
            </GroupContainerSettings>
          </SubSection>
        </MainContainerSettings>
        <MakeHyperLinksSettings
          value={makeHyperLinks}
          onChange={(makeHyperLinks) => onChangeIndicatorMakeHyperLinks(dataSettings, makeHyperLinks, id)}
        />
        <SortByColumnSettings
          value={sortItsColumn}
          onChange={({ type, isActive }) =>
            onSortChange({
              dataSettings,
              columnName,
              type,
              sortItsColumn,
              isActive,
            })
          }
        />
        <TableTotalIndicatorSettings
          value={totalSettings}
          onChange={(totalSettings) => onTotalIndicatorChange(dataSettings, totalSettings, id)}
          modelMetaData={modelMetaData}
        />
        {/*TODO 25.06.24 не реализован функционал маркер динамики*/}
        {/*<DynamicsMarkerSettings*/}
        {/*  value={dynamicsMarkerSettings}*/}
        {/*  onChange={(dynamicsMarkerSettings) => onDynamicsMarkerChange(dataSettings, dynamicsMarkerSettings, id)}*/}
        {/*/>*/}
        <FormattingSettings value={formatting} onChange={(value) => onChangeIndicatorFormatting(dataSettings, value, id)} />
      </>
    );
  };

  return (
    <>
      <DefaultDataSettings dataSettings={dataSettings} />
      <ModelSelectorSettings value={modelId} onChange={onModelIdChange} />
      <GeneralSettingsSwitch
        titleText="Группировать все разрезы"
        onChange={(groupIncision: boolean) => {
          onChangeGroupingAllIncisions(dataSettings, groupIncision);
        }}
        value={hasAllGroupIncision}
      />
      <MetricSettings
        titleText="Разрезы в шапке"
        addButtonText="Добавить разрез"
        fieldSettingsRender={incisionInHeaderFieldSettingsRender}
        metricRender={incisionInHeaderRender}
        metrics={incisionsInHeader}
        onAdd={() => onAddNewIncisionInHeader(dataSettings)}
        onDelete={(id) => id && onDeleteIncisionInHeader(dataSettings, id)}
        controls={controls}
        disableDeleteButton={false}
      />
      <MetricSettings
        titleText="Разрезы в колонках"
        addButtonText="Добавить разрез"
        fieldSettingsRender={incisionFieldSettingsRender}
        metricRender={incisionRender}
        metrics={incisions}
        onAdd={() => onAddNewIncision(dataSettings, 'table')}
        onDelete={(id) => id && onDeleteIncision(dataSettings, id)}
        controls={controls}
        disableDeleteButton={false}
      />
      <MetricSettings
        titleText="Показатели"
        addButtonText="Добавить показатель"
        fieldSettingsRender={indicatorFieldSettingsRender}
        metricRender={indicatorRender}
        metrics={indicators}
        onAdd={() => onAddNewIndicator(dataSettings, 'table')}
        onDelete={(id) => id && onDeleteIndicator(dataSettings, id)}
        disableDeleteButton={false}
        controls={controls}
      />
      <SqlSettings
        //@ts-ignore
        astData={astOfVisualisation}
        sqlData={codeEditorData}
        adviceEditorIncision={getIncisionAndIndicatorsCompletions(incisions)}
        adviceEditorIndicator={getIncisionAndIndicatorsCompletions(indicators)}
        onSave={(sqlSettingsChanges) => onSqlSettingsSave(dataSettings, sqlSettingsChanges, 'table')}
        modelMetaData={modelMetaData}
      />
      {!!dataSettings.incisionsInHeader.length && (
        <LimitSettings value={dataSettings.limit} onChange={onLimitChange} title="Лимит в шапке" />
      )}
      {(hasAllGroupIncision || hasGroupingIncision) && (
        <LimitSettings value={dataSettings.limitGrouping} onChange={onLimitGroupingChange} title="Лимит группировки" />
      )}
    </>
  );
};
