import React, { useCallback } from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { PrimaryTextParagraph } from 'styles/TextsElements';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { ModelRuleUserAndGroupInterface } from 'store/reducers/models/types';
import { useAppDispatch } from 'store';
import { UniversalLeftBarList } from 'modules/settingsContainer/UniversalLeftBarList';
import { closeModalAction, openConfirmationModalAction, openModalTypedAction } from 'store/reducers/modals/actions';
import { DefaultUserInterface } from 'types/store';
import {
  changeActiveRuleGroupIdAction,
  changeActiveRuleUserIdAction,
  deleteByIdRuleUserAction,
} from 'store/reducers/models/actions';
import { AddUserOrGroupsModal } from 'components/contents/Models/EditableMenu/PopoverRule/Modal/AddUserModal';
import { useSelector } from 'react-redux';
import { getActiveModelRuleUser, getUsers } from 'store/reducers/models/getters';
import { GroupsListInterface } from 'store/reducers/adminGroups/types';
import { Hint } from 'modules/ui/Hint';
import { HintChildrenDefaultRule } from 'components/contents/Models/EditableMenu/PopoverRule/Modal/RuleModal/Hint/HintChildrenDefaultRule';

interface UsersRuleProps {
  usersRule: ModelRuleUserAndGroupInterface[];
}

export const UsersRule = ({ usersRule }: UsersRuleProps) => {
  const dispatch = useAppDispatch();
  const usersList = useSelector(getUsers);
  const activeModelRuleUser = useSelector(getActiveModelRuleUser);

  const filteredGroupsList = usersList.filter((user) => !usersRule.some((userRule) => userRule.id === user.id));

  const modelMetaUserDataList = (usersRule || []).map(({ id, name }) => ({
    items: [],
    id: String(id),
    title: name,
    type: name,
  }));

  const onDeleteModal = useCallback(
    ({ id, title }: DefaultUserInterface) => {
      dispatch(
        openConfirmationModalAction({
          confirmationButtonText: 'Удалить',
          subTitleText: `Действительно удалить пользователя ${title} из правила?`,
          onConfirm: () => {
            dispatch(deleteByIdRuleUserAction(id));
            dispatch(changeActiveRuleUserIdAction(null));
          },
          titleText: 'Удалиение пользователя',
          width: '320px',
          disableModalPortal: true,
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onCloseAddUserModal = useCallback(
    () => dispatch(closeModalAction('addRuleUserModal')), // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const onAddUserModal = useCallback(
    async () => {
      try {
        const multiSelectData = filteredGroupsList.map((el) => ({ title: el.name, value: el.id }));

        dispatch(
          openModalTypedAction({
            Component: AddUserOrGroupsModal,
            componentProps: {
              onClose: onCloseAddUserModal,
              multiSelectData,
              mode: 'users',
            },
            modalSettings: {
              headerText: 'Добавление пользователей',
              disableModalPortal: true,
            },
            name: 'addRuleUserModal',
          }),
        );
      } catch (error) {
        return;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onCloseAddUserModal, filteredGroupsList],
  );

  const onSelectedItem = useCallback(
    async ({ id, title }: GroupsListInterface) => {
      dispatch(changeActiveRuleUserIdAction({ id, name: title, variableValues: [] }));
      dispatch(changeActiveRuleGroupIdAction(null));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <FlexContainer flexDirection="column" height="100%" position="relative">
      <FlexContainer flexDirection="row" alignItems="center" gap="8px">
        <PrimaryTextParagraph fontWeight="bold" fontSize="14px" lineHeight="16px" color={`var(${ColorVarsEnum.Level_1})`}>
          Пользователи
        </PrimaryTextParagraph>
        <Hint>
          <HintChildrenDefaultRule
            title="Правила назначенные пользователю в этом списке имеют больший приоритет перед правилом для того же пользователя в составе
      группы."
          />
        </Hint>
      </FlexContainer>
      <UniversalLeftBarList
        buttonTitle="Добавить пользователя"
        disabledMobileVersion
        data={modelMetaUserDataList}
        activeTitle={activeModelRuleUser?.name}
        activeId={activeModelRuleUser?.id}
        loadingList={false}
        disabledAdditionalField
        onSelectedItem={onSelectedItem}
        onDeleteItem={onDeleteModal}
        onCreateItem={onAddUserModal}
        height="100%"
        backgroundColorSidebar={`var(${ColorVarsEnum.Level_3_menu})`}
      />
    </FlexContainer>
  );
};
