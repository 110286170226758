import { AstStateInterface } from 'store/reducers/ast/types';

export const initialAstStoreState: AstStateInterface = {
  visualisations: {},
  enabledFilters: {},
  sources: {},
};

export const defaultAstOfFilterAndGroups = {
  having: null,
  where: null,
  orderBy: null,
  groupBy: null,
  limit: null,
};
