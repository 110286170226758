//TODO: fix types ASAP
//@ts-nocheck
import { format } from 'sql-formatter';
import { SqlDataRequestInterface } from 'types/store';
import { getAstFromSql } from './genereteAst';
import { getSql } from './generateSQL';

export const formatSql = (sql?: string) => (sql && sql !== '' ? format(sql, { language: 'postgresql' }) : undefined);

export const combineSqlStrings = (
  originSql: string,
  sqlData: SqlDataRequestInterface,
  sort: string | null | undefined,
  isDataFilter: boolean,
) => {
  const { incisionRequest, filterAndGroupRequest } = sqlData;

  if (!filterAndGroupRequest && !incisionRequest) {
    return originSql;
  }

  if (isDataFilter) return originSql;

  try {
    const originAst = getAstFromSql(originSql);

    const filterAndGroupAst: any = filterAndGroupRequest.length && getAstFromSql(`SELECT * ${filterAndGroupRequest}`);

    const incisionsAst: any =
      incisionRequest.length && isDataFilter
        ? getAstFromSql(
            `SELECT '"' || toString(toDate(Min(${incisionRequest}))) || '", "' || toString(toDate(Max(${incisionRequest}))) || '"' as value`,
          )
        : getAstFromSql(`SELECT ${incisionRequest} as value, COUNT(*) as count`);

    const removeNulls = (array: any[]) => array.filter((item) => item !== null);

    const combineConditions = (firstCondition: any, secondCondition: any) => {
      if (firstCondition && secondCondition) {
        return {
          type: 'binary',
          op: 'AND',
          left: firstCondition,
          right: secondCondition,
        };
      }
      return firstCondition || secondCondition || null;
    };

    const combineLimits = (firstLimit: any, secondLimit: any) => {
      if (firstLimit && secondLimit) {
        return {
          limit: { value: [Math.min(firstLimit.value[0], secondLimit.value[0])], type: 'integer' },
        };
      }
      return firstLimit || secondLimit || null;
    };

    const orderStrategy = sort
      ? originAst.orderby || filterAndGroupAst.orderby || []
      : filterAndGroupAst.orderby || originAst.orderby || [];

    const finalColumns = incisionsAst.columns?.length
      ? incisionsAst.columns
      : removeNulls((originAst.columns || []).concat(filterAndGroupAst.columns || []).filter((col: string) => col !== '*'));

    const combinedAST = {
      type: 'select',
      options: null,
      distinct: null,
      columns: finalColumns,
      from: removeNulls((originAst.from || []).concat(filterAndGroupAst.from || [])),
      where: combineConditions(originAst.where, filterAndGroupAst.where),
      groupby: removeNulls((originAst.groupby || []).concat(filterAndGroupAst.groupby || [])),
      having: combineConditions(originAst.having, filterAndGroupAst.having),
      orderby: removeNulls(orderStrategy),
      limit: combineLimits(originAst.limit, filterAndGroupAst.limit),
      with: null,
    };

    let sqlString = getSql(combinedAST as any);
    sqlString = sqlString?.replace(/`/g, '');

    return sqlString;
  } catch (error) {
    console.error('Error combining SQL:', error);
    return originSql;
  }
};
